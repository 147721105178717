
import React, { useEffect, useState } from 'react'
import bookmark from '../../assets/icons/bookmark.svg'
import { useNavigate, useParams } from 'react-router-dom'
import arrowleft from "../../../website/assets/icons/angle-right.svg"
import calendarminus from "../../../website/assets/icons/calendar-minus.svg"
import user from "../../assets/icons/user.png"
import heart from "../../assets/icons/heart.png"
import localisation from "../../assets/icons/localisation.png"
import starfull from "../../assets/icons/fullstar.png"
import staroutline from "../../assets/icons/staroutline.png"
import boat from '../../assets/images/boat.jpg'
import { api } from '../../../api'
import axios from 'axios'
import ConfirmModal from '../../../websitePro/components/Modal/ConfirmModal'
import Feedback from '../../../admin/components/feedback'
import AppointmentModal from '../../components/modal/AppointmentModal'
import { GrClose } from 'react-icons/gr'
import { BiCalendar } from 'react-icons/bi'
import { TimeConverter } from '../../../websitePro/components/DateTimeConverter/TimeConverter'


const BookingDetail = () => {
const navigate = useNavigate()
const {id} =useParams()
const [rdv, setRdv] = useState([])
const [showSuccessModal, setShowSuccessModal] = useState(false)
const [showCancelModal, setShowCancelModal] = useState(false)
const [showModal, setShowModal] = useState(false)
const [isOpen, setIsOpen] = useState({
  show: false,
  type: "success",
  message: ""
})
const [showAppointmentModal, setShowAppointmentModal] = useState(false)
const formatDate = (dateStr) => {
  const date = new Date(dateStr);
  return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
}

const [dateSelected, setDateSelected] = useState({
  date: '',
  hour: '',
  minute:''
})
const [offer, setOffer] = useState([])



useEffect(() => {
  // Appel API pour récupérer les détails du service
  axios.get(api + `/api/getRdvOffreService/${id}`)
    .then(response => {
      // Traiter la réponse si tout se passe bien
      setRdv(response.data)
    })
      .catch(error => console.error('Erreur lors de la récupération du rdv:', error));

}, [id])

const cancel = () => {
  // envoyer un email d'annulation
  const formData = new FormData();
  formData.append('email',localStorage.getItem("soro-email"))
  // formatDate(rdv.date)} à {rdv.heure}:{rdv.minute}
  formData.append('date',formatDate(rdv.date))
  formData.append('heure',rdv.heure)
  formData.append('minute',rdv.minute)
  formData.append('nomOffre',rdv.offre?.nom)

  // console.log(formData.email)

  axios.post(api+ `/api/annulerRdv/${id}`)
  .then((res)=>{
    // console.log(res)
    // setShowModal(false)
    console.log('Modification du rdv avec succès')
  

      axios.post(api+ `/api/emailAnnulerRdv`, formData)
      .then((res)=>{
        console.log(res)
        // setShowModal(false)
   setIsOpen({ ...isOpen, message:"Enregistrement réussie!", type:"success", show: true  });  
      })
      .catch((err)=>{
    console.log(err)
    // setShowModal(false)
  setIsOpen({ ...isOpen, message:"Une erreur est survenue", type:"error", show: true  });  
})

  })
  .catch((err)=>console.log(err))

}

const getOfferDetail = () => {
  // récupérer les jours ouvrables correspondant à cet offre

          axios.get(api + `/api/getOffreJourHeureOuvrable/${rdv.offre?.id}`)
            .then(response => {
              // Traiter la réponse si tout se passe bien
              setOffer(response.data)
            })
            .catch(error => console.error('Erreur lors de la récupération des offres:', error));
}

  return (
    <>
   <div className="container mx-auto px-4 pt-16">
    <div className=' flex items-start'> 
      <button className={" p-2 border bg-white  my-10 rounded-full"} onClick={()=>navigate('/reservation')}>
        <img src={arrowleft} alt="arrow" className='w-5 h-5 rotate-180'/>
      </button>
    </div>

    <div className='grid grid-cols-3  min-w-0 break-words  w-full mb-6 rounded-lg  gap-4'>
      {/* Section droite */}
      <div className='col-span-2 bg-white rounded-md p-6  drop-shadow-md min-h-[600px]'>
        <div className="flex flex-row items-center w-full justify-between">
          <div className=' flex flex-col items-start'>
            <h5 className='font-bold text-lg mb-6 text-start'>Reservation {rdv.etat}</h5>
            <p>{formatDate(rdv.date)} à {TimeConverter(rdv.heure,rdv.minute)}</p>

            </div>
            <div className='flex gap-3'>
                <div className="flex-col flex items-center">
                    <button onClick={()=>{
                      getOfferDetail()
                      setShowAppointmentModal(true)}} className=' bg-gray-100 p-3 rounded-md cursor-pointer hover:bg-gray-200 hover:text-blue-500 hover:bg-blue-600/10'>
                        <BiCalendar className='w-5 h-5'/>
                    </button>
                    <p>Modifier un RDV</p>
                </div>
                <div className="flex-col flex items-center">
                    <button
                    onClick={()=>setShowCancelModal(true)}
                    className=' bg-gray-100 p-3 rounded-md  cursor-pointer hover:bg-gray-200 hover: hover:text-red-500 hover:bg-red-600/10'>
                        <GrClose className='w-5 h-5'/>
                    </button>
                    <p>Annuler</p>
                </div>
            </div>
            
          
        </div>

 <hr className='my-6'/>
                    <div className='border rounded-lg mt-4'>
                            <div className='flex w-full justify-between px-6 py-4'>
                                <div className="flex flex-col items-start">
                                    <p className='hidden'>{rdv.offre?.id}</p>
                                    <p className=' text-lg font-semibold'>{rdv.offre?.nom}</p>
                                    <p className=' text-slate-500 font-semibold text-sm'>Durée: {rdv.offre?.duree_heure}h{rdv.offre?.duree_minute}</p>
                                </div>
                                <div className=' text-lg font-semibold'>{rdv.offre?.prix} Ariary</div>
                            </div>
                            <hr />
                            <div className='flex w-full justify-between px-6 py-4'>
                                <div className="flex flex-col items-start">
                                    {/* <p className=' text-slate-500 font-semibold text-sm'>Total</p> */}
                                    <p className=' text-lg font-semibold'>Total</p>
                                </div>
                                <div className="flex flex-col items-end">
                                    {/* <p className=' text-slate-500 font-semibold text-sm text-end'>20 %</p> */}
                                    <p className=' text-lg font-semibold text-end'>{rdv.offre?.prix} Ariary</p>
                                    
                                </div>
                            </div>

                        </div>
                        {rdv?.raison &&
                         <div className='mt-5'>
                          <h5 className='text-lg font-semibold text-start'>Raison</h5>
                          <p className='text-start text-slate-500 font-semibold text-sm'>{rdv.raison}</p>
                        </div>
                        }
                       
                    </div>

                    <div className='col-span-1 '>
                      <div
                        className="relative group shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer rounded-t-md">
                        <div className="relative overflow-hidden rounded-t-md">
                        {/* Heart Icon - Displayed only on hover */}
                        <div className="absolute top-4 right-4 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-white p-2 rounded-full">
                          <img src={heart} alt="Favorite" className="h-4 w-4" />
                        </div>

                        {/* Image */}
                        <img
                          src={api + '/storage/' + rdv.offre?.photo}
                          alt="No image"
                          className="mx-auto h-[220px] w-full object-cover transition duration-700 hover:skew-x-1 hover:scale-110 hover:z-10"
                        />
                        </div>

                        <div className="space-y-2 p-3 rounded-b-md">
                        <h1 className="line-clamp-1 font-bold text-xl text-start">{rdv.offre?.nom}</h1>
                        <p className="flex gap-2 items-center">
                              <img src={user} alt="User" className="w-5 h-5" />
                              {rdv.offre?.service?.nom}
                            </p>
                        <div className="flex items-center gap-2 opacity-70">
                          <img src={localisation} alt="place" className="w-5 h-5"/>
                          <span>{rdv.offre?.service?.adresse}</span>
                        </div>
                        {/* <div className=" flex items-center gap-2"><p>5.0</p>
                        <p className='flex gap-2'>
                          <img src={starfull} alt="s" className=" w-4 h-4"/>
                          <img src={starfull} alt="s"  className=" w-4 h-4"/>
                          <img src={starfull} alt="s"  className=" w-4 h-4"/>
                          <img src={starfull} alt="s"  className=" w-4 h-4"/>
                          <img src={staroutline} alt="s" className=" w-4 h-4" /></p>
                        <p>(58 Votes)</p>                      
                                              </div> */}
                        <hr />
                        <h5 className='font-semibold text-lg text-start'>Description</h5>
                        <p className='text-sm text-start'>{rdv.offre?.description}</p>
                        </div>
                      </div> 
                    </div>
                </div>
        </div>
  
    <ConfirmModal 
        show={showCancelModal} 
        onClose={()=>setShowCancelModal(false)}
        variant='danger'
        title="Annulation"
        text="Êtes-vous sûr de vouloir annuler ce rendez-vous"
        buttonText="Oui, Annuler"
        buttonCancelText="Non, abandoner"
        action={()=>{
          cancel()
          setShowCancelModal(false)
          
        }}
      />

        <AppointmentModal show={showAppointmentModal} onClose={()=>setShowAppointmentModal(false)}  idOffer={rdv.offre?.id} idService={rdv.offre?.service_id}/>
       <Feedback type={isOpen.type} isOpen={isOpen.show} onClose={() => setIsOpen({ ...isOpen, show: false})} message={isOpen.message} />
    </>

  )}

export default BookingDetail