import React from 'react'
import { useNavigate } from 'react-router-dom'

export const LayoutSecurity = () => {
    const navigate = useNavigate()
    if(localStorage.getItem('natura-germe-id') === null) navigate('/login')
  return (
    <div>Page not found</div>
  )
}
