import React from "react"

const Heading = ({ title, subtitle }) => {
  
  return (
    <>
      <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
            {title}
            </h2>
            <p className="mb-5 font-light text-gray-500 sm:text-xl dark:text-gray-400">
            {subtitle}
            </p>
          </div>
    </>
  )
}

export default Heading