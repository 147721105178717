import React, {useState} from 'react'
import Sidebar from '../components/Sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../components/Header'
import '../style/index.css'

const WebsiteProLayout = () => {
  const [title, setTitle] = useState('Tableau de bord')
  return (
    <div className='flex'>
      
      <Sidebar/>
      <div className=' w-full bg-slate-50/50 ' id='pro'>
      <Header title={title}></Header>
      <div className='h-24 md:h-0'></div>
      <Outlet context={{ setTitle }} /> 
      </div>
      
    </div>
  )
}

export default WebsiteProLayout