import React from 'react'
import { Link } from 'react-router-dom';

const PricingCard = ({ title, description, price, duration, features, buttonText, background, isHighlighted }) => {
  return (
    // bg-white border border-gray-100
    <div className={`
      ${background === "white" ? " " : "bg-black-900 text-white hover:shadow-black-900/30 shadow-lg  "}
      ${isHighlighted ? "h-[950px]" : "h-[850px]"}
      flex flex-col max-w-lg px-6 mx-auto text-center text-gray-900 rounded-lg py-10 ease-in duration-150 cursor-pointer
    `}>
      <h3 className="mb-4 text-2xl font-semibold">{title}</h3>
      <p className={(background !== "white" ? "text-white font-light sm:text-lg ":"text-gray-500 font-light sm:text-lg ")+" font-light sm:text-lg mb-2 "}>{description}</p>
      <Link to="register" className={`
        ${background === "white" ? "text-white bg-black-900 hover:bg-slate-700 focus:ring-4 focus:ring-purple-200" : "text-white bg-primary hover:bg-primary"}
        font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white 
      `}>
        {buttonText}
      </Link>
      <hr className='mt-6 opacity-50'/>
      <div className="flex items-baseline justify-center my-8">
        <span className="mr-2 text-5xl font-extrabold">{price}</span>
        <span className="text-gray-500 ">{duration}</span>
      </div>
      {/* <hr /> */}
      <ul role="list" className="mb-8 space-y-4 text-left">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center space-x-3">
            <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path>
            </svg>
            <span>{feature}</span>
          </li>
        ))}
      </ul>
    
    </div>
  );
}

export default PricingCard;
