import React from 'react'
import Left from './Left'
import Right from './Right'

const Register = () => {
  return (
    <div className='flex'>
      <Left/>
      <div className=" lg:w-2/5 h-full bg-red-700"></div>
      <Right/>
    </div>
  )
}

export default Register