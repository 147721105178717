import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import { IoArrowForward, IoCheckmark } from 'react-icons/io5';
// import { IoReloadCircle } from 'react-icons/io5';

export default function Load({ title="", loading = false,next=false,check=false }) {
    return (
        <div className='flex flex-row text-white justify-center items-center h-full'>
            {
                loading ? (
                    <div className=" animate-spin duration-200">
                        <FaSpinner />
                    </div>
                ) : (
                    <div className="flex flex-row justify-center items-center gap-x-1">
                        <span>{title}</span>
                        {
                            next &&
                            <div className="">
                                <IoArrowForward />
                            </div>
                        }
                        {
                            check&&(
                                <div className="">
                                <IoCheckmark />
                            </div> 
                            )
                        }
                    </div>
                )
            }

        </div>
    )
}