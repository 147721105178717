import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import profile from '../../../../../assets/image/profile.png'
import GoBack from '../../../../components/btn/goBack'
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../../api'
import Feedback from '../../../../components/feedback'
export default function DetailsAbonnement() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isOpen,setIsOpen]=useState({
        success: false,
        fails: false
    })
    const [options,setOptions]=useState({
        disabled: false,
        loading: false,
    })
    const [data, setData] = useState([])
    function fetchData() {
        try {
            
            axios.get(api + `/api/getAbo/${id}`)
                .then(res => {
                    setData(res.data)
                    // fetchData()
                    console.log(res.data)
                })
                .catch(err => {
                    console.error(err)
                })
        } catch (error) {
            console.error(error)
        }
    }
    function changeStatus(status) {
        // setOptions({...options,loading:true})
        try {
            const form = new FormData();
            form.append('status', status);
            form.append('id', id);
            axios.post(api + '/api/route/changeStatus')
                .then(res => {
                    console.log(res.data);
                    fetchData()
                      navigate(-1)

                })
                .catch(err => {
                    console.error(err)
                })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='w-full'>
            <Card>
                <div className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full flex flex-row gap-x-3 justify-start items-center ">
                        <GoBack />
                        <span className="font-bold text-slate-400">Détails d'abonnement</span>
                    </div>
                    <img src={api + '/storage/' + data.image} className='w-[200px]' />
                    <div className='grid grid-cols-3 gap-8'>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Type</span>
                            <span className="text-[#434966]  font-medium">{data.type}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Prix</span>
                            <span className="text-[#434966]  font-medium">{data.prix} Ar</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Description</span>
                            <span className="text-[#434966]  font-medium">{data.description}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Objet</span>
                            <span className="text-[#434966]  font-medium">{data.objet}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Debut</span>
                            <span className="text-[#434966]  font-medium">{data.dateDebut}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Fin</span>
                            <span className="text-[#434966]  font-medium">{data.dateFin}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Référence</span>
                            <span className="text-[#434966]  font-medium">{data.ref}</span>
                        </div>
                    </div>
                    {
                        data.status === 'en attente' && (
                            <div className='flex flex-row justify-end items-center w-full gap-3'>
                                <Button variant={"outlined"} style={{ borderColor: 'red', color: 'red' }}

                                    onClick={() => changeStatus('Refusé')}
                                >
                                    Rejeter
                                </Button>
                                <Button variant={"outlined"} style={{ borderColor: 'green', color: 'green' }}
                                    onClick={() => changeStatus('Expiré')}
                                >
                                    Expirer
                                </Button>
                                <Button variant={"outlined"} style={{ borderColor: 'green', color: 'green' }}
                                    onClick={() => changeStatus('Confirmé')}
                                >
                                    Confirmer
                                </Button>
                            </div>
                        )
                    }
                </div>
            </Card>
            <Feedback 
            isOpen={isOpen.success}
            type='success'
            message='Modifiée avec succès.'
            onClose={()=>{
                setIsOpen({...isOpen, success: false})
            }}
             
            />
            <Feedback
            isOpen={isOpen.fails}
            type='error'
            message="Une erreur s'est produite."
            onClose={()=>{
                setIsOpen({...isOpen, fails: false})
            }}/>
        </div>
    )
}
