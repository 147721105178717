import React, { useEffect, useState } from "react"
import {ServiceData} from "../../../../Datas/Datas"
import ServiceCard from "./ServiceCard"
import Heading from "../../../../components/Heading"
import { api } from "../../../../../api"
import axios from "axios"
import { Link, useNavigate } from "react-router-dom"

const Service = ({ handleOrderPopup }) => {
  const [service, setService] = useState([])


  useEffect(() => {
    axios.get(api + `/api/allServices`)
      .then(response => {
        setService(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des services:", error);
      })
      
  }, []);

  return (
    <>
      <div className="dark:bg-gray-900 dark:text-white bg-gray-50 py-10" >
        <section data-aos="fade-up" className="container mx-auto" >
        <Heading 
          title='Des Services Incontournables' 
          subtitle='Explorez des solutions adaptées à vos besoins' 
        />
          
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mb-6">
            {service.map((item, index) => (
              <ServiceCard
                handleOrderPopup={handleOrderPopup}
                key={index}
                {...item}
              />
            ))}
          </div>
<div className="flex justify-center">
  <Link to="/login" className="bg-[#000000] px-6 py-2 rounded-md text-white font-semibold">Voir plus</Link>
</div>
          
        </section>
      </div>
    </>
  );
};

export default Service
