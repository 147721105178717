import React, { useState } from 'react';
import Button from '../Button';

const ImagePicker = ({ initialImage, onImageChange }) => {
    const [selectedImage, setSelectedImage] = useState(null);

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setSelectedImage(file);
            if (onImageChange) {
                onImageChange({ target: { name: 'photo', value: file } });
            }
        }
    };

    const handleRemoveImage = () => {
        setSelectedImage(null);
        if (onImageChange) {
            onImageChange({ target: { name: 'photo', value: null } });
        }
    };

    const displayImage = selectedImage
        ? URL.createObjectURL(selectedImage)
        : initialImage;

    return (
        <div className="border-line">
            <label htmlFor="image" className="text-sm font-medium text-gray-700 mb-1 text-start flex justify-start">
                Photo
            </label>
            <div className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
                <input
                    name="photo"
                    type="file"
                    className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                    onChange={handleImageChange}
                />
                {displayImage ? (
                    <div className="w-full h-full flex-col flex items-center justify-center py-4 gap-y-2">
                        <img src={displayImage} alt="Selected" className="object-cover w-52 h-52" />
                        <Button
                            type="button"
                            onClick={handleRemoveImage}
                            className="mt-2 text-red-600"
                        >
                            Modifier
                        </Button>
                        <p className="m-0">Glisser l’image ici ou cliquer pour modifier.</p>
                    </div>
                ) : (
                    <div className="flex flex-col items-center justify-center py-10 text-center">
                        <div className="bg-orange-400/10 flex items-center justify-center p-2 text-primary rounded-full">
                            <svg
                                className="w-6 h-6 text-current-50"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                />
                            </svg>
                        </div>
                        <p className="m-0">Glisser l’image ici ou cliquer sur Ajouter une image.</p>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ImagePicker;
