import React, { useEffect, useState } from 'react'
import Input from '../../../components/form/input'
import GoBack from '../../../components/btn/goBack'
import { Button } from '@mui/material'
import Load from '../../../components/load'
import Feedback from '../../../components/feedback'
import { useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import Modals from '../../../components/modal'
import axios from 'axios'
import { api } from '../../../../api'
import TextAria from '../../../components/form/textAria/index.'

export default function NewClientPro() {
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
        add: false,
        Verification: false
    })
    const [options, setOptions] = useState({
        disabled: true,
        loading: false,
        disabledEmail: false
    })
    const [data, setData] = useState({
        nom: '',
        email: '',
        password: '',
        confirmPassword: '',
        region: '',
        district: '',
        address: '',
        contact: '',
        province: '',
        photo: null,
        codeGenerated: '',
        // code: '',
        // description: ''

    })
    const handleSubmit = (e) => {
        e.preventDefault()
        console.log(e)
        handleOpen("add")
    }
    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }

    const handleChange = (e, type) => {
        setData({ ...data, [type]: e.target.value })
    }
    function addClient() {
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append('photo', data.photo)
            form.append('region', data.region)
            form.append('district', data.district)
            form.append('address', data.address)
            form.append('contact', data.contact)
            form.append('province', data.province)
            form.append('password', data.password)
            form.append('confirmPassword', data.confirmPassword)
            form.append('nom', data.nom)
            form.append('email', data.email)
            form.append('description', data.description)

            axios.post(api + '/api/route', form)
                .then(res => {
                    console.log(res.data);

                    handleOpen("success")
                    setTimeout(() => {
                        navigate('/admin/prestataire')
                    }, 400)

                })
                .catch(err => {
                    console.error(err)
                    handleOpen("fails")

                })
        } catch (error) {
            console.error(error)
            handleOpen("fails")
        }

    }
    // function sendCodeToEmail() {
    //     try {
    //         const form = new FormData()
    //         form.append('email', data.email)
    //         form.append('code', data.codeGenerated)
    //         axios.post(api + '/api/route/send')
    //             .then(res => {
    //                 console.log(res.data);
    //                 handleOpen("Verification")
    //             })
    //             .catch(err => {
    //                 console.error(err)
    //                 handleOpen("Verification")
    //             })
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }


    useEffect(() => {
        if (
            data.nom.trim() !== '' &&
            data.email.trim() !== '' &&
            data.password.trim() !== '' &&
            data.confirmPassword.trim() !== '' &&
            data.region.trim() !== '' &&
            data.district.trim() !== '' &&
            data.address.trim() !== '' &&
            data.contact.trim() !== '' &&
            data.province.trim() !== '' &&
            data.password.trim() === data.confirmPassword
        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <div className='w-full'>
            <Card>
                <form onSubmit={(e) => { handleSubmit(e) }} className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full gap-x-2 flex flex-row justify-start items-center ">
                        <GoBack />
                        <span className='font-bold text-slate-500'>
                            Nouveau client professionnel
                        </span>
                    </div>
                    <div className="grid grid-cols-3 gap-4 justify-start items-start w-full ">
                        <Input label='Nom de compte' value={data.nom} onChange={(e) => { handleChange(e, "nom") }} />
                        <Input label='Numero de téléphone' value={data.contact} onChange={(e) => { handleChange(e, "contact") }} />
                        <Input label='Adresse' type='text' value={data.address} onChange={(e) => { handleChange(e, "address") }} />
                        <Input label='Region' value={data.region} onChange={(e) => { handleChange(e, "region") }} />
                        <Input label='District' value={data.district} onChange={(e) => { handleChange(e, "district") }} />
                        <Input label='Province' value={data.province} onChange={(e) => { handleChange(e, "province") }} />
                        <Input label='E-mail' type="email" value={data.email} onChange={(e) => { handleChange(e, "email") }} />
                        <Input label='Mot de passe' type='password' value={data.password} onChange={(e) => { handleChange(e, "password") }} />
                        <Input label='Confirmation mot de passe' type='password' value={data.confirmPassword} onChange={(e) => { handleChange(e, "confirmPassword") }} />
                        <Input label='Photo' type='file' onChange={(e) => { setData({ ...data, photo: e.target.files[0] }) }} />

                    </div>
                    <TextAria label='Description' value={data.description} onChange={(e) => { handleChange(e, "description") }} />
                    <div className="flex flex-row justify-end items-center gap-x-4 w-full">
                        <Button type="button" variant='outlined' onClick={() => { navigate(-1) }}>Annuler</Button>
                        <Button type="submit" variant='contained' disabled={options.disabled} onClick={() => { handleOpen("add") }}>
                            <Load title={"Ajouter"} check={true} />
                        </Button>
                    </div>
                </form>
            </Card>
            <Modals isOpen={isOpen.add} onClose={() => { handleClose("add") }}>
                <div className='w-full flex flex-col gap-y-2'>
                    <h4>Nouveau client</h4>
                    <p>
                        Voulez-vous ajouter ce client?
                    </p>
                    <div className="flex flex-row justify-between items-center gap-x-2">
                        <Button fullWidth variant='outlined' onClick={() => { handleClose("add") }}>
                            Annuler
                        </Button>
                        <Button  fullWidth variant='contained' onClick={() => {
                            // handleOpen("Verification"); sendCodeToEmail();
                            addClient()
                        }}>
                            <Load loading={options.loading} title='Oui,ajouter' />
                        </Button>

                    </div>
                </div>
            </Modals>
            {/* <Modals isOpen={isOpen.Verification} onClose={() => { handleClose("Verification") }}>
                <div className='w-full flex flex-col gap-y-2'>
                    <h4>Nouveau client</h4>
                    <p>
                        Nous avons envoyé une code de verification a votre adressse email
                    </p>
                    <div>
                        <Input label='Code de verification' value={data.code} onChange={(e) => { handleSubmit(e, "codeGenerated") }} />
                    </div>
                    <div className="flex flex-row justify-between items-center gap-x-2">
                        <Button fullWidth variant='outlined' onClick={() => { handleClose("Verification") }}>
                            Annuler
                        </Button>
                        <Button disabled={options.disabledEmail} fullWidth variant='contained' onClick={() => {
                            addClient()
                        }}>
                            <Load loading={options.loading} title='Verifier' />
                        </Button>

                    </div>
                </div>
            </Modals> */}
            <Feedback type='success' message='Ajoutée avec succès.' isOpen={isOpen.success} onClose={() => {
                handleClose("success")
            }} />
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails}
                onClose={() => {
                    handleClose("fails")
                }}
            />

        </div>
    )
}
