import React, {useEffect, useState} from 'react'
import boat from '../../assets/images/boat.jpg'
import Modal from '../../../websitePro/components/Modal/Modal'
import axios from "axios"
import ProfileImagePicker from "../../../websitePro/components/ProfileImagePicker"
import { api } from '../../../api'

import {district, region} from '../../Datas/RegionDistrict'


const Index = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [profileData, setProfileData] = useState({
    fullName: 'Jenna Stones',
    email: 'jenna.stones@example.com',
    address: '1234 Main St',
    city: 'Los Angeles',
    country: 'USA',
    state: 'California',
    // Ajoutez d'autres champs nécessaires ici
  })

  
  const id = localStorage.getItem("soro-id")
  const [userInfo, setUserInfo] = useState({
    photo:'',
    nom: '',
    contact: '',
    email: '',
    adresse: '',
    region: '',
    district: '',
  })


  const [oldUserInfo, setOldUserInfo] = useState({
    photo:'',
    nom: '',
    contact: '',
    email: '',
    adresse: '',
    region: '',
    district: '',
  })


  const [showProfileModal, setShowProfileModal] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "photo" && files && files.length > 0) {
      setUserInfo({
        ...userInfo,
        [name]: files[0], // Store the file directly
      });
    } else {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
  }


  useEffect(() => {
    getInfoPrest()
  }, [])

  const getInfoPrest = async () => {
    axios.get(api + `/api/getInfoClient/${id}`)
    .then(response => {
      setUserInfo(response.data)
      setOldUserInfo(response.data)
      // setoldImage(response.data.photo)
    })
    .catch(error => {
      console.error("There was an error fetching the customer data!", error);
    })
  };


  const handleSaveProfile = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('nom', userInfo.nom);
    formDataToSend.append('contact', userInfo.contact);
    formDataToSend.append('region', userInfo.region);
    formDataToSend.append('district', userInfo.district);
    formDataToSend.append('adresse', userInfo.adresse);

    if (userInfo.photo) {
      formDataToSend.append('photo', userInfo.photo);
  }

    try {
      const response = await axios.post(api + `/api/modInfoClient/${id}`, formDataToSend);
      // console.log('Profile updated:', response.data);
      setShowProfileModal(false);

      window.location.href='profil'
      getInfoPrest()
      localStorage.setItem('soro-photo',response.data.photo)


    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  
  return (
    <div>  
<main className="profile-page">
  <section className="relative block h-[350px]">
    
    <div className="absolute top-0 w-full  bg-center bg-cover bg-slate-400 h-[350px]" id='back1'>
      <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
    </div>
    <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" >
      <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <section className="relative pt-[100px] bg-blueGray-200 ">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64 pb-20">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full px-20 lg:order-2 flex justify-center lg:justify-start">
                    <div className="relative">
                      <img alt="..." src={oldUserInfo.photo ? `${api}/storage/${oldUserInfo.photo}` : null} className="shadow-xl rounded-full h-[150px] align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-[150px] outline-4 outline outline-white"/>
                    </div>
                  </div>
                </div>
               

<div className="flex justify-between mt-24 items-center">
                       <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
            Information général
        </h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">

            Voici quelque informations sur votre profil.

        </p>
    </div>

    <div>
    <button 
                        className="bg-black-900 active:bg-black-900 text-white font-bold hover:shadow-md shadow text-xs px-6 py-3 rounded outline-none focus:outline-none sm:mr-2 mb-1 ease-linear transition-all duration-150"
                        type="button"
                        onClick={() => setShowProfileModal(true)}
                      >
                        {isEditing ? 'Enregistrer' : 'Modifier'}
                      </button>
    </div>
                    </div>
                  <div class="bg-white overflow-hidden shadow rounded-lg border">
                  
   
    <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">

                    Nom
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {oldUserInfo.nom}

                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">

                    Email
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {oldUserInfo.email}

                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">

                    Contact
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {oldUserInfo.contact}

                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">

                    Adresse
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {oldUserInfo.adresse}

                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    District
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                    {oldUserInfo.district}

                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Région
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">

                    {oldUserInfo.region}

                </dd>
            </div>
        </dl>
    </div>
</div>
              </div>
            </div>
          </div>
        </section>
</main>

<Modal
  title="Modifier le profil"
  show={showProfileModal}
  onClose={() => setShowProfileModal(false)}
  onSave={handleSaveProfile}
>
<div className="space-y-4">
      <ProfileImagePicker
        initialImage={userInfo.photo ? `${api}/storage/${userInfo.photo}` : null}
        onImageChange={handleInputChange}
      />
      
      <div>
        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Nom</label>
        <input
          type="text"
          id="lastName"
          name="nom"
          value={userInfo.nom}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>

      <div>
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
        <input
          type="text"
          id="phoneNumber"
          name="contact"
          value={userInfo.contact}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>


      {/* <div>

        <label htmlFor="region" className="block text-sm font-medium text-gray-700">Région</label>
        <input
          type="text"
          id="region"
          name="region"
          value={userInfo.region}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />

      </div> */}

      <div className="relative mt-1">
        <select
            id="region"
            name="region"
            value={userInfo.region}
            onChange={handleInputChange}
            className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            required
        >
            <option value="">Choisissez une région</option>
            {region.map((item, index) => (
                <option key={index} value={item}>{item}</option>
            ))}
        </select>
      </div>

      {/* <div>

        <label htmlFor="district" className="block text-sm font-medium text-gray-700">District</label>
        <input
          type="text"
          id="district"
          name="district"
          value={userInfo.district}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />

      </div> */}
                  <div className="relative mb-6">
                <label htmlFor="district" className="block text-sm font-medium text-gray-700 text-start">District</label>
                <div className="relative mt-1">
                    <select
                        id="district"
                        name="district"
                        value={userInfo.district}
                        onChange={handleInputChange}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                    >
                        <option value="">Choisissez un district</option>
                        {userInfo.region && district[userInfo.region].map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>


      <div>
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
        <input
          type="text"
          id="address"
          name="adresse"
          value={userInfo.adresse}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>
    </div>
</Modal>

  </div>

  )
}

export default Index