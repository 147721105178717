import React from "react";
import Navbar from "../components/Navbar";
import { Outlet, Link } from "react-router-dom";
import Footer from "../components/Footer";
// import OrderPopup from "../components/OrderPopup/OrderPopup";

const Layout = ({ isAuthenticated }) => {
  const [orderPopup, setOrderPopup] = React.useState(false)
  // const first = useContext(second)


  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup)
  }; 
  return (
    <>
      <Navbar handleOrderPopup={handleOrderPopup}  isAuthenticated={isAuthenticated} />
      <div className="bg-[#FCFDFB] selection:bg-orange-300/80 selection:text-white">  <Outlet /></div>
    
      <Footer />
      {/* <OrderPopup orderPopup={orderPopup} setOrderPopup={setOrderPopup} /> */}
    </>
  )
}

export default Layout;