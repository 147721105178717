import Button from "../Button"
import React from 'react';

const Modal = ({ title, show, onClose, onSave, children, okOption=false }) => {
  if (!show) {
    return null;
  }

  return (

    
    <div className="fixed inset-0 z-[500] flex items-center justify-center overflow-auto bg-gray-900/20 bg-opacity-50">

      <div className="bg-white rounded-lg w-full max-w-lg mx-auto shadow-lg">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-gray-900">{title}</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Modal Body */}
        <div className="p-4">
          {children}
        </div>

        {/* Modal Footer */}
        <div className="flex justify-end items-center p-4  border-gray-200 bg-gray-50">
          {okOption ?
          <Button onClick={onClose}>OK</Button>
          :
         <> <button
            onClick={onClose}
            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm px-4 py-2 mr-2 "
          >
            Annuler
          </button>
          <button
            onClick={onSave}
            className="bg-primary text-white px-3 py-2 rounded-md hover:bg-primary focus:outline-none"
          >
            Sauvegarder
          </button>
         </>
         
          }
          
        </div>
      </div>
    </div>
  );
};

export default Modal
