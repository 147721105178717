import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import img from '../../../assets/image/profile.png'
import Card from '../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoPerson, IoPersonCircleOutline, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Input from '../../components/form/input'
import Path from '../../components/path';
import axios from 'axios';
import { api } from '../../../api'
import Modals from '../../components/modal';
import Load from '../../components/load';
import Notification from '../Notification';
import Feedback from '../../components/feedback';
import { exportToExcel } from '../../components/ExcelExport'

export default function Client() {
    const navigate = useNavigate()
    const [data, setData] = useState([
    ])
    const [isOpen, setIsOpen] = useState({
        delete: false,
        success: false,
        fails: false
    })
    const [options, setOptions] = useState({
        loading: false
    })
    const [search, setSearch] = useState('')

    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }


    function fetchData() {
        try {
            axios.get(api + '/api/getAllUserClient')
                .then(res => {
                    setData(res.data)
                })
                .catch(err => {
                    console.error(err)
                })
        } catch (error) {
            console.error(error)
        }
    }

    function deleteClient(client) {
        try {
            axios.delete(api + '/api/route/' + client.id)
                .then(res => {
                    fetchData()
                    setIsOpen({ ...isOpen, success: true })
                })
                .catch(err => {
                    setIsOpen({ ...isOpen, fails: true })
                    // setIsOpen({...isOpen,})
                    console.error(err)
                })
        } catch (error) {
            setIsOpen({ ...isOpen, fails: true })

            console.error(error)
        }
    }


    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='w-full flex flex-col gap-y-3'>
            <div className="w-full flex flex-row justify-between items-center">
                <div className='flex flex-row gap-x-3 justify-start items-center'>
                    <Button className="flex flex-row gap-x-3" variant="contained" onClick={() => { navigate('/admin/nouveauClient') }}>
                        <IoAddCircle />
                        <span>Nouveau</span>
                    </Button>
                    <Button className="flex flex-row gap-x-3" variant="outlined" onClick={() => {
                        exportToExcel(data, "Client")
                        // console.log(data)
                    }}>
                        <IoShare />
                        <span>Exporter</span>
                    </Button>
                </div>
                <div className='flex flex-row justify-end items-center'>
                    <div className="flex flex-row justify-end items-center gap-x-1">
                        <Input value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        <Button className="flex flex-row gap-x-3" variant="contained">
                            <IoSearch />
                            <span>Rechercher</span>
                        </Button>
                    </div>
                </div>
            </div>
            <Card>
                <div className='flex flex-row justify-between items-center'>
                    <h4 className="mb-2">Client</h4>
                    <Path currentName='Client' />
                </div>
                <table className='w-full max-w-full '>
                    <thead>
                        <tr>
                            <th>NUMERO</th>
                            <th>PHOTO</th>
                            <th>NOM</th>
                            <th>EMAIL</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.filter((item) => (search ? item.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>
                                        {
                                            item.photo != null ? (
                                                <img src={api + '/storage/' + item.photo} alt={item.nom} className='w-8 h-8 rounded-full' />
                                            ) : (
                                                <IoPersonCircleOutline className='w-8 h-8' />
                                            )
                                        }
                                    </td>
                                    <td>{item.nom}</td>
                                    <td>{item.email}</td>
                                    {/* <status>{item.status}</status> */}
                                    <td className='flex flex-row justify-end items-center text-xl gap-x-3'>

                                        <IoEye className='text-lg cursor-pointer' onClick={() => { navigate(`/admin/detailsClient/${item.id}`) }} />
                                        <IoCreate className='text-lg cursor-pointer' onClick={() => { navigate(`/admin/modificationClient/${item.id}`) }} />
                                        <IoTrash className='text-lg cursor-pointer' onClick={() => { handleOpen("delete") }} />
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </Card>
            <Modals isOpen={isOpen.delete} onClose={() => { handleClose("delete") }}>
                <div className='w-full flex flex-col gap-y-2'>
                    <h4>Suppression de client</h4>
                    <p>
                        Etes-vous sûr vouloir supprimer ce client? Cette action est irréversible.
                    </p>
                    <div className="flex flex-row justify-between items-center gap-x-2">
                        <Button fullWidth variant='outlined' onClick={() => { handleClose("delete") }}>
                            Annuler
                        </Button>
                        <Button color='error' fullWidth variant='contained' onClick={() => {
                            deleteClient()
                        }}>
                            <Load loading={options.loading} title='Oui,supprimer' />
                        </Button>

                    </div>
                </div>
            </Modals>
            <Feedback isOpen={isOpen.success} type='success' />
            <Feedback isOpen={isOpen.fails} type='error' />

        </div>
    )
}
