import React, { useState, useEffect } from 'react';
import ServiceCard from "../Home/partials/Services/ServiceCard";
import OfferPic from "../../assets/images/OfferPic.jpg";
import successIcon from "../../assets/icons/successIcon.png";
import CategoryCard from "../Home/partials/Categories/CategoryCard";
import axios from 'axios';
import { api } from '../../../api';

const Index = () => {
  const [formData, setFormData] = useState({
    Offer: '',
    agent: '',
    localisation: '',
    secteur_id: ''
  });

  const [secteur, setSecteur] = useState([]);
  const [service, setService] = useState([]);
  const [filteredServices, setFilteredServices] = useState([]);

  useEffect(() => {
    axios.get(api + `/api/allSecteurs`)
      .then(response => {
        setSecteur(response.data);
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des secteurs:", error);
      });

    axios.get(api + `/api/allServices`)
      .then(response => {
        setService(response.data);
        setFilteredServices(response.data);  // Initialisation du filtrage
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des services:", error);
      });
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const applyFilter = () => {
    const filtered = service.filter((item) => {
      return (
        (formData.agent === '' || item.nom.toLowerCase().includes(formData.agent.toLowerCase())) &&
        (formData.localisation === '' || item.localisation.toLowerCase().includes(formData.localisation.toLowerCase())) &&
        (formData.secteur_id === '' || item.secteur_id === formData.secteur_id)
      )
    })
    setFilteredServices(filtered);
  };

  return (
    <div className=''>
      <div className="bg-black/20 h-[400px] z-10">
        <div className="h-full flex justify-center p-4 bg-gray-900/50 items-end z-15 ">
          <div className='bg-black-900/10 absolute right-0 top-0 h-[400px] w-[100%] z-10'></div>
          <img src={OfferPic} className="absolute object-cover right-0 top-0 h-[400px] w-full z-2" alt='Logo'/>
        </div>
      </div>

      <div className="w-[86%] bg-white rounded-md shadow-md mx-auto p-5 -mt-20 z-20 flex-col relative">
        <div className="grid grid-cols-1 md:grid-cols-5 gap-3 mb-4">
          <div className="relative mt-1 col-span-2">
            <input
              type="text"
              id="agent"
              name="agent"
              value={formData.agent}
              onChange={handleChange}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Nom du service"
              required
            />
          </div>

          <div className="relative mt-1 col-span-2">
            <input
              type="text"
              id="localisation"
              name="localisation"
              value={formData.localisation}
              onChange={handleChange}
              className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Localisation"
              required
            />
          </div>

          <button
            className='flex items-center gap-x-3 justify-center bg-black-900 p-3 text-white font-semibold col-span-1 rounded-md'
            onClick={applyFilter}
          >
            <svg aria-hidden="true" className="w-5 h-5 text-white dark:text-gray-400 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
         
            Rechercher
          </button>
        </div>

        <div className='grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6'>
          {secteur.map((item, index) => (
            <CategoryCard item={item} key={index} selected={formData.secteur_id === item.id} setSelected={() => {
              setFormData({ ...formData, secteur_id: item.id });
              applyFilter()
            }} />
          ))}
        </div>
      </div>

      <div className='max-w-screen-xl px-4 py-8 mx-auto lg:pt-10 lg:px-6'>
        <p className='text-start'>{filteredServices.length} agences ou prestataires trouvés</p>
        <p className='flex items-center text-start gap-3 mb-4'>
          <img src={successIcon} alt="successIcon" className='w-5 h-5' />
          Réservez les meilleurs services auprès de nos experts
        </p>

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 mt-3">
          {filteredServices.map((item, index) => (
            <ServiceCard key={index} {...item} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Index;
