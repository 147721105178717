import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import Input from '../../../../components/form/input'
import { Button } from '@mui/material'
import GoBack from '../../../../components/btn/goBack'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../../api'
import Load from '../../../../components/load'
import Feedback from '../../../../components/feedback'

export default function VerificationEmail() {
    const navigate = useNavigate()
    const { email } = useParams()
    const { all, setAll } = useOutletContext()
    const [data, setData] = useState({
        code: ''
    })
    const [options, setOptions] = useState({
        disabled: true,
        loading: false
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    })

    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        //verification code

        if (data.code === all.codeGenerated) {
            try {
                const form = new FormData()
                form.append('email', email)
                axios.post(api + '/api/route', form)
                    .then(() => {
                        setOptions({ ...options, loading: false })
                        setIsOpen({ ...isOpen, success: true })
                        setTimeout(() => {
                            navigate('/admin/profile')
                        }, 500)
                    })
                    .catch((error) => {
                        console.error(error)
                        setOptions({ ...options, loading: false })
                        setIsOpen({ ...isOpen, fails: true })
                    })
            } catch (error) {
                console.error(error)
                setOptions({ ...options, loading: false })
                setIsOpen({ ...isOpen, fails: true })
            }
        } else {
            // alert('Code de verification incorrect')
            setOptions({ ...options, loading: false })
            setIsOpen({ ...isOpen, fails: true })
            // setIsOpen({...isOpen, fails: true })

        }
    }

    useEffect(() => {
        if (
            data.code != ''
        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])

    return (
        <div className="max-md:w-full max-lg:w-8/12 lg:w-5/12" >
            <Card>
                <form onSubmit={(e) => {
                    handleSubmit(e)
                }} className='flex flex-col w-full gap-y-3'>
                    <div className="flex flex-row justify-start items-center gap-x-3">
                        <GoBack />
                        <h4 className='w-full'>Verification d'e-mail</h4>
                    </div>
                    <Input label='Code de verification' value={data.code} onChange={(e) => { setData({ ...data, code: e.target.value }) }} />
                    <Button type='submit' variant="contained" disabled={options.disabled}>
                        <Load title='Verifier' loading={options.loading} />
                    </Button>
                </form>
            </Card>
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
            <Feedback type='success' message='Votre e-mail a été modifiée avec succèsn.' isOpen={isOpen.success} onClose={() => { setIsOpen({ ...isOpen, success: false }) }} />

        </div>

        // </div >
    )
}
