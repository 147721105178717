import React, { useEffect, useState } from 'react'
// import Input from '../../entrepreneur/components/form/Input'
// import { Button } from '@mui/material'
// import Load from '../../entrepreneur/components/load'
// import Notification from '../../entrepreneur/components/feedback/Notification'
import axios from 'axios'
import { api } from '../../../api'
import { useNavigate } from 'react-router-dom'
import Loading from '../../../websitePro/components/Loading'
import Input from '../../../component/Forms/Input'
import Feedback from '../../../admin/components/feedback'
import Button from '../../../websitePro/components/Button'

export default function ChagePassword({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false,success:false
    })
    const navigate = useNavigate()

    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        // setData({ ...data, codeGenerated: Code() })
        // console.log()
        try {
            setData({ ...data, codeGenerated: Math.ceil(Math.random() * 9001) + 999 })
            const form = new FormData()
            form.append('email', data.email)
            form.append('new_password', data.password)
            // form.append('password', data.confirmPassword)
            console.log(form)
            axios.post(api + '/api/forgotPassword', form)
                .then(response => {
                    console.log(response.data)
                    setOptions({ ...options, loading: false })
                    // setPage(prev => prev += 1)
                    setIsOpen({...isOpen, success: true })
                    setTimeout(() => {
                        navigate('/Login')
                    }, 500);
                    
                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsOpen({ ...isOpen, fails: true })
                    setOptions({ ...options, loading: false })
                });
        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }

    }



    useEffect(() => {
        if (data.password !== '' && data.confirmPassword !== '' && data.password === data.confirmPassword) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    
    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
            <Input label={"Mot de passe"} className='w-full' required={true} type="password" value={data.password} onChange={(e) => { setData({ ...data, password: e.target.value }) }} />
            <Input label={"Mot de passe de confirmation"} className='w-full' wrong={data.password !== '' && data.codeGenerated !== '' && (data.password !== data.confirmPassword)} required={true} type="password" value={data.confirmPassword} onChange={(e) => { setData({ ...data, confirmPassword: e.target.value }) }} />
            <Button onClick={(e) => { handleSubmit(e)}} disabled={options.disabled}>
                Terminer
                {/* <Loading width={7}/> */}
            </Button>
            <Feedback isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </form>
    )
}
