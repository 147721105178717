import React from 'react'

export default function TextAria({ label = '',value,onChange,placeholder }) {
    return (
        <div className="flex flex-col justify-start items-start w-full">
            {
                label !== '' && (
                    <label className='text-slate-400'>{label}</label>
                )
            }
            {/* <input value={value} onChange={onChange} placeholder={placeholder} type={type}  className='w-full h-8 border border-slate-500  rounded-sm' /> */}
            <textarea  value={value} onChange={onChange} placeholder={placeholder}  className='w-full  border border-slate-500  rounded-sm'  cols="30" rows="10"></textarea>
        </div>


    )
}
