import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import profile from '../../../../assets/image/profile.png'
import GoBack from '../../../components/btn/goBack'
import { Button } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../api'

export default function DetailsAdministrateur() {
    const navigate = useNavigate()
    const { id } = useParams()
    const [data, setData] = useState({})
    function disableAccount() {
        try {
            axios.put(api + '/api/route/' + id)
                .then(response => {
                    console.log(response)
                    fetchData()
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    function fetchData() {
        try {
            axios.get(api + '/api/getInfoClient/' + id)
                .then(response => setData(response.data))
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }
    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='w-full'>
            <Card>
                <div className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full flex flex-row gap-x-3 justify-start items-center ">
                        <GoBack />
                        <span className="font-bold text-slate-400">Details administrateur</span>
                    </div>
                    <img src={api + '/storage/' + data.photo} className='w-[150px]' />
                    <div className='grid grid-cols-3 gap-8'>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Nom</span>
                            <span className="text-[#434966]  font-medium">{data.nom}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Rôle</span>
                            <span className="text-[#434966]  font-medium">Administrateur</span>
                        </div>
                        {/* <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Age</span>
                            <span className="text-[#434966]  font-medium"></span>
                        </div> */}
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Numero de téléphone</span>
                            <span className="text-[#434966]  font-medium">{data.contact}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">E-mail</span>
                            <span className="text-[#434966]  font-medium">{data.email}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Adresse</span>
                            <span className="text-[#434966]  font-medium">{data.adresse}</span>
                        </div>
                        {/* <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Province</span>
                            <span className="text-[#434966]  font-medium"></span>
                        </div> */}
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Région</span>
                            <span className="text-[#434966]  font-medium">{data.region}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">District</span>
                            <span className="text-[#434966]  font-medium">{data.district}</span>
                        </div>

                    </div>
                    <div className='flex flex-row justify-end items-center w-full gap-3'>

                        <Button variant={"contained"} style={{ backgroundColor: 'red' }} onClick={() => { disableAccount() }}>
                            Desactiver ce compte
                        </Button>
                    </div>
                </div>
            </Card>
        </div>
    )
}
