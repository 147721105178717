import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Card from '../../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Input from '../../../components/form/input'
import Path from '../../../components/path';
import Status from '../../../components/Status';
import axios from 'axios';
import { api } from '../../../../api';
import Feedback from '../../../components/feedback';
import Load from '../../../components/load';
import { FaSpinner } from 'react-icons/fa';
import { exportToExcel } from '../../../components/ExcelExport';

export default function Abonnement() {
  const navigate = useNavigate()
  const { id } = useParams();
  const [abonnementId, setAbonnementId] = useState()
  const [emailUser, setEmailUser] = useState()
  const [data, setData] = useState([])
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
  })
  const [options, setOptions] = useState({
    // disabled: false,
    loading: false
  })
  const [search, setSearch] = useState('')
  const handleClose = () => {
    setAnchorEl(null);
  };
  function getStatus(status) {
    switch (status) {
      case "Confirmé":
        return 3;
      // break;
      case "En attente":
        return 2;
      // break;
      case "Expiré":
        return 1;
      // break;
      case "Refusé":
        return 4;

      default:
        return 2;
      // break;
    }
  }

  function fetchAllData() {
    try {
      axios.get(api + '/api/getAllAbo')
        .then(res => setData(res.data))
        .catch(err => {
          console.error(err)
        })
    } catch (error) {
      console.error(error)
    }
  }
  function fetchData() {
    try {

      axios.get(api + `/api/getAboUser/${id}`)
        .then(res => setData(res.data))
        .catch(err => {
          console.error(err)
        })
    } catch (error) {
      console.error(error)
    }
  }
  function changeStatus(status) {
    setOptions({ ...options, loading: true })
    try {
      const form = new FormData();
      form.append('etat', status);
      form.append('id', abonnementId);

      const emailForm = new FormData();
      form.append('email', emailUser);

      axios.post(api + '/api/changerEtatAbo', form)
        .then(res => {
          setIsOpen({ ...isOpen, loading: false })
          console.log(res.data);

          if (status === 'Confirmé') {
            axios.get(`${api}/api/emailConfirmerAbo`, emailForm)
              .then(response => {
                // setInfoPrest(response.data);
                console.log('Email de confirmation envoyé')
              })
              .catch(error => {
                console.error("Email de confirmation non envoyé", error);
              })
          }
          if (status === 'Refusé') {
            // Envoyer un email de refus
          }
          if (status === 'Expiré') {
            // Envoyer un email d'expiration
          }

          if (id === '0') {
            fetchAllData();
          } else {
            fetchData()
          }
          setIsOpen({ ...isOpen, success: true })

        })
        .catch(err => {
          console.error(err)
          setIsOpen({ ...isOpen, fails: true })
          setOptions({ ...options, loading: false })
        })
    } catch (error) {
      console.error(error)
      setIsOpen({ ...isOpen, fails: true })
      setOptions({ ...options, loading: false })

    }
  }

  useEffect(() => {
    if (id === '0') {
      fetchAllData();
    } else {
      fetchData()
    }
  }, [])
  return (
    <div className='w-full flex flex-col gap-y-3'>
      <div className="w-full flex flex-row justify-between items-center">

        <div className='flex flex-row w-full justify-end items-center'>
          <div className="flex flex-row justify-end items-center gap-x-1">
            <Input value={search} onChange={(e) => { setSearch(e.target.value) }} />
            <Button size='large' className="flex flex-row gap-x-3" variant="contained">
              <IoSearch />
              <span>Rechercher</span>
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <div className='flex flex-row justify-between items-center'>
          <h4 className="mb-2">Abonnement</h4>
          <Path
            currentName='Abonnement'
            path={[
              {
                name: 'Client pro',
                path: '/admin/prestataire'
              }]} />
        </div>
        <table className='w-full max-w-full '>
          <thead>
            <tr>
              <th>NUMERO</th>
              <th>TYPE</th>
              <th>PRIX (Ar)</th>
              <th>ETAT</th>
              <th>NOM</th>
              <th>EMAIL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              data.filter((item) => (search ? item.user?.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td>{item.type}</td>
                  <td>{item.prix}</td>
                  <td>
                    <Status type={getStatus(item.etat)} label={item.etat} />
                  </td>
                  <td>{item.user?.nom}</td>
                  <td>{item.user?.email}</td>

                  <td
                    className='flex flex-row justify-end items-center text-xl gap-x-3'
                  >
                    {
                      item.etat === 'En attente' && (

                        <Button
                          id="basic-button"
                          aria-controls={open ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={open ? 'true' : undefined}
                          onClick={(e) => { handleClick(e); setAbonnementId(item.id); setEmailUser(item.user?.email) }}
                        >
                          {
                            options.loading == false ?
                              (<IoEllipsisHorizontal className='text-lg' />) : (
                                <div className=" animate-spin duration-200 text-blue-600">
                                  <FaSpinner className='text-xl' />
                                </div>
                              )}

                        </Button>
                      )


                    }

                    <IoEye className='text-lg' onClick={() => { navigate(`/admin/detailsAbonnement/${item.id}`) }} />
                    {/* <IoCreate className='text-lg' onClick={() => { navigate(`/admin/modificationAbonnement/${item.id}`) }} /> */}
                    {/* <IoTrash className='text-lg' /> */}
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </Card>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem sx={{
          color: 'green', '&:hover': {
            backgroundColor: 'green',
            color: 'white'
          }
        }} onClick={() => {
          changeStatus('Confirmé')
          handleClose()
        }}>Confirmer</MenuItem>
        <MenuItem sx={{
          color: 'red', '&:hover': {
            backgroundColor: 'red',
            color: 'white'
          }
        }}
          onClick={() => {
            changeStatus('Refusé')
            handleClose()
          }}
        >Rejeter</MenuItem> <MenuItem sx={{
          color: 'orange', '&:hover': {
            backgroundColor: 'orange',
            color: 'white'
          }
        }}
          onClick={() => {
            changeStatus('Expiré')
            handleClose()
          }}
        >Expiré</MenuItem>
      </Menu>
      <Feedback
        isOpen={isOpen.success}
        type='success'
        message='Modifiée avec succès.'
        onClose={() => {
          setIsOpen({ ...isOpen, success: false })
        }}

      />
      <Feedback
        isOpen={isOpen.fails}
        type='error'
        message="Une erreur s'est produite."
        onClose={() => {
          setIsOpen({ ...isOpen, fails: false })
        }} />
    </div>
  )
}
