import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import photo from '../../../assets/image/404.png'
import Card from '../../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoPerson, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import Input from '../../../components/form/input'
import axios from 'axios';
import { api } from '../../../../api';
import Feedback from '../../../components/feedback';
import Modals from '../../../components/modal';
import Load from '../../../components/load';
import { exportToExcel } from '../../../components/ExcelExport';
export default function ServiceAdmin() {
  const navigate = useNavigate()
  const { id } = useParams()
  const [data, setData] = useState([])
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false,
    delete: false
  })
  const [search,setSearch]=useState('')
  const [options, setOptions] = useState({
    loading: false,
    disabled: false
  })
  const [serviceId, setServiceId] = useState()
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function fetchAllData() {
    try {
      axios.get(api + '/api/allServices')
        .then(res => {
          setData(res.data)
          console.log(res.data)
        })
        .catch(err => {
          console.error(err);
        })
    } catch (error) {
      console.error(error)
    }

  }

  function fetchData() {
    try {
      const form = new FormData()
      form.append('id', id)
      axios.get(api + `/api/servicesUserPro/${id}`)
        .then(res => {
          setData(res.data)
        })
        .catch(err => {
          console.error(err);
        })
    } catch (error) {
      console.error(error)
    }

  }

  function deleting() {
    setOptions({ ...options, loading: true });
    try {
      axios.delete(api + '/api/' + serviceId)
        .then(res => {
          setIsOpen({ ...isOpen, success: true })
          fetchData()
        })
        .catch(err => {
          setOptions({
            ...options,
            loading: false,

          })
          setIsOpen({ ...isOpen, fails: true })

          console.error(err)
        })

    } catch (error) {
      console.error(error)
      setOptions({ ...options, loading: false });
      setIsOpen({ ...isOpen, delete: false })
    }
  }
  function handleSubmit(e) {
    // e.preventDefault()
    setServiceId(e)
    setIsOpen({ ...isOpen, delete: true })
  }
  useEffect(() => {
    if (id === '0') {
      fetchAllData()
    } else {
      fetchData()
    }
  }, [])
  return (
    <div className='w-full flex flex-col gap-y-3'>
      <div className="w-full flex flex-row justify-between items-center">
        <div className='flex flex-row gap-x-3 justify-start items-center'>
          {/* <Button className="flex flex-row gap-x-3" variant="contained" onClick={() => { navigate('/admin/nouveauPrestataire') }}>
            <IoAddCircle />
            <span>Nouveau</span>
          </Button> */}
          <Button className="flex flex-row gap-x-3" variant="outlined"
            onClick={() => {exportToExcel(data,"Service") }}
          >
            <IoShare />
            <span>Exporter</span>
          </Button>
        </div>
        <div className='flex flex-row justify-end items-center'>
          <div className="flex flex-row justify-end items-center gap-x-1">
            <Input value={search} onChange={(e)=>{setSearch(e.target.value)}} />
            <Button className="flex flex-row gap-x-3" variant="contained">
              <IoSearch />
              <span>Rechercher</span>
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <h4 className="mb-2">Service</h4>
        <table className='w-full max-w-full '>
          <thead>
            <tr>
              <th>NUMERO</th>
              <th>PHOTO</th>
              <th>NOM</th>
              <th>ETAT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              data.filter((item) => (search ? item.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                <tr key={index} >
                  <td>{item.id}</td>
                  <td>
                    {
                      item.photo?
                        <img src={api + '/storage/' + item.photo} alt='image' className='w-10 h-10 rounded-full' />
                        :
                        <IoPerson className='w-10 h-10 text-gray-400' />
                    }
                  </td>
                  <td>{item.nom}</td>
                  <td>{item.etat}</td>
                  <td className='flex flex-row justify-end items-center text-xl gap-x-3'>
                    <Button id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e) => { handleClick(e); setServiceId(item.id) }} >
                      <IoEllipsisHorizontal className='text-lg' />
                    </Button>
                    <IoEye className='text-lg cursor-pointer' onClick={() => { navigate(`/admin/detailsService/${item.id}`) }} />
                    {/* <IoCreate className='text-lg' onClick={() => { navigate(`/admin/modificationService/${item.id}`) }} /> */}
                    {/* <IoTrash className='text-lg' onClick={() => { handleSubmit(item.id) }} /> */}
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Card>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { navigate('/admin/offre/' + serviceId) }}>Offre</MenuItem>

      </Menu>
      <Feedback
        isOpen={isOpen.success}
        message='Service supprimé avec succès'
        type="success"
        onClose={() => {
          setIsOpen({
            ...isOpen,
            success: false
          })
        }}
      />
      <Feedback
        isOpen={isOpen.fails}
        message="Une erreur s'est produit"
        type='error'
        onClose={() => {
          setIsOpen({
            ...isOpen,
            fails: false
          })
        }}
      />
      <Modals isOpen={isOpen.delete} onClose={() => {
        setIsOpen({ ...isOpen, delete: false })
      }}>

        <div className='p-4 text-center flex flex-col gap-y-3'>
          <h4> Suppression de service</h4>
          <p>Etes-vous sûr de vouloir supprimer ce service?</p>
          <div className='flex flex-row justify-center gap-x-3'>
            <Button fullWidth variant="outlined" onClick={() => { setIsOpen({ ...isOpen, delete: false }) }}>
              Annuler
            </Button>
            <Button fullWidth variant="contained" onClick={deleting}>
              <Load
                title='Supprimer'
                loading={options.loading}
              />
            </Button>

          </div>
        </div>

      </Modals>


    </div>
  )
}
