import { useState } from "react";

const region=["Analamanga","Bongolava","Itasy","Vakinankaratra","Diana","Sava","Amoron'i mania","Atsimo Atsinanana","Haute matsiatra","Ihorombe","Vatovavy","Fitovinany","Betsiboka","Boeny","Melaky","Sofia","Alaotra-mangoro","Analanjirofo","Antsinanana","Androy","Anosy","Atsimo-andrefana","Menabe"]
const district={
    "Analamanga":["Ambohidratrimo","Andramasina","Anjozorobe","Ankazobe","Antananarivo atsimondrano","Antananarivo avaradrano","Antananarivo renivohitra","Manjakandriana"]
    ,"Bongolava":["Fenoarivobe","Tsiroanomandidy"]
    ,"Itasy":["Arivonimamo","Miarinarivo","Soavinandriana"]
    ,"Vakinankaratra":["Ambatolampy","Antanifotsy","Antsirabe I","Antsirabe II","Betafo","Faratsiho","Mandoto"]
    ,"Diana":["Ambanja","Ambilobe","Antsiranana I","Antsiranana II","Nosy-be"]
    ,"Sava":["Andapa","Antalaha","Sambava","Vohemar"]
    ,"Amoron'i mania":["Ambatofinandrahana","Ambositra","Fandriana","Manandriana"]
    ,"Atsimo Atsinanana":["Befotaka atsimo","Farafangana","Midongy sud","Vangaindrano","Vondrozo"]
    ,"Haute matsiatra":["Ambalavao","Ambohimahasoa","Fianarantsoa","Isandra","Ikalamavony","Vohibato","Lalangina"]
    ,"Ihorombe":["Iakora","Ihosy","Ivohibe"]
    ,"Vatovavy":["Ifanadiana","Mananjary","Nosy varika"]
    ,"Fitovinany":["Ikongo","Manakara","Vohipeno"]
    ,"Betsiboka":["Kandreho","Maevatanana","Tsaratanana"]
    ,"Boeny":["Ambato boeny","Mahajanga I","Mahajanga II","Marovoay","Mitsinjo","Soalala"]
    ,"Melaky":["Ambatomainty","Antsalova","Besalampy","Maintirano","Morafenobe"]
    ,"Sofia":["Analalava","Antsohihy","Befandriana nord","Mampikony","Mandritsara","Port-berge"]
    ,"Alaotra-mangoro":["Ambatondrazaka","Amparafaravola","Andilamena","Anosibe an'ala","Moramanga"]
    ,"Analanjirofo":["Fenerive est","Mananara-nord","Maroantsetra","Sainte marie","Soanierana ivongo","Vavatenina"]
    ,"Antsinanana":["Antanambao manampontsy","Brickaville","Mahanoro","Marolambo","Toamasina I","Toamasina II","Vatomandry"]
    ,"Androy":["Ambovombe androy","Bekily","Beloha androy","Tsihombe"]
    ,"Anosy":["Amboasary sud","Betroka","Taolanaro"]
    ,"Atsimo-andrefana":["Ampanihy ouest","Ankazoabo sud","Benenitra","Beroroha","Betioky sud","Morombe","Sakaraha","Toliara I","Toliara II"]
    ,"Menabe":["Belo sur tsiribihina","Mahabo","Manja","Miandrivazo","Mahabo","Manja","Miandrivo","Morondava"]
}
export {
    region,district
}