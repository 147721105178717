import React, { useState } from 'react';

const generateSlots = (
  openingHour,
  openingMinute,
  breakStartHour,
  breakStartMinute,
  breakEndHour,
  breakEndMinute,
  closingHour,
  closingMinute,
  intervalHour,
  intervalMinute
) => {
  const slots = [];
  let currentHour = openingHour;
  let currentMinute = openingMinute;

  const addSlot = (hour, minute) => {
    slots.push({
      time: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
    });
  };

  while (currentHour < closingHour || (currentHour === closingHour && currentMinute < closingMinute)) {
    if (
      (currentHour > breakStartHour || (currentHour === breakStartHour && currentMinute >= breakStartMinute)) &&
      (currentHour < breakEndHour || (currentHour === breakEndHour && currentMinute < breakEndMinute))
    ) {
      currentHour = breakEndHour;
      currentMinute = breakEndMinute;
    } else {
      addSlot(currentHour, currentMinute);

      currentMinute += intervalMinute;
      currentHour += intervalHour;

      if (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour += 1;
      }
    }
  }

  return slots;
};

const Slots = ({
  dateSelected,
  openingHour,
  openingMinute,
  breakStartHour,
  breakStartMinute,
  breakEndHour,
  breakEndMinute,
  closingHour,
  closingMinute,
  intervalHour,
  intervalMinute,
  setTimeSelected,
  setHourSelected,
  setMinuteSelected,
  textSize = 'text-sm',
}) => {
  const [selectedSlot, setSelectedSlot] = useState(null);

  const slots = generateSlots(
    openingHour,
    openingMinute,
    breakStartHour,
    breakStartMinute,
    breakEndHour,
    breakEndMinute,
    closingHour,
    closingMinute,
    intervalHour,
    intervalMinute
  );

  const currentDateTime = new Date(); // Date et heure actuelles
  const selectedDate = new Date(dateSelected); // Convertir la date sélectionnée en objet Date

  const handleSlotClick = (slot) => {
    setTimeSelected(slot.time);

    const [hours, minutes] = slot.time.split(':').map(Number);
    setHourSelected(hours);
    setMinuteSelected(minutes);
    setSelectedSlot(slot.time);
  };

  return (
    <div className="grid grid-cols-4 gap-4 py-6">
      {slots.map((slot, index) => {
        // const [slotHour, slotMinute] = slot.time.split(':').map(Number);
        // const slotDateTime = new Date(selectedDate); // Copier la date sélectionnée
        // slotDateTime.setHours(slotHour, slotMinute); // Définir l'heure et les minutes du créneau

        // // Comparer le créneau avec l'heure actuelle uniquement si la date sélectionnée est aujourd'hui
        // const isToday = selectedDate.toDateString() === currentDateTime.toDateString();

        const currentDate = new Date();
        const [day, month, year] = dateSelected.split('-').map(Number);
        // const selectedDate = new Date(year, month - 1, day); // Convert to Date object
        // const isDisabled = isToday && slotDateTime < currentDateTime;
        const [slotHour, slotMinute] = slot.time.split(':').map(Number)
        const slotDateTime = new Date(year, month - 1, day, slotHour, slotMinute)

        const isDisabled = slotDateTime < currentDate

        return (
          <button
            key={index}
            onClick={() => !isDisabled && handleSlotClick(slot)}
            className={`${textSize} p-2 border rounded ${
              isDisabled
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed'
                : 'text-black-900 hover:bg-black-900 hover:text-white ease-in duration-100'
            } ${
              selectedSlot === slot.time ? 'bg-black-900 text-white ' : 'text-gray-700'
            }`}
            disabled={isDisabled}
          >
            {slot.time}
          </button>
        );
      })}
    </div>
  );
};

export default Slots;
