import React, { useEffect } from 'react'
import { useOutletContext } from 'react-router-dom'


const Index = () => {
  const { setTitle } = useOutletContext()
  
  useEffect(() => {

    setTitle('Paramètre')

}, [setTitle])

  return (
    <div>index</div>
  )
}

export default Index