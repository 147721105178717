import React, {useState, useEffect} from 'react'
import CategoryCard from '../Home/partials/Categories/CategoryCard'
import { categories, prestataires } from "../../Datas/Datas"
import successIcon from "../../assets/icons/successIcon.png"
import OfferPic from "../../assets/images/prestataire.png"
import PrestataireCard from '../../components/Card/PrestataireCard'
import imagepurple from "../../assets/images/bgpurple.png"
import axios from 'axios'
import { api } from '../../../api'
import { Link } from 'react-router-dom'

const UserPro = () => {
    const [formData, setFormData] = useState({
        Offer:'',
        agent: '',
        localisation: '',
        secteur: ''
      })

      const data = [
        {
          id:1,
          name: 'Pineapple',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          imgUrl: 'https://images.pexels.com/photos/6157052/pexels-photo-6157052.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        },
        {
          id:3,
          name: 'Banana',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          imgUrl: 'https://images.pexels.com/photos/5966630/pexels-photo-5966630.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        },
        {
          id:3,
          name: 'Mango',
          description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
          imgUrl: 'https://images.pexels.com/photos/5217960/pexels-photo-5217960.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
        },
      ];

      const [userPro, setUserPro] = useState([])
      const [filteredPrestataires, setFilteredPrestataires] = useState([]);
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      }
      useEffect(() => {
        
          axios.get(api + `/api/allPrestataires`)
            .then(response => {
              setUserPro(response.data);
              setFilteredPrestataires(response.data);
            })
            .catch(error => {
              console.error("Erreur lors de la récupération des prestataires:", error);
            })

      }, [])

      const applyFilter = () => {
        const filtered = userPro.filter((item) => {
          return (
            (formData.agent === '' || item.nom.toLowerCase().includes(formData.agent.toLowerCase())) &&
            (formData.localisation === '' || item.localisation.toLowerCase().includes(formData.localisation.toLowerCase()))
            // (formData.secteur_id === '' || item.secteur_id === formData.secteur_id)
          );
        });
        setFilteredPrestataires(filtered);
      };
      
      return (
        <div className=''>
          {/* div en dessous */}
          <div className=" bg-black/20 h-[400px] z-10">
            <div className="h-full flex justify-center p-4 bg-gray-900/50 items-end z-15 ">
              <div className=' bg-black-900/10 absolute right-0 top-0 h-[400px] w-[100%] z-10'>
              </div>
              <img src={OfferPic} className="absolute object-cover right-0 top-0 h-[400px] w-full z-2" alt='Logo'/>
            </div>
        </div>
        {/* div au dessu */}
        <div className="w-[86%] bg-white rounded-md shadow-md mx-auto p-5 -mt-20 z-20  flex-col relative" >
              <div className="grid grid-cols-1 md:grid-cols-5 gap-3 mb-2">
                  
                  <div className="relative mt-1 col-span-2">
                    {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                        <path d="M10.036 8.278 19.294.488A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                        <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                      </svg>
                    </div> */}
                    <input
                      type="text"
                      id="agent"
                      name="agent"
                      // value={formData.agent}
                      onChange={handleChange}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Nom des agences, prestataires, professionnels"
                      required
                    />
                  </div>
    
    
                  <div className="relative mt-1 col-span-2">
                    {/* <div className="absolute inset-y-0 start-0 flex items-center ps-3.5 pointer-events-none">
                      <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 16">
                        <path d="M10.036 8.278 19.294.488A1.979 1.979 0 0 0 18 0H2A1.987 1.987 0 0 0 .641.541l9.395 7.737Z"/>
                        <path d="M11.241 9.817c-.36.275-.801.425-1.255.427-.428 0-.845-.138-1.187-.395L0 2.6V14a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V2.5l-8.759 7.317Z"/>
                      </svg>
                    </div> */}
                    <input
                      type="search"
                      id="localisation"
                      name="localisation"
                      // value={formData.agent}
                      onChange={handleChange}
                      className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      placeholder="Localisation"
                      required
                    />
                  </div>
    
                  <button
            className='flex items-center gap-x-3 justify-center bg-black-900 p-3 text-white font-semibold col-span-1 rounded-md'
            onClick={applyFilter}
          >
            <svg aria-hidden="true" className="w-5 h-5 text-white dark:text-gray-400 cursor-pointer" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
         
            Rechercher
          </button> </div>
                
            <div class="grid grid-cols-1 md:grid-cols-3 gap-3 mb-2  w-full">
</div>

              </div>
          <div className='max-w-screen-xl px-4 py-8 mx-auto lg:py-20 lg:px-6'>
            <p className='text-start'>{filteredPrestataires.length} agences ou prestataires de trouvé</p>
            <p className='flex items-center text-start gap-3 mb-8'><img src={successIcon} alt="successIcon" className='w-5 h-5'/> Reserver les meilleurs services auprès de nos expert</p>
            <div className=" py-6 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {filteredPrestataires.map((item, index) => (
              <Link to={"/prestataire/"+item.id} key={index} className="bg-white shadow-md rounded hover:scale-105  transform duration-100 ease-in">
                <div className="h-64 w-full">
                  <img
                    className=" w-full h-full object-cover rounded-t "
                    src={api + '/storage/' + item.photo}
                    alt={item.nom}
                  />
                </div>
                <div className="p-4">
                  <a href="#" className=" text-gray-700">
                    <span className="text-lg font-semibold uppercase tracking-wide">
                      {item.nom}
                    </span>
                  </a>
                  <p className="text-gray-600 text-sm leading-5 mt-2">{item.description}</p>
                </div>
              </Link>
            ))}
    </div>
            </div>

            
          
        </div>
  )
}

export default UserPro