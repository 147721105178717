import React, { useState } from 'react';
import ButtonBack from '../../components/Button/ButtonBack';
import Button from "../../components/Button";
import Input from "../../../component/Forms/Input";
import Textarea from "../../../component/Forms/Textarea";
import Select from "../../../component/Forms/Select";
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { api } from '../../../api';
import Feedback from '../../../admin/components/feedback'
import ConfirmModal from '../../components/Modal/ConfirmModal';

const AddOffer = () => {
    const { id } = useParams();
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const [showErrorModal, setShowErrorModal] = useState(false)
    const [showAcceptModal, setShowAcceptModal] = useState(false)
    const [offerData, setOfferData] = useState({
        nom: '',
        description: '',
        prix: '',
        duree_heure: '',
        duree_minute: '',
        status: 'Publié',
        photo: null,
    });

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === "photo" && files && files.length > 0) {
            setOfferData({
                ...offerData,
                [name]: files[0], // Stocker le fichier directement
            });
        } else {
            setOfferData({
                ...offerData,
                [name]: value,
            });
        }
    };

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('nom', offerData.nom);
        formData.append('prix', offerData.prix);
        formData.append('description', offerData.description);
        formData.append('duree_heure', offerData.duree_heure);
        formData.append('duree_minute', offerData.duree_minute);
        formData.append('status', offerData.status)
        formData.append('photo', offerData.photo)

        // if (offerData.photo) {
        //     formData.append('photo', offerData.photo)
        // }
        console.log(offerData)

     
         await axios.post(api + `/api/ajoutOffre/${id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            }).then(()=>{
                setShowAcceptModal(false)
                setShowSuccessModal(true)})
            .catch(()=>{
                setShowAcceptModal(false)
                setShowErrorModal(true)}) 
            // console.log('Offer created:', response.data);  
        
    }

    // Désactiver le bouton si un des champs obligatoires est vide
    const isSubmitDisabled = !offerData.nom || !offerData.description || !offerData.prix || !offerData.duree_heure || !offerData.duree_minute;

    return (
        <div className='px-6 mx-6'>
            <div className="flex justify-between items-center">
                <ButtonBack path={"/pro/service/" + id + "/offers"} text={"Créer une nouvelle offre"} />
                <Button onClick={()=>setShowAcceptModal(true)} disabled={isSubmitDisabled}>Enregistrer</Button>
            </div>
            <div className="grid grid-cols-6 gap-3 my-5">
                <div className="col-span-4 space-y-4">
                    <div className="border-line">
                        <Input
                            label={"Nom"}
                            name={"nom"}
                            className='w-full'
                            placeholder='Ajouter un nom...'
                            onChange={handleInputChange}
                        />
                        <Textarea
                            label={"Description"}
                            name="description"
                            id="description"
                            placeholder='Ecrire une description...'
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="border-line">
                        <label htmlFor="image" className='text-sm font-medium text-gray-700 mb-1 text-start flex justify-start'>Photo</label>
                        <div
                            className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer"
                        >
                            <input
                                name="photo"
                                type="file"
                                className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                                onChange={handleInputChange}
                            />
                            <div className="flex flex-col items-center justify-center py-10 text-center">
                                {offerData.photo ? (
                                    <img
                                        src={URL.createObjectURL(offerData.photo)}
                                        alt="Selected"
                                        className="w-32 h-32 object-cover rounded-full"
                                    />
                                ) : (
                                    <div className='bg-orange-400/10 flex items-center justify-center p-2 text-primary rounded-full'>
                                        <svg
                                            className="w-6 h-6 text-current-50"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                    </div>
                                )}
                                <p className="m-0">Glisser l’image ici ou cliquer sur Ajouter une image.</p>
                            </div>
                        </div>
                    </div>
                    <div className="border-line">
                        <Input
                            label={"Prix (Ar)"}
                            name={"prix"}
                            className='w-full'
                            onChange={handleInputChange}
                            type='number'
                        />
                        <Input
                            label={"Durée en heure"}
                            name={"duree_heure"}
                            className='w-full'
                            onChange={handleInputChange}
                            type='number'
                        />
                        <Input
                            label={"Durée en minute"}
                            name={"duree_minute"}
                            className='w-full'
                            onChange={handleInputChange}
                            type='number'
                        />
                    </div>
                </div>

                <div className="col-span-2">
                    <div className="border-line">
                        <Select
                            label={"Etat"}
                            name="status"
                            placeholder='Seléctionner un état...'
                            options={["Publié", "Non publié"]}
                            onChange={handleInputChange}
                        />
                    </div>
                </div>
            </div>
            {/* Confirm modal */}
            <ConfirmModal 
        show={showAcceptModal} 
        onClose={()=>setShowAcceptModal(false)}
        // variant='danger'
        title="Confirmation"
        text="Êtes-vous sûr de vouloir enregistrer cette nouvelle offre"
        buttonText="Enregistrer"
        action={handleSubmit}
      />
            {/* Feedback success or error */}
            <Feedback isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)} message='Enregistrement réussie !' />
            <Feedback isOpen={showErrorModal} onClose={() => setShowErrorModal(false)} type='error' message='Une erreur est survénue !' />
        </div>
    );
}

export default AddOffer;
