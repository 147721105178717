import React, { useEffect, useState } from 'react';
import boat from '../../assets/images/boat.jpg';
import arrowleft from "../../../website/assets/icons/angle-right.svg";
import { Link, useNavigate, useParams } from 'react-router-dom'
import {serviceDetail, offerByIdService} from '../../Datas/Datas'
import axios from 'axios';
import { api } from '../../../api'
import AvalaibilityDay from './AvalaibilityDay'

const ServiceDetail = () => {
    const navigate = useNavigate();
    const { id } = useParams();
    // const [service, setService] = useState({});
    // const [offres, setOffres] = useState([]);
    const [service, setService] = useState([]);
    const [offres, setOffres] = useState([]);
    const [disponibilite, setDisponibilite] = useState(
        {
        lundi: 0,
        mardi: 0,
        mercredi: 0,
        jeudi: 0,
        vendredi: 0,
        samedi: 0,
        dimanche: 0,
      }
  )

    useEffect(() => {
        // Appel API pour récupérer les détails du service
        axios.get(api + `/api/getDetailServiceUser/${id}`)
            .then(response => {
                // Traiter la réponse si tout se passe bien
                setService(response.data)
            })
            .catch(error => console.error('Erreur lors de la récupération du service:', error));
        
        // Appel API pour récupérer les offres associées
        axios.get(api + `/api/allOffresService/${id}`)
            .then(response => {
                // Traiter la réponse si tout se passe bien
                setOffres(response.data)
            })
            .catch(error => console.error('Erreur lors de la récupération des offres:', error));
   
        axios.get(api + `/api/jourOuvrableService/${id}`)
        .then((response) => {
            if(response.data.length !== 0){
            setDisponibilite(response.data[0])
            }
            // setIdSelected(response.data.id)
            console.log("Avalaibility day: "+response.data+" Id sélectionné:")
        })
        .catch((error) => console.error('Erreur lors de la récupération des jours ouvrables:', error));
      
        }, [id])

        const isOpen =(status)=>{
            if(status){
                return "Ouvert"
            }else{
                return "Fermé"
            }
        }


    return (
        <div className='pt-16'>
            <section className="px-5 py-10 text-start">
                <button className="p-2 border bg-white my-5 rounded-full ml-16" onClick={() => navigate('/service')}>
                    <img src={arrowleft} alt="arrow" className='w-5 h-5 rotate-180' />
                </button>
                <div className="bg-white rounded-md container grid grid-cols-12 mx-auto px-6 gap-y-6 md:gap-10">
                    {/* Colonne gauche: Informations sur le prestataire ou l'entreprise */}
                    <div className="flex flex-col justify-between col-span-12 py-2 space-y-8 md:space-y-16 md:col-span-3 ">
                        <div className="flex flex-col space-y-8 md:space-y-12">
                            <div className="flex flex-col space-y-2">
                                <h3 className="flex items-center space-x-2">
                                    {/* <span className="text-xs font-bold tracking-wider uppercase">Adresse du service</span> */}
                                </h3>
                                <a rel="noopener noreferrer" href="#" className="hover:underline">{service.titre}</a>
                                <p className="">Publié le {new Date(service.created_at).toLocaleDateString()} par {service.user?.nom}
                                    <a rel="noopener noreferrer" href="#" className="hover:underline"> {service.idPrestataire}</a>
                                </p>
                                <p className=''> {service.adresse}</p>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <h3 className="flex items-center space-x-2">
                                    <span className="text-xs font-bold tracking-wider uppercase">Contact</span>
                                </h3>
                                <a rel="noopener noreferrer" href="#" className="hover:underline">{service.user?.email}</a>
                                <p className="">{service.user?.contact}</p>
                                <a rel="noopener noreferrer" href="#" className="hover:underline">{service.user?.adresse}</a>
                            </div>
                            <div className="flex flex-col space-y-2">
                                <h3 className="flex items-center space-x-2">
                                    <span className="text-xs font-bold tracking-wider uppercase">Description</span>
                                </h3>
                                <p className="">{service.description}</p>
                            </div>

                            <div className="flex flex-col">
                                <ul><AvalaibilityDay item={disponibilite}></AvalaibilityDay>
                                    {/* <li>Lundi: {isOpen(disponibilite.lundi)}</li>
                                    <li>Mardi:  {isOpen(disponibilite.lundi)}</li>
                                    <li>Mercredi:{isOpen(disponibilite.mercredi)}</li>
                                    <li>Jeudi:{isOpen(disponibilite.jeudi)}</li>
                                    <li>Vendresi {isOpen(disponibilite.vendredi)}</li>
                                    <li>Samedi {isOpen(disponibilite.samedi)}</li>
                                    <li>Dimanche {isOpen(disponibilite.dimanche)}</li> */}
                                </ul>
                            </div>
                        </div>
                    </div>

                    {/* Colonne centrale: Image du service */}
                    <div className="relative flex col-span-12 bg-center bg-no-repeat bg-cover xl:col-span-6 lg:col-span-5 md:col-span-9 min-h-96" style={{ backgroundImage: `url(${api+'/storage/'+service.photo})` }}>
                        <span className="absolute px-1 pb-2 text-xs font-bold uppercase border-b-2 left-6 top-6 text-white">{service.user?.district}</span>
                       
                    </div>

                    {/* Colonne droite: Liste des offres */}
                    <div className="py-2 xl:col-span-3 lg:col-span-4 text-start col-span-12">
                        {/* <div className="mb-8 space-x-5 border-b-2 border-opacity-10">
                            <button type="button" className="pb-5 text-xs font-bold uppercase border-b-2 border-primary">Dernières Offres</button>
                            <button type="button" className="pb-5 text-xs font-bold uppercase border-b-2 border-b-transparent">Populaires</button>
                        </div> */}
                        <div className="flex flex-col divide-y">
                            {offres.map((offre, index) => (
                                <div key={index} className="flex px-1 py-4">
                                    <img alt="" className="flex-shrink-0 object-cover w-20 h-20 mr-4" src={api + '/storage/' + offre.photo} />
                                    <div className="flex flex-col flex-grow">
                                        <Link rel="noopener noreferrer" to={"/booking/" + offre.id} className="hover:underline">{offre.nom}</Link>
                                        <p className="mt-auto text-xs">Publié le {new Date(offre.created_at).toLocaleDateString()} à {service.user?.district} 
                                            {/* <a rel="noopener noreferrer" href="#" className="block lg:ml-2 lg:inline hover:underline">{offre.responsable}</a> */}
                                        </p>
                                        <p className="text-sm font-bold">{offre.prix} Ar</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}


export default ServiceDetail