import React, { useEffect, useRef, useState } from "react"
import { FaEllipsis, FaEllipsisVertical } from "react-icons/fa6"
import { useNavigate } from "react-router-dom"
import ModalDelete from "../Modal/ModalDelete"
import ModalServiceDetail from "../Modal/ModalServiceDetail "
import ConfirmModal from "../Modal/ConfirmModal"
import axios from "axios"
import { api } from "../../../api"

export default function DropdownMenu({ idSelected, serviceSelected}) {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showDetailModal, setShowDetailModal] = useState(false)
  const navigate = useNavigate()
  const trigger = useRef(null);
  const dropdown = useRef(null)
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
})

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!dropdown.current) return;
      if (
        !dropdownOpen ||
        dropdown.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setDropdownOpen(false);
    }
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  })

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  })

  // Api de suppression de service Luciano
  const handleDelete = ()=>{
    // setShowDeleteModal(false)
    axios.post(api+"/api/deleteService/"+idSelected)
    .then((res)=>{
      console.log(res)
      setShowDeleteModal(false)
      setIsOpen({ ...isOpen, success: true });
    })
    .catch((err)=>{
      setShowDeleteModal(false)
      setIsOpen({ ...isOpen, fail: true });
    })
  }

  return (
    <section className="bg-gray-2  dark:bg-dark">
      <div className="container">
        <div className="flex justify-center">
          <div className=" inline-block">
            <button
              ref={trigger}
              onClick={() => setDropdownOpen(!dropdownOpen)}
                 className="flex items-center justify-end gap-2 rounded-md p-2 text-base font-medium text-dark
             "
            >
              
              <FaEllipsisVertical/>
            </button>
            <div
              ref={dropdown}
              onFocus={() => setDropdownOpen(true)}
              onBlur={() => setDropdownOpen(false)}
              className={`absolute right-0 top-full w-[240px] divide-y divide-stroke overflow-hidden z-50 rounded-lg bg-white border shadow-md  ${dropdownOpen ? "block" : "hidden"}`}
            >
             
             <div>
  <span

  onClick={()=>setShowDetailModal(true)}

    // href="/formation/see"
    className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  cursor-pointer"
  >
    <span className="flex items-center gap-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
       <path d="M18.53 9L13 3.47C12.8595 3.32931 12.6688 3.25018 12.47 3.25H8C7.27065 3.25 6.57118 3.53973 6.05546 4.05546C5.53973 4.57118 5.25 5.27065 5.25 6V18C5.25 18.7293 5.53973 19.4288 6.05546 19.9445C6.57118 20.4603 7.27065 20.75 8 20.75H16C16.7293 20.75 17.4288 20.4603 17.9445 19.9445C18.4603 19.4288 18.75 18.7293 18.75 18V9.5C18.7421 9.3116 18.6636 9.13309 18.53 9ZM13.25 5.81L16.19 8.75H13.25V5.81ZM16 19.25H8C7.66848 19.25 7.35054 19.1183 7.11612 18.8839C6.8817 18.6495 6.75 18.3315 6.75 18V6C6.75 5.66848 6.8817 5.35054 7.11612 5.11612C7.35054 4.8817 7.66848 4.75 8 4.75H11.75V9.5C11.7526 9.69811 11.8324 9.88737 11.9725 10.0275C12.1126 10.1676 12.3019 10.2474 12.5 10.25H17.25V18C17.25 18.3315 17.1183 18.6495 16.8839 18.8839C16.6495 19.1183 16.3315 19.25 16 19.25Z" fill="currentColor"/>

      </svg>
      Détail
    </span>
  </span>
</div>
<div>
  <span
  onClick={()=>navigate('/pro/service/'+idSelected+'/offers')}
    // href="/formation/see"
    className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  cursor-pointer"
  >
    <span className="flex items-center gap-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M18 4.25H6C5.27065 4.25 4.57118 4.53973 4.05546 5.05546C3.53973 5.57118 3.25 6.27065 3.25 7V17C3.25 17.7293 3.53973 18.4288 4.05546 18.9445C4.57118 19.4603 5.27065 19.75 6 19.75H18C18.7293 19.75 19.4288 19.4603 19.9445 18.9445C20.4603 18.4288 20.75 17.7293 20.75 17V7C20.75 6.27065 20.4603 5.57118 19.9445 5.05546C19.4288 4.53973 18.7293 4.25 18 4.25ZM19.25 7V11.25H12.75V5.75H18C18.3315 5.75 18.6495 5.8817 18.8839 6.11612C19.1183 6.35054 19.25 6.66848 19.25 7ZM6 5.75H11.25V11.25H4.75V7C4.75 6.66848 4.8817 6.35054 5.11612 6.11612C5.35054 5.8817 5.66848 5.75 6 5.75ZM4.75 17V12.75H11.25V18.25H6C5.66848 18.25 5.35054 18.1183 5.11612 17.8839C4.8817 17.6495 4.75 17.3315 4.75 17ZM18 18.25H12.75V12.75H19.25V17C19.25 17.3315 19.1183 17.6495 18.8839 17.8839C18.6495 18.1183 18.3315 18.25 18 18.25Z" fill="currentColor"/>

      </svg>
      Gérer les offres
    </span>
  </span>
</div>
<div>
  <span
  onClick={()=>navigate('/pro/service/availability/'+idSelected)}
    // href="/formation/see"
    className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  cursor-pointer"
  >
    <span className="flex items-center gap-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M17 4.75H15.75V3.5C15.75 3.30109 15.671 3.11032 15.5303 2.96967C15.3897 2.82902 15.1989 2.75 15 2.75C14.8011 2.75 14.6103 2.82902 14.4697 2.96967C14.329 3.11032 14.25 3.30109 14.25 3.5V4.75H9.75V3.5C9.75 3.30109 9.67098 3.11032 9.53033 2.96967C9.38968 2.82902 9.19891 2.75 9 2.75C8.80109 2.75 8.61032 2.82902 8.46967 2.96967C8.32902 3.11032 8.25 3.30109 8.25 3.5V4.75H7C6.27065 4.75 5.57118 5.03973 5.05546 5.55546C4.53973 6.07118 4.25 6.77065 4.25 7.5V18.5C4.25 19.2293 4.53973 19.9288 5.05546 20.4445C5.57118 20.9603 6.27065 21.25 7 21.25H17C17.7293 21.25 18.4288 20.9603 18.9445 20.4445C19.4603 19.9288 19.75 19.2293 19.75 18.5V7.5C19.75 6.77065 19.4603 6.07118 18.9445 5.55546C18.4288 5.03973 17.7293 4.75 17 4.75ZM7 6.25H8.25V7.5C8.25 7.69891 8.32902 7.88968 8.46967 8.03033C8.61032 8.17098 8.80109 8.25 9 8.25C9.19891 8.25 9.38968 8.17098 9.53033 8.03033C9.67098 7.88968 9.75 7.69891 9.75 7.5V6.25H14.25V7.5C14.25 7.69891 14.329 7.88968 14.4697 8.03033C14.6103 8.17098 14.8011 8.25 15 8.25C15.1989 8.25 15.3897 8.17098 15.5303 8.03033C15.671 7.88968 15.75 7.69891 15.75 7.5V6.25H17C17.3315 6.25 17.6495 6.3817 17.8839 6.61612C18.1183 6.85054 18.25 7.16848 18.25 7.5V10.25H5.75V7.5C5.75 7.16848 5.8817 6.85054 6.11612 6.61612C6.35054 6.3817 6.66848 6.25 7 6.25ZM17 19.75H7C6.66848 19.75 6.35054 19.6183 6.11612 19.3839C5.8817 19.1495 5.75 18.8315 5.75 18.5V11.75H18.25V18.5C18.25 18.8315 18.1183 19.1495 17.8839 19.3839C17.6495 19.6183 17.3315 19.75 17 19.75Z" fill="currentColor"/>
<path d="M14 15.25H12.75V14C12.75 13.8011 12.671 13.6103 12.5303 13.4697C12.3897 13.329 12.1989 13.25 12 13.25C11.8011 13.25 11.6103 13.329 11.4697 13.4697C11.329 13.6103 11.25 13.8011 11.25 14V15.25H10C9.80109 15.25 9.61032 15.329 9.46967 15.4697C9.32902 15.6103 9.25 15.8011 9.25 16C9.25 16.1989 9.32902 16.3897 9.46967 16.5303C9.61032 16.671 9.80109 16.75 10 16.75H11.25V18C11.25 18.1989 11.329 18.3897 11.4697 18.5303C11.6103 18.671 11.8011 18.75 12 18.75C12.1989 18.75 12.3897 18.671 12.5303 18.5303C12.671 18.3897 12.75 18.1989 12.75 18V16.75H14C14.1989 16.75 14.3897 16.671 14.5303 16.5303C14.671 16.3897 14.75 16.1989 14.75 16C14.75 15.8011 14.671 15.6103 14.5303 15.4697C14.3897 15.329 14.1989 15.25 14 15.25Z" fill="currentColor"/>

      </svg>
      Disponibilité
    </span>
  </span>
</div>

<div>
  <span
  onClick={()=>navigate('/pro/service/edit/'+idSelected)}
    // href="formation/1"
    className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50  cursor-pointer"
  >
    <span className="flex items-center gap-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M4.20999 20.5199C4.11375 20.521 4.01826 20.5029 3.92902 20.4669C3.83977 20.4308 3.75854 20.3775 3.68999 20.3099C3.61139 20.2323 3.55092 20.1383 3.51288 20.0346C3.47485 19.9308 3.4602 19.82 3.46999 19.7099L3.77999 15.8699C3.79328 15.6916 3.87156 15.5244 3.99999 15.3999L15.06 4.33995C15.6762 3.76286 16.4961 3.45361 17.34 3.47995C18.1784 3.48645 18.9828 3.81181 19.59 4.38995C20.1723 4.98795 20.5073 5.7839 20.5277 6.61837C20.5481 7.45284 20.2524 8.26421 19.7 8.88995L8.62999 19.9999C8.50609 20.1234 8.34386 20.201 8.16999 20.2199L4.27999 20.5699L4.20999 20.5199ZM5.20999 16.2599L4.99999 18.9999L7.73999 18.7499L18.64 7.82995C18.8525 7.57842 18.9884 7.27118 19.0314 6.94472C19.0745 6.61827 19.0229 6.28631 18.8828 5.9883C18.7428 5.69028 18.5201 5.43873 18.2413 5.26354C17.9625 5.08834 17.6393 4.99685 17.31 4.99995C17.0936 4.98621 16.8766 5.01633 16.6721 5.0885C16.4676 5.16067 16.2798 5.27341 16.12 5.41995L5.20999 16.2599Z" fill="currentColor"/>

      </svg>
      Modifier
    </span>
  </span>
</div>

<div>
  <button 
  onClick={()=>setShowDeleteModal(true)}
  className="flex w-full items-center justify-between px-4 py-2.5 text-sm font-medium text-dark hover:bg-gray-50 text-red-600">
    <span className="flex items-start gap-2">
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M20 8.70007H4C3.90151 8.70007 3.80398 8.68067 3.71299 8.64298C3.62199 8.60529 3.53931 8.55005 3.46967 8.4804C3.40003 8.41076 3.34478 8.32808 3.30709 8.23709C3.2694 8.14609 3.25 8.04856 3.25 7.95007C3.25 7.85158 3.2694 7.75405 3.30709 7.66306C3.34478 7.57207 3.40003 7.48939 3.46967 7.41974C3.53931 7.3501 3.62199 7.29486 3.71299 7.25716C3.80398 7.21947 3.90151 7.20007 4 7.20007H20C20.1989 7.20007 20.3897 7.27909 20.5303 7.41974C20.671 7.5604 20.75 7.75116 20.75 7.95007C20.75 8.14899 20.671 8.33975 20.5303 8.4804C20.3897 8.62106 20.1989 8.70007 20 8.70007Z" fill="currentColor"/>
<path d="M16.44 20.75H7.56C7.24309 20.7717 6.92503 20.7303 6.62427 20.6281C6.3235 20.5259 6.04601 20.3651 5.80788 20.1548C5.56975 19.9446 5.37572 19.6892 5.23704 19.4034C5.09836 19.1177 5.01779 18.8072 5 18.49V8.00005C5 7.80113 5.07902 7.61037 5.21967 7.46972C5.36032 7.32906 5.55109 7.25005 5.75 7.25005C5.94891 7.25005 6.13968 7.32906 6.28033 7.46972C6.42098 7.61037 6.5 7.80113 6.5 8.00005V18.49C6.5 18.9 6.97 19.25 7.5 19.25H16.38C16.94 19.25 17.38 18.9 17.38 18.49V8.00005C17.38 7.78522 17.4653 7.57919 17.6172 7.42729C17.7691 7.27538 17.9752 7.19005 18.19 7.19005C18.4048 7.19005 18.6109 7.27538 18.7628 7.42729C18.9147 7.57919 19 7.78522 19 8.00005V18.49C18.9822 18.8072 18.9016 19.1177 18.763 19.4034C18.6243 19.6892 18.4303 19.9446 18.1921 20.1548C17.954 20.3651 17.6765 20.5259 17.3757 20.6281C17.075 20.7303 16.7569 20.7717 16.44 20.75ZM16.56 7.75005C16.4611 7.75139 16.363 7.73291 16.2714 7.6957C16.1798 7.65848 16.0966 7.60329 16.0267 7.53337C15.9568 7.46346 15.9016 7.38024 15.8644 7.28864C15.8271 7.19704 15.8087 7.09891 15.81 7.00005V5.51005C15.81 5.10005 15.33 4.75005 14.81 4.75005H9.22C8.67 4.75005 8.22 5.10005 8.22 5.51005V7.00005C8.22 7.19896 8.14098 7.38972 8.00033 7.53038C7.85968 7.67103 7.66891 7.75005 7.47 7.75005C7.27109 7.75005 7.08032 7.67103 6.93967 7.53038C6.79902 7.38972 6.72 7.19896 6.72 7.00005V5.51005C6.75872 4.88136 7.04203 4.29281 7.50929 3.87041C7.97655 3.44801 8.5906 3.22533 9.22 3.25005H14.78C15.4145 3.21723 16.0362 3.43627 16.51 3.8595C16.9838 4.28273 17.2713 4.87592 17.31 5.51005V7.00005C17.3113 7.09938 17.2929 7.19798 17.2558 7.29013C17.2187 7.38228 17.1637 7.46615 17.0939 7.53685C17.0241 7.60756 16.941 7.6637 16.8493 7.70201C16.7577 7.74033 16.6593 7.76006 16.56 7.76005V7.75005Z" fill="currentColor"/>
<path d="M10.22 17.0001C10.0219 16.9975 9.83263 16.9177 9.69253 16.7776C9.55244 16.6375 9.47259 16.4482 9.47 16.2501V11.7201C9.47 11.5212 9.54902 11.3304 9.68967 11.1898C9.83032 11.0491 10.0211 10.9701 10.22 10.9701C10.4189 10.9701 10.6097 11.0491 10.7503 11.1898C10.891 11.3304 10.97 11.5212 10.97 11.7201V16.2401C10.9713 16.3394 10.9529 16.438 10.9158 16.5302C10.8787 16.6223 10.8237 16.7062 10.7539 16.7769C10.6841 16.8476 10.601 16.9037 10.5093 16.9421C10.4177 16.9804 10.3193 17.0001 10.22 17.0001Z" fill="currentColor"/>
<path d="M13.78 17.0001C13.5811 17.0001 13.3903 16.9211 13.2497 16.7804C13.109 16.6398 13.03 16.449 13.03 16.2501V11.7201C13.03 11.5212 13.109 11.3304 13.2497 11.1898C13.3903 11.0491 13.5811 10.9701 13.78 10.9701C13.9789 10.9701 14.1697 11.0491 14.3103 11.1898C14.451 11.3304 14.53 11.5212 14.53 11.7201V16.2401C14.53 16.4399 14.4513 16.6317 14.3109 16.774C14.1706 16.9162 13.9798 16.9975 13.78 17.0001Z" fill="currentColor"/>

      </svg>
      Supprimer
    </span>
  </button>
</div>

            </div>
          </div>
        </div>
      </div>

    {/* modal de suppression */}
    {/* <ModalDelete show={showDeleteModal} onClose={()=>setShowDeleteModal(false)}/> */}
    <ModalServiceDetail show={showDetailModal} onClose={()=>setShowDetailModal(false)} item={serviceSelected}/>
    <ConfirmModal showModal={showDeleteModal} buttonText={"Supprimer"} text="Etes vous sûre de vouloir supprimer ce service" variant="danger" action={handleDelete} show={showDeleteModal} onClose={()=>setShowDeleteModal(false)}/>
    </section>
  );
}
