import React, { useEffect, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import TimePicker from '../../components/TimesSelect/TimePicker';
import axios from 'axios';
import Button from '../../components/Button';
import ButtonBack from '../../components/Button/ButtonBack';
import AvalaibilityDay from './AvalaibilityDay';
import { api } from '../../../api';
import Feedback from '../../../admin/components/feedback'
import ConfirmModal from '../../components/Modal/ConfirmModal'

const Disponibilite = () => {
  const {id} = useParams()
  const [idSelected, setIdSelected] = useState(0)
  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [isOpen, setIsOpen] = useState({
      success: false,
      fails: false
  })

  const [schedule, setSchedule] = useState({
    heure_ouv: 7,
    min_ouv: 0,
    heure_ferm: 19,
    min_ferm: 0,
    heure_debut_pause: 0,
    min_debut_pause: 0,
    heure_fin_pause: 0,
    min_fin_pause: 0,
  })
  
  const [openingDays, setOpeningDays] = useState( {})
    // id du jour ouvrable seléctionné
    const [idOpeningDays, setIdOpeningDays] = useState()

  useEffect(() => {
    // A MODIFIER: Api de récupération des jours ouvrable 
    // axios.get(api+`/api/jourOuvrableService/${id}`)
    //   .then((response) =>{ 
    //     console.log(response.data)
    //     console.log("id Service dans avalaibility"+id)
    //     if(response.data.length !== 0)
    //       setOpeningDays(response.data[0] || {
    //         lundi: 0,
    //         mardi: 0,
    //         mercredi: 0,
    //         jeudi: 0,
    //         vendredi: 0,
    //         samedi: 0,
    //         dimanche: 0,
    //       })
    //       setIdSelected(response.data[0].id)
    //   })
    //   .catch(error => console.error('Error fetching data:', error))

      // A MODIFIER: récupérer les heure d'ouvertures
      axios.get(api+`/api/heureOuvrableService/${id}`)
      .then((response) =>{
        if(response.data !== null){
          // console.log('heure ouvrable:', response.data)
          setSchedule(response.data[0])
        }else{
          setSchedule({
            heure_ouv: 7,
            min_ouv: 0,
            heure_ferm: 19,
            min_ferm: 0,
            heure_debut_pause: 0,
            min_debut_pause: 0,
            heure_fin_pause: 0,
            min_fin_pause: 0,
          })
        }
          setIdSelected(response.data[0].id)
          console.log("Heure ouvrable: " +response.data)

      })

      .catch(error =>{ 
        console.error('Error fetching data:', error)
        setSchedule({
          heure_ouv: 7,
          min_ouv: 0,
          heure_ferm: 19,
          min_ferm: 0,
          heure_debut_pause: 0,
          min_debut_pause: 0,
          heure_fin_pause: 0,
          min_fin_pause: 0,
        })
      })

      // setSchedule({
      //   heure_ouv: 7,
      //   min_ouv: 0,
      //   heure_ferm: 19,
      //   min_ferm: 0,
      //   heure_debut_pause: 0,
      //   min_debut_pause: 0,
      //   heure_fin_pause: 0,
      //   min_fin_pause: 0,
      // })
  }, [])

  const handleTimeChange = (type, value) => {
    setSchedule(prevState => ({
      ...prevState,
      [type]: parseInt(value),
    }))
  }

  const handleSaveSchedule = async () => {
    console.log(schedule)
    const formDataToSend = new FormData();
    formDataToSend.append('heure_ouv', schedule.heure_ouv);
    formDataToSend.append('min_ouv', schedule.min_ouv);
    formDataToSend.append('heure_ferm', schedule.heure_ferm);
    formDataToSend.append('min_ferm', schedule.min_ferm);
    formDataToSend.append('heure_debut_pause', schedule.heure_debut_pause);
    formDataToSend.append('min_debut_pause', schedule.min_debut_pause);
    formDataToSend.append('heure_fin_pause', schedule.heure_fin_pause);
    formDataToSend.append('min_fin_pause', schedule.min_fin_pause);
    formDataToSend.append('service_id', id);

    console.log('Saving Schedule:', id)
    console.log('Heure ouvrable:', schedule)

    // axios.post(api + `/api/ajoutHeureOuvrable`, formDataToSend)
    //   .then(response => console.log('Enregistré avec succès:', response))
    //   .catch(error => console.error('Erreur lors de l\'enregistrement:', error))

    const request = idSelected ? 
      axios.post(api + `/api/modHeureOuvrable/${id}`, formDataToSend) :
      axios.post(api + `/api/ajoutHeureOuvrable/${id}`, formDataToSend) 

    // request
      .then(() =>{
        setShowConfirmModal(false)
        setIsOpen({ ...isOpen, success: true })})
      .catch(() =>{ 
        setShowConfirmModal(false)
        setIsOpen({ ...isOpen, fails: true })})

      
    // try {
    //   const response = await axios.post(api + `/api/ajoutHeureOuvrable/${id}`, formDataToSend);
    //   console.log('Heure ouvrable enregistré:', response.data);
    // } catch (error) {
    //   console.error('Error saving schedule:', error);
    // }
  }


  return (
    <div className='border-line min-h-screen mx-6'>
      <div className="flex justify-between items-center mb-6">
        <ButtonBack path={'/pro/services'} text={"Disponibilité d'un service"} />
        {/* <Button onClick={handleSaveSchedule}>Enregistrer</Button> */}
      </div>
      <div className="grid grid-cols-1 md:grid-cols-4 gap-4">

        {/* Section gauche: jour disponible */}
        <div className=" col-span-1 md:col-span-2">
          <AvalaibilityDay openingDays={openingDays} id={id}/>
        </div>

        {/* Section droite: plage horaire */}
        <div className="col-span-1 md:col-span-2">
          <div className="border-line mb-4">
            <div className="flex justify-between items-center mb-6">
              <p className='title1'>Plage horaire d'ouverture</p>
              <Button onClick={()=>setShowConfirmModal(true)}>Enregistrer</Button>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
              <div className="flex-col space-y-1">
                <p className='font-medium text-sm'>Heure d'ouverture</p>
                <TimePicker
                  hourName="heure_ouv"
                  minuteName="min_ouv"
                  selectedHour={schedule.heure_ouv}
                  selectedMinute={schedule.min_ouv}
                  setSelectedHour={(value) => handleTimeChange('heure_ouv', value)}
                  setSelectedMinute={(value) => handleTimeChange('min_ouv', value)}
                />
              </div>
              <div className="flex-col space-y-1">
                <p className='font-medium text-sm'>Heure de fermeture</p>
                <TimePicker
                  hourName="heure_ferm"
                  minuteName="min_ferm"
                  selectedHour={schedule.heure_ferm}
                  selectedMinute={schedule.min_ferm}
                  setSelectedHour={(value) => handleTimeChange('heure_ferm', value)}
                  setSelectedMinute={(value) => handleTimeChange('min_ferm', value)}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div className="flex-col space-y-1">
                <p className='font-medium text-sm'>Début pause</p>
                <TimePicker
                  hourName="heure_debut_pause"
                  minuteName="min_debut_pause"
                  selectedHour={schedule.heure_debut_pause}
                  selectedMinute={schedule.min_debut_pause}
                  setSelectedHour={(value) => handleTimeChange('heure_debut_pause', value)}
                  setSelectedMinute={(value) => handleTimeChange('min_debut_pause', value)}
                />
              </div>
              <div className="flex-col space-y-1">
                <p className='font-medium text-sm'>Fin pause</p>
                <TimePicker
                  hourName="heure_fin_pause"
                  minuteName="min_fin_pause"
                  selectedHour={schedule.heure_fin_pause}
                  selectedMinute={schedule.min_fin_pause}
                  setSelectedHour={(value) => handleTimeChange('heure_fin_pause', value)}
                  setSelectedMinute={(value) => handleTimeChange('min_fin_pause', value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

 {/* Modal de confirmation */}
 <ConfirmModal 
                show={showConfirmModal} 
                onClose={() => setShowConfirmModal(false)}
                title="Confirmation"
                text="Êtes-vous sûr de vouloir enregistrer cette offre modifiée ?"
                buttonText="Enregistrer"
                action={handleSaveSchedule}
            />

            {/* Feedback success or error */}
            <Feedback isOpen={isOpen.success} onClose={() => setIsOpen({ ...isOpen, success: false })} message="Modification réussie !" />
            <Feedback isOpen={isOpen.fails} onClose={() => setIsOpen({ ...isOpen, fails: false })} type='error' message='Une erreur est survenue !' />
    </div>
  );
};

export default Disponibilite;
