import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router-dom'
import CurrentAbonnement from './partials/CurrentAbonnement'
import DemandeAbonnement from './partials/DemandeAbonnement'
import History from './partials/History'

const Abonnement = () => {
  const { setTitle } = useOutletContext()
  const [statusFilter, setStatusFilter] = useState('Mon abonnement')
  const [menu, setMenu] = useState(0)
  
  useEffect(() => {

    setTitle('Abonnement')

}, [setTitle])

  return (
    <div className='p-6 mx-4 border-line min-h-[600px]'>
    <div className="grid grid-cols-9">
      <div className="col-span-2 min-h-[600px]">
        <ul className='text-sm mr-4'>
          {['Demande','Mon abonnement',  'Historique'].map((filter, index) => (
            <li
              key={filter}
              onClick={() => {setStatusFilter(filter)
                setMenu(index)
              }}
              className={`cursor-pointer p-2 font-medium ${statusFilter === filter ? 'bg-orange-400 text-white rounded-md' : ''}`}
            >
              {filter}
            </li>
          ))}
        </ul>
      </div>
      <div className="col-span-7 border-l border-l-1 pl-6">
      {menu === 0 && <DemandeAbonnement/>}
        {menu === 1 && <CurrentAbonnement/>}
        {menu === 2 && <History/>}
        {/* {menu === 3 && <CurrentAbonnement/>} */}
        
        </div></div></div>
  )
}

export default Abonnement