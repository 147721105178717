import React, { useState } from 'react';

const generateSlots = (openingHour, openingMinute, breakStartHour, breakStartMinute, breakEndHour, breakEndMinute, closingHour, closingMinute, interval) => {
  const slots = []
  let currentHour = openingHour
  let currentMinute = openingMinute

  const addSlot = (hour, minute) => {
    slots.push({
      time: `${String(hour).padStart(2, '0')}:${String(minute).padStart(2, '0')}`,
    });
  };

  while (currentHour < closingHour || (currentHour === closingHour && currentMinute < closingMinute)) {
    if (
      (currentHour > breakStartHour || (currentHour === breakStartHour && currentMinute >= breakStartMinute)) &&
      (currentHour < breakEndHour || (currentHour === breakEndHour && currentMinute < breakEndMinute))
    ) {
      currentHour = breakEndHour;
      currentMinute = breakEndMinute;
    } else {
      addSlot(currentHour, currentMinute)
      currentMinute += interval;
      if (currentMinute >= 60) {
        currentMinute -= 60;
        currentHour += 1;
      }
    }
  }

  return slots;
};

const SlotSelection = ({
  openingHour,
  openingMinute,
  breakStartHour,
  breakStartMinute,
  breakEndHour,
  breakEndMinute,
  closingHour,
  closingMinute,
  interval,
  setTimeSelected,
  setHourSelected=()=>{},
  setMinuteSelected=()=>{},
  textSize = 'text-sm'
}) => {
  const [selectedSlot, setSelectedSlot] = useState(null);

  const slots = generateSlots(
    openingHour,
    openingMinute,
    breakStartHour,
    breakStartMinute,
    breakEndHour,
    breakEndMinute,
    closingHour,
    closingMinute,
    interval
  );

  const handleSlotClick = (slot) => {
    setTimeSelected(slot.time)
    console.log('Temps sélectionné:', slot.time)
    
    // Extraction de l'heure et de la minute
    const [hours, minutes] = slot.time.split(':').map(Number)
    const formattedTime = `${String(Math.floor(hours / 60)).padStart(2, '0')}:${String(hours % 60).padStart(2, '0')}`

    console.log('Heure choisie:', hours)
    setHourSelected(hours)
    console.log('Minute choisie:',typeof minutes)
    setMinuteSelected(minutes)
    console.log('Temps formatté:', formattedTime)

    setSelectedSlot(formattedTime)
  }

  return (
    <div className="grid grid-cols-4 gap-4 py-6">
      {slots.map((slot, index) => (
        <button
          key={index}
          onClick={() => handleSlotClick(slot)}
          className={textSize+` p-2 border rounded text-black-900 hover:bg-black-900 hover:text-white ease-in duration-100 ${
            selectedSlot === slot.time ? 'bg-black-900 text-white ' : 'text-gray-700'
          }`}
        >
          {slot.time}
        </button>
      ))}
    </div>
  );
};

export default SlotSelection;
