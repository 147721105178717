import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Card from '../../../../../components/Card'
import GoBack from '../../../../../components/btn/goBack'
import Input from '../../../../../components/form/input'
import Feedback from '../../../../../components/feedback'
import { Button } from '@mui/material'
import Load from '../../../../../components/load'
import axios from 'axios'
import { api } from '../../../../../../api'

export default function AddOffre() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [options, setOptions] = useState({
        disabled: true,
        loading: false,

    })
    const [data, setData] = useState({
        nom: '',
        description: '',
        prix: 1,
        duree: 1,
        photo: null,


    })

    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })
    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }
    const handleChange = (e, key) => {
        setData({ ...data, [key]: e.target.value })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }
    function handleSubmit(e) {
        e.preventDefault()
        setOptions({...options, loading: true })
        const form=new FormData()
        form.append('nom', data.nom)
        form.append('description', data.description)
        form.append('prix', data.prix)
        form.append('duree', data.duree)
        form.append('photo', data.photo)
        form.append('service_id',id)
        axios.post(api+"/api/route",form)
        .then(() => {
            setOptions({...options, loading: false })
            setIsOpen({...isOpen, success: true })
            navigate(`/admin/offres/${id}`)
        })
        .catch((error) => {
            console.error(error)
            setOptions({...options, loading: false })
            setIsOpen({...isOpen, fails: true })
        })
    }
    useEffect(() => {
        if (data.nom && data.description && data.prix && data.duree && data.photo) {
            setOptions({...options,disabled:false})
        }else {            setOptions({...options,disabled:true})
    }
    }, [data])
    return (

        <div className='w-full'>
            <Card>
                <form onSubmit={(e) => { handleSubmit(e) }} className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full gap-x-2 flex flex-row justify-start items-center ">
                        <GoBack />
                        <span className='font-bold text-slate-500'>
                            Nouveau administrateur
                        </span>
                    </div>
                    <div className="grid grid-cols-3 gap-4 justify-start items-start w-full ">
                        <Input label='Nom de compte' value={data.nom} onChange={(e) => { handleChange(e, "nom") }} />
                        <Input label='Description' value={data.description} onChange={(e) => { handleChange(e, "description") }} />
                        <Input label='Prix' value={data.prix} type='number' onChange={(e) => { handleChange(e, "prix") }} />
                        <Input label='Durée' value={data.duree} type='number' onChange={(e) => { handleChange(e, "duree") }} />
                        <Input label='Photo' type='file' onChange={(e) => { setData({ ...data, photo: e.target.files[0] }) }} />

                    </div>
                    <div className="flex flex-row justify-end items-center gap-x-4 w-full">
                        <Button type="button" variant='outlined' onClick={() => { navigate(-1) }}>Annuler</Button>
                        <Button type="submit" variant='contained' disabled={options.disabled} onClick={() => { handleOpen("add") }}>
                            <Load title={"Ajouter"} check={true} loading={options.loading}/>
                        </Button>
                    </div>
                </form>
            </Card>

            <Feedback type='success' message='Ajoutée avec succès.' isOpen={isOpen.success} onClose={() => {
                handleClose("success")
            }} />
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails}
                onClose={() => {
                    handleClose("fails")
                }}
            />

        </div>
    )
}
