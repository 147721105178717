import React, { useEffect, useState } from 'react'
import Input from '../../../components/form/input'
import GoBack from '../../../components/btn/goBack'
import { Button } from '@mui/material'
import Load from '../../../components/load'
import Feedback from '../../../components/feedback'
import { useNavigate } from 'react-router-dom'
import Card from '../../../components/Card'
import TextAria from '../../../components/form/textAria/index.'
import Modals from '../../../components/modal'
import axios from 'axios'
import { api } from '../../../../api'

export default function NewCategorie() {
    const navigate = useNavigate()
    const [data, setData] = useState({
        nom: '',
        logo: null
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
        add: false,
        Verification: false
    })
    const [options, setOptions] = useState({
        disabled: true,
        loading: false,

    })

    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }
    function change(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append('nom', data.nom)
            form.append('logo', data.logo)
            axios.post(api + '/api/ajoutSecteur', form)
                .then(() => {
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, success: true })
                })
                .catch((error) => {
                    console.error(error)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })
                })
        } catch (e) {
            console.error(e)
            setOptions({ ...options, loading: false })
            setIsOpen({ ...isOpen, fails: true })
        }
    }
    const handleInputChange = (e, type) => {
        setData({ ...data, [type]: e.target.value })
    }

    useEffect(() => {
        if (
            data.nom !== '' &&
            data.description !== ''

        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <div className='w-full'>
            <Card>
                <form onSubmit={(e) => { change(e) }} className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full gap-x-2 flex flex-row justify-start items-center ">
                        <GoBack />
                        <span className='font-bold text-slate-500'>
                            Nouveau secteur
                        </span>
                    </div>
                    <div className="grid grid-cols-1 gap-4 justify-start items-start w-full ">
                        <Input label='Nom du secteur' type='text' value={data.nom} onChange={(e) => handleInputChange(e, 'nom')} />
                        <Input label='Logo' type="file" onChange={(e) => { setData({ ...data, logo: e.target.files[0] }) }} />
                        <div className="flex flex-row justify-end items-center gap-x-4 w-full">
                            <Button type="button" variant='outlined' onClick={() => { navigate(-1) }}>Annuler</Button>
                            <Button type="submit" variant='contained' disabled={options.disabled} >
                                <Load title={"Ajouter"} check={true} loading={options.loading} />
                            </Button>
                        </div>
                    </div>
                </form>
            </Card>
            <Modals isOpen={isOpen.add} onClose={() => { handleClose("add") }}>
                <div className='w-full flex flex-col gap-y-2'>
                    <h4>Nouveau secteur</h4>
                    <p>
                        Voulez-vous ajouter ce secteur?
                    </p>
                    <div className="flex flex-row justify-between items-center gap-x-2">
                        <Button fullWidth variant='outlined' onClick={() => { handleClose("add") }}>
                            Annuler
                        </Button>
                        <Button fullWidth variant='contained' onClick={() => {
                            change();
                        }}>
                            <Load loading={options.loading} title='Oui,ajouter' />
                        </Button>

                    </div>
                </div>
            </Modals>

            <Feedback type='success' message='Modifiée avec succès.' isOpen={isOpen.success} />
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails} />
        </div>
    )
}
