import React from 'react';
import { saveAs } from 'file-saver';
import { utils, writeFileXLSX } from 'xlsx';
const exportToExcel = (data = [], fileName) => {
  const worksheet = utils.json_to_sheet(data);
  const workbook = utils.book_new();

  utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  writeFileXLSX(workbook, `${fileName + '-' + new Date()}.xlsx`);
};
export { exportToExcel };