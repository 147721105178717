import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import Input from '../../../../components/form/input'
import { Button } from '@mui/material'
import GoBack from '../../../../components/btn/goBack'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../../api'
import { Code } from '../../../../../script/generator'
import Load from '../../../../components/load'
import Feedback from '../../../../components/feedback'

export default function ChangeEmail() {
    const navigate = useNavigate()
    const { all, setAll } = useOutletContext()
    const [options, setOptions] = useState({
        disabled: true,
        loading: false,
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
    })
    const [data, setData] = useState({
        email: ''
    })

    function handleSubmit(e) {
        e.preventDefault()
        const code = Code()
        console.log(code)
        setAll({ ...all, codeGenerated: code })
        // navigate('/admin/verificationEmail/' + data.email)
        setOptions({ ...options, loading: true })

        try {
            const form = new FormData()
            form.append('email', data.email)
            form.append('code', code)

            axios.post(api + '/api/route', form)
                .then(() => {
                    setOptions({ ...options, loading: false })
                    // setIsOpen({ ...isOpen, success: true })

                    navigate('/admin/verificationEmail/' + data.email)

                })
                .catch((error) => {
                    console.error(error)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })
                })
        } catch (error) {
            console.error(error)
        }
    }

    useEffect(() => {

        if (
            data.email !== ''
        ) {
            setOptions({ ...options, disabled: false })

        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])

    return (
        <div className="max-md:w-full max-lg:w-8/12 lg:w-5/12" >
            <Card>
                <form onSubmit={(e) => { handleSubmit(e) }} className='flex flex-col w-full gap-y-3'>
                    <div className="flex flex-row justify-start items-center gap-x-3">
                        <GoBack />
                        <h4 className='w-full'>Modification d'email</h4>
                    </div>
                    <Input label='Nouveau e-mail' required={true} type='email' value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
                    <Button variant="contained" type="submit" disabled={options.disabled}>
                        <Load title='Changer' loading={options.loading} />
                    </Button>
                </form>
            </Card>
            {/* <Feedback type='success' message='E-'/> */}
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails} onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </div>
    )
}
