import { Button } from '@mui/material'
import React from 'react'
import { IoHome } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

export default function Path(
    {
        currentName="current",
        path = [
            
        ]
    }
) {
    const navigate = useNavigate()
    
    return (
        <div className='flex flex-row justify-end items-center gap-x-1'>
            <div className='flex flex-row justify-end items-center gap-x-1'>
                <span>/</span>
                <Button variant="text" onClick={() => { navigate('/admin') }}>
                    <IoHome />
                </Button>
            </div>
            {
                path.map((item, index) => (
                    <div key={index} className='flex flex-row justify-end items-center gap-x-1'>
                        <span>/</span>
                        <Button variant="text" onClick={() => { navigate(item.path) }}>
                            {item?.name}
                        </Button>
                    </div>
                ))
            }
             <div className='flex flex-row justify-end items-center gap-x-1'>
                <span>/</span>
                <Button variant="text" >
                    {currentName}
                </Button>
            </div>
        </div>
    )
}
