import React, { useEffect, useState } from 'react'
import axios from 'axios'

import {
    AreaChart,
    Area,
    ResponsiveContainer,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
  } from 'recharts'
import { api } from '../../api'
  
  // const productSales = [
  //   {
  //     name: 'Jan',
  //     rdv: 4000,
  //     client: 2400,
  //   },
  //   {
  //     name: 'Feb',
  //     rdv: 3000,
  //     client: 2210,
  //   },
  //   {
  //     name: 'Mar',
  //     rdv: 2000,
  //     client: 2290,
  //   },
  //   {
  //     name: 'Apr',
  //     rdv: 2780,
  //     client: 2000,
  //   },
  //   {
  //     name: 'May',
  //     rdv: 1890,
  //     client: 2181,
  //   },
  //   {
  //     name: 'Jun',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Juil',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Août',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Sept',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Oct',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Nov',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  //   {
  //     name: 'Déc',
  //     rdv: 2390,
  //     client: 2500,
  //   },
  // ]
  

  
  const AreaChartComponent = ({rdvData, clientData}) => {

    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Juil", "Août", "Sept", "Oct", "Nov", "Déc"]

    // Merge the rdv and client data into the productSales array
    const productSales = months.map((month, index) => {
      const rdvMatch = rdvData.find(rdv => rdv.month === index + 1);
      const clientMatch = clientData.find(client => client.month === index + 1);
      
      return {
        name: month,
        rdv: rdvMatch ? rdvMatch.total_rdv : 0,  // Use 0 if no rdv data for the month
        client: clientMatch ? clientMatch.total_client : 0  // Use 0 if no client data for the month
      }
    })

    // const [resumeRDV, setResumeRDV] = useState([])
    // const [resumeClient, setResumeClient] = useState([])
  
    // useEffect(() => {
    //   axios.get(api+"/api/getRdvCountPerMonth/"+localStorage.getItem("soro-id"))
    //  .then(response => {
    //   setResumeRDV(response.data)
    //   console.log("Nombre rdv chart",response.data)
    //   })
    //   .catch((err)=>{
    //     console.error(err)
    //   })
  
    //   axios.get(api+"/api/getUniqueClientsPerMonth/"+localStorage.getItem("soro-id"))
    //   .then(response => {
    //     setResumeClient(response.data)
    //     console.log("Nombre client chart",response.data)
    //    })
    //    .catch((err)=>{
    //      console.error(err)
    //    })
      
  
    // })

    return (
      <ResponsiveContainer width="100%" height="100%">
        <AreaChart
          width={500}
          height={400}
          data={productSales}
          margin={{ right: 30 }}
        >
          <YAxis />
          <XAxis dataKey="name" />
          <CartesianGrid strokeDasharray="5 5" />
  
          <Tooltip content={<CustomTooltip />} />
          <Legend />
  
          <Area
            type="monotone"
            dataKey="rdv"
            stroke="#2563eb"
            fill="#3b82f6"
            stackId="1"
          />
  
          <Area
            type="monotone"
            dataKey="client"
            stroke="#7c3aed"
            fill="#8b5cf6"
            stackId="1"
          />
        </AreaChart>
      </ResponsiveContainer>
    )
  }
  
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const rdvValue = payload[0]?.value ?? 0; 
      const clientValue = payload[1]?.value ?? 0;
  
      return (
        <div className="h-32 relative px-2 bg-slate-900 flex flex-col gap-4 rounded-md">
          <p className="text-medium text-lg">{label}</p>
          <p className="text-sm text-blue-400">
            RDV :
            <span className="ml-2">{rdvValue}</span>
          </p>
          <p className="text-sm text-indigo-400">
            Client :
            <span className="ml-2">{clientValue}</span>
          </p>
        </div>
      )
    }
  
    return null
  }
  
  
  export default AreaChartComponent