import React, { useEffect, useState } from 'react'
import axios from 'axios'
import arrowleft from "../../assets/icons/arrowleft.png"
import VerifyCode from './VerifyCode'
import { Link } from 'react-router-dom'
import { FaUser } from 'react-icons/fa'
import { BiCamera } from 'react-icons/bi'
import { api } from '../../api'
import {district, region} from '../../website/Datas/RegionDistrict'
import Feedback from '../../admin/components/feedback'

const steps = [
  { title: "Étape 1", content: "Sélection du rôle" },
  { title: "Étape 2", content: "Création de compte" },
  { title: "Étape 3", content: "" },
  { title: "Étape 4", content: "Informations supplémentaires" }
];

const Right = () => {
  const [emailValidated, isEmailValidated] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [generatedCode, setGeneratedCode] = useState()
  const [isChecked, setIsChecked] = useState(false)
  const [showModal, setshowModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [formData, setFormData] = useState({
    role:'client',
    email: '',
    password: '',
    confirmPassword: '',
    region: '',
    district: '',
    adresse: '',
    contact: '',
    nom:'',
    photo:'',
    description:'',
    type:''
  })
  useEffect(()=>{
    // console.log(generatedCode,'ghjkl')
  },[generatedCode])

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageChange = (event) => {
    setSelectedImage(event.target.files[0]);
  }

  const handleNext = () => {

    if(currentStep === 1 && formData.email !== '' && formData.password !== '' && formData.confirmPassword != ''){

      // if (!generatedCode) {

      axios.post(api + '/api/verifyEmail',{email: formData.email})
      .then((response)=>{

        // false = mi-existe
        if (response.data === false)
        {
          // retour de Viviane
          console.log('Email non valide')
          console.log(response.data)
        }
        
        else{
          const newCode = Math.ceil(Math.random() * 9001) + 999;
            setGeneratedCode(newCode)
            const form = new FormData()
                form.append("email", formData.email)
                form.append("code", newCode)     
              axios.post(api + '/api/sendEmail', form)
                .then((res) => {
                  console.log('Code envoyé avec succès')
                })
                .catch((err) => {
                    console.log("Une erreur est survenue.")
                })
          setCurrentStep(2)
        }

        // console.log(res.data.message)
        // if(res.data.message)setCurrentStep(currentStep + 1)
      })

        
    }
    if(currentStep === 2 && emailValidated === true ){

      // axios.post('/api/sendMail', formData)
      // .then((res)=>{
       setCurrentStep(currentStep + 1)   
      //   console.log(res.data.message)
      //   if(res.data.message)setCurrentStep(currentStep + 1)
      // })
    

      
      setCurrentStep(currentStep + 1)

      
    }
    if(currentStep === 3 && formData.district.trim !== '' && formData.contact !== '' && formData.region !== '' && formData.adresse !== '' )setCurrentStep(currentStep + 1)
    if (currentStep === 0) {setCurrentStep(currentStep + 1)}
  }

  const handlePrev = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  }

    const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "photo" && files && files.length > 0) {
        setFormData({
            ...formData,
            [name]: files[0], // Stocker le fichier directement
        });
    } else {
        setFormData({
            ...formData,
            [name]: value,
        });
    }
};

  const handleSubmit = async (e) => {
    e.preventDefault()
    console.log(formData)
    const formDataToSend = new FormData();
    formDataToSend.append('role', formData.role);
    formDataToSend.append('email', formData.email);
    formDataToSend.append('password', formData.password);
    formDataToSend.append('region', formData.region);
    formDataToSend.append('district', formData.district);
    formDataToSend.append('adresse', formData.adresse);
    formDataToSend.append('contact', formData.contact);
    formDataToSend.append('nom', formData.nom);
    formDataToSend.append('description', formData.description);
    formDataToSend.append('type', formData.type);
    // formDataToSend.append('photo', formData.photo);

    if (formData.photo) {
      formDataToSend.append('photo', formData.photo)
    }

    axios.post(api + '/api/inscription', formDataToSend)
    .then(response => {
      // Traiter la réponse si tout se passe bien
      setshowModal(true)
      setTimeout(
        () => {
          window.location.href="/login"
        }, 2000)
    })
    .catch(error => {
      setShowErrorModal(true)
      // if (error.response) {
      //   console.log('Erreur:', error.response.data);
      // } else if (error.request) {
      //   console.log('Aucune réponse reçue:', error.request);
      // } else {
      //   console.log('Erreur lors de la requête:', error.message);
      // }
    })
    
  }

  const sendMail = async (e) => {
    e.preventDefault()
    console.log(formData)
    try {
      await axios.post('/api/sendMail', formData)
      .then((res)=>{
        

      })
    } catch (error) {
    }
  }

  return (
    <div className='w-full lg:w-3/5'>
      <div className="flex items-start">
          <button className={(currentStep > 0)?" p-2 border bg-white ml-5 sm:ml-10 mt-10 rounded-full ":" invisible  p-2 border bg-white m-10 rounded-full" +" p-2 border bg-white m-10 rounded-full"} onClick={handlePrev}>
            <img src={arrowleft} alt="arrow" className='w-5 h-5'/>
          </button>
      </div>

      <div className="max-w-md w-full pt-4 md:pt-2 px-6 mx-auto ">
        <h1 className="text-3xl font-semibold mb-6 text-black text-center">{steps[currentStep].title}</h1>
        <p className={(currentStep === 2)? "mb-3 hidden " :"mb-6  block " +"text-sm font-semibold text-gray-500 text-center" } >{steps[currentStep].content}</p>

        {currentStep === 0 && (
          <div className="w-full">
            {/* Étape 1 */}
            <ul className="grid w-full gap-6">
              {/* Étape 1 : Sélection du rôle de l'utilisateur */}
<div className="w-full">
  <ul className="grid w-full gap-6">
    <li>
      <input type="radio" id="role-client" name="role" value="client" className="hidden peer" defaultChecked required onChange={handleChange} />
      <label htmlFor="role-client" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-black-900 peer-checked:border-2 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">                           
        <div className="block text-start">
          <div className="w-full text-lg font-semibold">Client</div>
          <div className="w-full">Pour les utilisateurs souhaitant réserver des services</div>
        </div>
        <svg className="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
      </label>
    </li>
    <li>
      <input type="radio" id="role-provider" name="role" value="prestataire" className="hidden peer"  onChange={handleChange} />
      <label htmlFor="role-provider" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-700 peer-checked:border-black-900 peer-checked:border-2 hover:text-gray-600 hover:bg-gray-100 dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700">
        <div className="block text-start">
          <div className="w-full text-lg font-semibold">Prestataire</div>
          <div className="w-full">Pour les utilisateurs offrant des services</div>
        </div>
        <svg className="w-5 h-5 ms-3 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
          <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
        </svg>
      </label>
    </li>
  </ul>
</div>

            </ul>
          </div>
        )}

        {currentStep === 1 && (

          <form onSubmit={handleSubmit} className="space-y-4">
              {/* <div className="w-full mb-2 lg:mb-0">
              <button
                type="button"
                className="font-semibold w-full flex justify-center items-center gap-2 bg-white text-sm text-gray-600 p-2 rounded-md hover:bg-gray-50 border border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-200 transition-colors duration-300"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                  className="w-4"
                  id="google"
                >
                  <path fill="#fbbb00" d="M113.47 309.408 95.648 375.94l-65.139 1.378C11.042 341.211 0 299.9 0 256c0-42.451 10.324-82.483 28.624-117.732h.014L86.63 148.9l25.404 57.644c-5.317 15.501-8.215 32.141-8.215 49.456.002 18.792 3.406 36.797 9.651 53.408z"></path>
                  <path fill="#518ef8" d="M507.527 208.176C510.467 223.662 512 239.655 512 256c0 18.328-1.927 36.206-5.598 53.451-12.462 58.683-45.025 109.925-90.134 146.187l-.014-.014-73.044-3.727-10.338-64.535c29.932-17.554 53.324-45.025 65.646-77.911h-136.89V208.176h245.899z"></path>
                  <path fill="#28b446" d="m416.253 455.624.014.014C372.396 490.901 316.666 512 256 512c-97.491 0-182.252-54.491-225.491-134.681l82.961-67.91c21.619 57.698 77.278 98.771 142.53 98.771 28.047 0 54.323-7.582 76.87-20.818l83.383 68.262z"></path>
                  <path fill="#f14336" d="m419.404 58.936-82.933 67.896C313.136 112.246 285.552 103.82 256 103.82c-66.729 0-123.429 42.957-143.965 102.724l-83.397-68.276h-.014C71.23 56.123 157.06 0 256 0c62.115 0 119.068 22.126 163.404 58.936z"></path>
                </svg>
                S'inscrire avec Google
              </button>
            </div> */}
          {/* Lignes de séparation avec le texte "ou" */}
          {/* <div className="mt-4 text-sm text-gray-600 text-center flex items-center">
            <hr className="flex-1 border-gray-300" />
            <span className="px-2">ou</span>
            <hr className="flex-1 border-gray-300" />
          </div> */}
            <div className="relative mb-6">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700 text-start">Email</label>
              <div className="relative mt-1">
                <input
                  type="text"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className=" border border-[#D0D5DD] text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Entrez votre email"
                  required
                />
              </div>
            </div>
            <div className="relative mb-6">
              <label htmlFor="password" className="block text-sm font-medium text-gray-700 text-start">Mot de passe</label>
              <div className="relative mt-1">
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  className=" border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Entrez votre mot de passe"
                  required
                />
              </div>
            </div>
            <div className="relative mb-6">
              <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700 text-start">Confirmez le mot de passe</label>
              <div className="relative mt-1">
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Confirmez votre mot de passe"
                  required
                />
              </div>
            </div>
            <div class="flex items-center mt-4 mb-4">
        <input onChange={(e)=>setIsChecked(e.target.value)} name="term" id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800" required=""/>
        <label htmlFor="terms" class="text-gray-500 dark:text-gray-300 ms-2 text-sm">J'accepte les <a class="font-medium text-primary hover:underline dark:text-blue-500" href="#">Termes et Conditions</a></label>
    </div>
          </form>
        )}

        {currentStep === 2 && (
          <VerifyCode isEmailValidated = {isEmailValidated} email={formData.email} codeGenerated1={generatedCode}/>
        )}

{currentStep === 3 && (
  <form onSubmit={handleSubmit} className="space-y-4">
    {/* récupérer image */}
    <div className="flex items-center space-x-6 justify-center">
      <div className="shrink-0 relative">
        {selectedImage ? (
          <>
            <img 
              id='preview_img'
              className="h-20 w-20 object-cover rounded-full"
              src={URL.createObjectURL(selectedImage)} 
              alt="Selected profile photo"
            />
            
          </>
        ) : (
          <div className="h-20 w-20 bg-gray-200 rounded-full flex items-center justify-center">
            <FaUser className="h-12 w-12 text-gray-400" />
          </div>
        )}
      </div>
      <label  htmlFor='photo' className='absolute mt-16 ml-[90px]'>
      <BiCamera className=" h-8 w-8 p-1 text-gray-900 cursor-pointer bg-white border rounded-full" onClick={() => setSelectedImage(null)} />
       
        <input 
          id='photo'
          type="file" 
          name="photo"
          accept="image/*" 
          onChange={(e)=>{handleChange(e)
            setSelectedImage(e.target.files[0])
          }}
          className="hidden w-full text-sm text-slate-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-violet-50 file:text-violet-700 hover:file:bg-violet-100"
        />
      </label>
    </div>

    <div className="relative mb-6">
                <label htmlFor="region" className="block text-sm font-medium text-gray-700 text-start">Région</label>
                <div className="relative mt-1">
                    <select
                        id="region"
                        name="region"
                        value={formData.region}
                        onChange={handleChange}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                    >
                        <option value="">Choisissez une région</option>
                        {region.map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="relative mb-6">
                <label htmlFor="district" className="block text-sm font-medium text-gray-700 text-start">District</label>
                <div className="relative mt-1">
                    <select
                        id="district"
                        name="district"
                        value={formData.district}
                        onChange={handleChange}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                    >
                        <option value="">Choisissez un district</option>
                        {formData.region && district[formData.region].map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>
    <div className="relative mb-6">
      <label htmlFor="type" className="block text-sm font-medium text-gray-700 text-start">Type</label>
      <div className="relative mt-1">
        <select
          id="type"
          name="type"
          value={formData.type}
          onChange={handleChange}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          required
        >
          <option value="">Choisissez un type</option>
          {/* Ajoutez ici vos options de type */}
          <option value="individuel">individuel</option>
          <option value="organisation">Organisation</option>
          {/* <option value="examen">Examen</option> */}
          {/* Ajoutez plus d'options selon vos besoins */}
        </select>
      </div>
    </div>
    <div className="relative mb-6">
      <label htmlFor="description" className="block text-sm font-medium text-gray-700 text-start">Description</label>
      <div className="relative mt-1">
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Description du rendez-vous"
          required
        />
      </div>
    </div>
    <div className="relative mb-6">
      <label htmlFor="nom" className="block text-sm font-medium text-gray-700 text-start">Nom</label>
      <div className="relative mt-1">
        <input
          type="text"
          id="nom"
          name="nom"
          value={formData.nom}
          onChange={handleChange}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Nom du patient"
          required
        />
      </div>
    </div>
    <div className="relative mb-6">
      <label htmlFor="address" className="block text-sm font-medium text-gray-700 text-start">Adresse</label>
      <div className="relative mt-1">
        <input
          type="text"
          id="adresse"
          name="adresse"
          value={formData.adresse}
          onChange={handleChange}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Votre adresse"
          required
        />
      </div>
    </div>
    <div className="relative mb-6">
      <label htmlFor="contact" className="block text-sm font-medium text-gray-700 text-start">Numéro de contact</label>
      <div className="relative mt-1">
        <input
          type="text"
          id="contact"
          name="contact"
          value={formData.contact}
          onChange={handleChange}
          className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          placeholder="Votre numéro de contact"
          required
        />
      </div>
    </div>
  </form>
)}


        <div className="mt-4 flex justify-between">
        {currentStep === 0 && (
            <button type="button" onClick={handleNext} className="bg-black-900 text-white p-3 rounded-md w-full mt-5 font-semibold">
              Commencer
            </button>
          )}
          { currentStep !== 0 && currentStep < steps.length - 1 && (
            <button type="button" onClick={handleNext} className="bg-black-900 text-white p-3 rounded-md w-full mt-5 font-semibold">
              Suivant
            </button>
          )}
          {currentStep === steps.length - 1 && (
            <button type="submit" onClick={handleSubmit} className="bg-black-900 text-white p-3 rounded-md w-full mt-5 font-semibold">
              S'inscrire maintenant
            </button>
          )}
          {/* {currentStep === 1 && (
            <button type="submit" onClick={sendMail} className="bg-black-900 text-white p-3 rounded-md w-full mt-5 font-semibold">
              Suivant
            </button>
          )} */}

        </div>
        {currentStep < steps.length - 1 && (
           <div className="mt-4 text-sm text-gray-600 text-center">
           <p className='text-gray-500 font-semibold'>Déjà un compte? <Link to="/login" className="text-primary hover:underline">Se connecter</Link></p>
         </div>
          )}
         
      </div>
      <Feedback isOpen={showModal} onClose={()=>setshowModal(false)} message='Inscription réussie !'/>
      <Feedback isOpen={showErrorModal} onClose={()=>setShowErrorModal(false)} type='error' message='Une erreur est survenue !'/>
    </div>
  );
}

export default Right;
