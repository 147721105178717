import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import GoBack from '../../../components/btn/goBack'
import { useNavigate, useOutletContext, useParams } from 'react-router-dom'
import { IoCamera, IoPersonCircle } from 'react-icons/io5'
import Input from '../../../components/form/input'
import { Button } from '@mui/material'
import Load from '../../../components/load'
import Modals from '../../../components/modal'
// import Notification from '../../../components/feedback'
import Feedback from '../../../components/feedback'
import axios from 'axios'
import { api } from '../../../../api'

export default function UpdateClient() {
    const { id } = useParams()
    const navigate = useNavigate()
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    })
    const [options, setOptions] = useState({
        disabled: false,
        loading: false
    })

    const [data, setData] = useState({
        nom: '',
        region: '',
        district: '',
        adresse: '',
        contact: '',
        province: '',
    })
    const handleSubmit = (e) => {
        e.preventDefault()
        change()
        // console.log(e)
        // handleOpen("add")

    }
    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }

    const handleChange = (e, type) => {
        setData({ ...data, [type]: e.target.value })
    }
    function change() {
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append('nom', data.nom)
            form.append('region', data.region)
            form.append('district', data.district)
            form.append('adresse', data.adresse)
            form.append('contact', data.contact)
            // form.append('province', data.province)
            axios.post(api + `/api/modInfoClient/${id}`, form)
                .then(res => {
                    
                    handleOpen('success')
                    // handleClose('success')
                    setOptions({ ...options, loading: false })
                })
                .catch(err => {
                    // setData({...data,nom: '',region: '',district: '',address: '',contact: '',province: '' })
                    handleOpen('fails')
                    console.error(err)
                    // handleClose('fails')
                    setOptions({ ...options, loading: false })

                })
        } catch (error) {
            console.error(error)
            handleOpen('fails')
            setOptions({ ...options, loading: false })

        }
    }

    function fetchData() {
        try {
            axios.get(api + '/api/getInfoClient/' + id)
                .then(response => setData(response.data))
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.error(error);
        }    
    }

    useEffect(() => {
        
        fetchData()

    }, [])

    useEffect(() => {
        
        if (
            data.nom.trim() !== '' &&
            data.region.trim() !== '' &&
            data.district.trim() !== '' &&
            data.adresse.trim() !== '' &&
            data.contact.trim() !== ''
            // data.province.trim() !== ''

        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }

    }, [data])
    return (
        <div className='w-full'>
            <Card>
                <form onSubmit={(e) => { handleSubmit(e) }} className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full gap-x-2 flex flex-row justify-start items-center ">
                        <GoBack />
                        <span className='font-bold text-slate-500'>
                            Modification client
                        </span>
                    </div>
                    <div className="grid grid-cols-3 gap-4 justify-start items-start w-full ">
                        <Input label='Nom' value={data.nom} onChange={(e) => { handleChange(e, "nom") }} />
                        <Input label='Numero de téléphone' value={data.contact} onChange={(e) => { handleChange(e, "contact") }} />
                        <Input label='Adresse' value={data.adresse} onChange={(e) => { handleChange(e, "adresse") }} />
                        <Input label='Région' value={data.region} onChange={(e) => { handleChange(e, "region") }} />
                        <Input label='District' value={data.district} onChange={(e) => { handleChange(e, "district") }} />
                    </div>
                    <div className="flex flex-row justify-end items-center gap-x-4 w-full">
                        <Button variant='outlined' onClick={() => { navigate(-1) }}>Annuler</Button>
                        <Button variant='contained' disabled={options.disabled} type='submit'>
                            <Load title={"Modifier"} check={true} loading={options.loading} />
                        </Button>
                    </div>
                </form>
            </Card>
            <Feedback type='success' message='Modifiée avec succès.' isOpen={isOpen.success} />
            <Feedback type='error' message="Une erreur s'est produite." isOpen={isOpen.fails} />

        </div>
    )
}
