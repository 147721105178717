import React from "react"

 const AvalaibilityDay = ({item}) => {
    const isOpen =(status)=>{
        if(status){
            return "Ouvert"
        }else{
            return "Fermé"
        }
    }
    return (
        <div className="  flex justify-center items-center">
  <div className="mx-auto p-6 pb-1 border bg-white rounded-md shadow-dashboard">
    <div className="flex flex-wrap items-center justify-between mb-1 -m-2">
      <div className="w-auto p-2">
        <h2 className="text-lg font-semibold text-coolGray-900">Diponibilité</h2>
        <p className="text-xs text-coolGray-500 font-medium">Voici les jours disponible pour ce service</p>
      </div>
      <div className="w-auto p-2">
      </div>
    </div>
    <div className="flex flex-wrap">
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
              {/* <div className="w-auto p-2">
                <div
                  className="flex items-center justify-center w-12 h-12 bg-yellow-50 rounded-md"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    height="24"
                    width="24"
                  >
                    <path
                      fill="#F59E0B"
                      d="M19 4H5C4.20435 4 3.44129 4.31607 2.87868 4.87868C2.31607 5.44129 2 6.20435 2 7V17C2 17.7956 2.31607 18.5587 2.87868 19.1213C3.44129 19.6839 4.20435 20 5 20H19C19.7956 20 20.5587 19.6839 21.1213 19.1213C21.6839 18.5587 22 17.7956 22 17V7C22 6.20435 21.6839 5.44129 21.1213 4.87868C20.5587 4.31607 19.7956 4 19 4ZM5 18C4.73478 18 4.48043 17.8946 4.29289 17.7071C4.10536 17.5196 4 17.2652 4 17V14.58L7.3 11.29C7.48693 11.1068 7.73825 11.0041 8 11.0041C8.26175 11.0041 8.51307 11.1068 8.7 11.29L15.41 18H5ZM20 17C20 17.2652 19.8946 17.5196 19.7071 17.7071C19.5196 17.8946 19.2652 18 19 18H18.23L14.42 14.17L15.3 13.29C15.4869 13.1068 15.7382 13.0041 16 13.0041C16.2618 13.0041 16.5131 13.1068 16.7 13.29L20 16.58V17ZM20 13.76L18.12 11.89C17.5501 11.3424 16.7904 11.0366 16 11.0366C15.2096 11.0366 14.4499 11.3424 13.88 11.89L13 12.77L10.12 9.89C9.55006 9.34243 8.79036 9.03663 8 9.03663C7.20964 9.03663 6.44994 9.34243 5.88 9.89L4 11.76V7C4 6.73478 4.10536 6.48043 4.29289 6.29289C4.48043 6.10536 4.73478 6 5 6H19C19.2652 6 19.5196 6.10536 19.7071 6.29289C19.8946 6.48043 20 6.73478 20 7V13.76Z"
                    ></path>
                  </svg>
                </div>
              </div> */}
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Lundi</h2>
                {/* <h3 className="text-xs font-medium text-coolGray-400">44 photos</h3> */}
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.lundi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
            
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Mardi</h2>
               
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.mardi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
            
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Mercredi</h2>
               
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.mercredi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
            
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Jeudi</h2>
               
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.jeudi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
            
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Vendrei</h2>
               
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.lundi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full border-b border-coolGray-100">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
            
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Samedi</h2>
               
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.samedi)}</p>
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="flex flex-wrap items-center justify-between py-4 -m-2">
          <div className="w-auto p-2">
            <div className="flex flex-wrap items-center -m-2">
             
              <div className="w-auto p-2">
                <h2 className="text-sm font-medium text-coolGray-900">Dimanche</h2>
                {/* <h3 className="text-xs font-medium text-coolGray-400">3 videos</h3> */}
              </div>
            </div>
          </div>
          <div className="w-auto p-2">
            <p className="text-xs text-coolGray-500 font-medium">{isOpen(item.dimanche)}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
    )
}

export default AvalaibilityDay