
import dayjs from "dayjs";
import React, { useState } from "react";
import { generateDate, months } from "../../components/util/calendar";
import cn from "../../components/util/cn";
import { GrFormNext, GrFormPrevious } from "react-icons/gr"

const Calendar = ({setDateSelected, textSize = 'text-sm'}) =>{
	const days = ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"]
	const currentDate = dayjs()
	const [today, setToday] = useState(currentDate)
	const [selectDate, setSelectDate] = useState(currentDate)
	return (
		<div className={'flex gap-10 sm:divide-x justify-center mx-auto my-5 items-center sm:flex-row flex-col '+textSize}>
			<div className="w-96 h-96">
				<div className="flex justify-between items-center">
					<h1 className="select-none font-semibold">
						{months[today.month()]}, {today.year()}
					</h1>
					<div className="flex gap-6 items-center ">
						<GrFormPrevious
							className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(today.month(today.month() - 1));
							}}
						/>
						<h1
							className=" cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(currentDate);
							}}
						>
							Aujourd'hui
						</h1>
						<GrFormNext
							className="w-5 h-5 cursor-pointer hover:scale-105 transition-all"
							onClick={() => {
								setToday(today.month(today.month() + 1));
							}}
						/>
					</div>
				</div>
				<div className="grid grid-cols-7 ">
					{days.map((day, index) => {
						return (
							<h1
								key={index}
								className={" text-center h-14 w-14 grid place-content-center text-gray-500 select-none "+textSize}
							>
								{day}
							</h1>
						);
					})}
				</div>

				<div className=" grid grid-cols-7 ">
					{generateDate(today.month(), today.year()).map(
						({ date, currentMonth, today }, index) => {
							return (
								<div
									key={index}
									className={"p-2 text-center h-14 grid place-content-center border-t "+textSize}
								>
									<h1
										className={cn(
											currentMonth ? "" : "text-gray-400",
											today
												? "bg-orange-500 text-white"
												: "",
											selectDate
												.toDate()
												.toDateString() ===
												date.toDate().toDateString()
												? "bg-black-900 text-white"
												: "",
											" h-8 w-8 rounded-full grid place-content-center hover:bg-black-900 hover:text-white transition-all cursor-pointer select-none "
										) +textSize}
										onClick={() => {
											setSelectDate(date)
											console.log(date)
											setDateSelected(
												selectDate.date().toString().padStart(2, '0') + '-' + 
												(selectDate.month() + 1).toString().padStart(2, '0') + '-' + 
												selectDate.year()
											  )
										}}
									>
										{date.date()}
									</h1>
								</div>
							);
						}
					)}
				</div>
			</div>
			{/* <div className="h-96 w-96 sm:px-5">
				<h1 className=" font-semibold">
					Schedule for {selectDate.toDate().toDateString()} -- -- {selectDate.date()} {selectDate.month() } {selectDate.year()}
				</h1>
			</div> */}
		</div>
	);
}

export default Calendar