import React, { useEffect, useState } from 'react'
import Input from "../../../component/Forms/Input"
import axios from 'axios'
import { api } from '../../../api'
// import Notification from "../../../websitePro/components/Notification"
import Button from '../../../websitePro/components/Button'
import Feedback from '../../../admin/components/feedback'

export default function VerificationEmail({ data, setData, setPage }) {
    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        fails: false
    })

    function handleSubmit(e) {
        e.preventDefault()
        setOptions({ ...options, loading: true })
        // setPage(prev => prev += 1)
        let coder = Math.ceil(Math.random() * 9001) + 999
        console.log(coder)
        setData({ ...data, codeGenerated: coder })
        // console.log()
        try {
            // setData({ ...data, codeGenerated: Math.ceil(Math.random() * 9001) + 999 })
            const form = new FormData()
            form.append('email', data.email)
            form.append('code', coder)
            axios.post(api + '/api/verificationAccount', form)
                .then(response => {
                    console.log(response.data)
                    setOptions({ ...options, loading: false })
                    setPage(prev => prev += 1)


                })
                .catch(error => {
                    console.error('Error fetching data: ', error);
                    setIsOpen({ ...isOpen, fails: true })
                    setOptions({ ...options, loading: false })
                });
        } catch (error) {
            console.error(error)
            setIsOpen({ ...isOpen, fails: true })
            setOptions({ ...options, loading: false })

        }
    }

    useEffect(() => {
        if (data.email !== '') {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <form onSubmit={(e) => { handleSubmit(e) }} className='w-full flex flex-col gap-y-3'>
            <Input label={"Votre e-mail"} required={true} type="email" className='w-full' value={data.email} onChange={(e) => { setData({ ...data, email: e.target.value }) }} />
            <Button  disabled={options.disabled} onClick={(e) => { handleSubmit(e) }}>
                {/* <Load  title={'Suivant'} loading={options.loading} /> */}
                Suivant
            </Button>
            <Feedback isOpen={isOpen.fails} type='error' message="Une erreur s'est produit." onClose={() => { setIsOpen({ ...isOpen, fails: false }) }} />
        </form>
    )
}
