import React, { useState } from 'react';

const TimePicker = ({
    minuteName,
    hourName,
    selectedMinute,
    selectedHour,
    setSelectedHour,
    setSelectedMinute,
    onChange,
    required = false
}) => {
//   const [selectedHour, setSelectedHour] = useState(7); // Heure par défaut: 07
//   const [selectedMinute, setSelectedMinute] = useState(0); // Minute par défaut: 00

  const hours = Array.from({ length: 24 }, (_, i) => i); // Liste des heures de 0 à 23
  const minutes = Array.from({ length: 12 }, (_, i) => i * 5); // Liste des minutes incrémentées par 5

  return (
    <div className='flex gap-2'>
      {/* <label htmlFor="hour">Heure: </label> */}
      <select 
        id={hourName} 
        name={hourName}
        value={selectedHour} 
        required={required}
        onChange={(e)=>setSelectedHour(e.target.value)}
        className='border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:border-2 focus:border-orange-400 focus:ring-[#FF7200]/10 focus:border-transparent w-full'
      >
        {hours.map((hour) => (
          <option key={hour} value={hour}>
            {hour.toString().padStart(2, '0')}
          </option>
        ))}
      </select>

      {/* <label htmlFor="minute">Minute: </label> */}
      <select 
        id={minuteName}
        name={minuteName}
        value={selectedMinute} 
        required={required}
        onChange={(e)=>setSelectedMinute(e.target.value)}
        className='border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:border-2 focus:border-orange-400 focus:ring-[#FF7200]/10 focus:border-transparent w-full'
      >
        {minutes.map((minute, index) => (
          <option key={minute} value={minute} selected={(minute===0)} >
            {minute.toString().padStart(2, '0')}
          </option>
        ))}
      </select>
    </div>
  );
};

export default TimePicker;
