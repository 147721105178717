import React, {useState} from "react";
import searchIcon from "../../assets/icons/search.png"
import BouttonBlur from "../Button/BouttonBlur"
import homeimage from "../../assets/images/homeimage.jpg"
import { Link } from "react-router-dom"

const Hero = () => {
  const [priceValue, setPriceValue] = useState(30)
  

  return (
    <div className=" bg-black/20 h-full">
      <img src={homeimage} className="absolute right-0 top-0 h-[700px] w-full object-cover" alt='Logo'/>
      <div className=" absolute right-0 top-0 h-[700px] w-full flex justify-center items-center p-4 bg-gray-900/50">
      <div className="mx-auto mt-56 w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-32">
    <div className="mx-auto max-w-3xl text-center">
      <h1 className="mb-6 pb-4 text-4xl font-bold text-white md:text-6xl">Réservez votre rendez-vous en ligne</h1>
      <p className="mx-auto mb-8 max-w-[528px] text-xl text-[#e2e2e2] lg:mb-12  tracking-wide">Préparez-vous à un rendez-vous rapide, efficace et personnalisé. Profitez d'une expérience innovante et sécurisée, à tout moment et depuis l'espace confortable de votre maison.</p>
      <Link to="service" className="inline-block rounded-full bg-primary text-white px-8 py-3 text-center font-bold text-black transition hover:border-black hover:bg-orange-400">Commencer</Link>
    </div>

      </div>
    </div>
    </div>
  )
}

export default Hero
