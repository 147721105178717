import React from "react";
import { Link } from "react-router-dom";
import { NavbarLinks } from "./Navbar"
import prestataire1 from "../assets/images/prestataire1.png"
import Logo from "../assets/logo/logo.png"
import { api } from "../../api"

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  console.log("showMenu", showMenu);

  return (
    <div 
      onClick={() => setShowMenu(false)}
      className={`${showMenu ? "w-screen" : "w-0"} sm:hidden bg-gray-900/20 h-screen fixed z-20 top-0 backdrop-blur-md backdrop-filter`}
    >
      <div
        onClick={(e) => e.stopPropagation()} 
        className={`${
          showMenu ? "left-0 w-[300px] block" : "-left-[500px]"
        } fixed bottom-0 top-0 z-40 flex h-screen  flex-col justify-between bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-10 text-black transition-all duration-300 md:hidden rounded-r-xl shadow-md`}
      >
        <img src={Logo} alt="" className="h-10 w-20" />
        <hr />
        <div className="card">
          <div className="flex items-center justify-start gap-3">
            {/* <FaUserCircle size={50} /> */}
            <img src={api+"/storage/"+localStorage.getItem("soro-email")}
            onError={(e)=>{e.target.error=null; e.target.src=prestataire1}}
            alt="prestataire" className="outline outline-[4px] outline-primary/30 w-10 h-10 rounded-full"/>
            <div>
              <h1>Bonjour</h1>
              <h1 className="text-sm text-slate-500">{localStorage.getItem("soro-email")}</h1>
            </div>
          </div>
          <nav className="mt-12">
            <ul className="space-y-4 text-xl text-start">
              {NavbarLinks.map((data) => (
                <li key={data.name}>
                  <Link
                    to={data.link} // Adjust based on your routing setup
                    onClick={() => setShowMenu(false)}
                    className="mb-5 inline-block text-black-900 text-base"
                  >
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        <hr />
        <div className="footer">
          <h1>
            @ Copirighting Haisoa Consulting
          </h1>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveMenu;