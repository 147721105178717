import React, { useEffect, useState } from 'react'
import Sidebar from '../../components/Sidebar'
import { Link, useOutletContext } from 'react-router-dom'
import { FaEllipsisV } from 'react-icons/fa'
import bag from '../../assets/icons/bag.png'
import { BiArrowFromLeft, BiArrowToTop } from 'react-icons/bi'
// import { Line } from 'react-chartjs-2'
// import { Chart as ChartJS, LineElement, CategoryScale, PointElement, LinearScale } from 'chart.js'
import AreaChartComponent from '../../components/AreaChartComponent'
import ServiceCard from '../../components/Cards/ServiceCard'
import { ServiceData } from '../../../website/Datas/Datas'
import axios from 'axios'
import { api } from '../../../api'
import {TimeConverter} from "../../components/DateTimeConverter/TimeConverter"

// ChartJS.register(
//   LineElement, CategoryScale, PointElement, LinearScale
// )

const Index = () => {
  const { setTitle } = useOutletContext()
  const [services, setServices] = useState(ServiceData)
  const id = 5;

  const [totalClient, setTotalClient] = useState([])
  const [totalRdv, setTotalRdv] = useState([])
  const [totalOffre, setTotalOffre] = useState([])
  const [listeRdv, setListeRdv] = useState([])

  function getNombreClient() {
    try {
        axios.get(api + `/api/getClientTotalRdvPrest/${id}`)
            .then(response => {
              setTotalClient(response.data)
                // setCount({...count, clientPro: response.data})
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    } catch (error) {
        console.error(error);
    }
  }

  function getNombreRdv() {
    try {
        axios.get(api + `/api/getRdvUserPro/${id}`)
            .then(response => {
              setTotalRdv(response.data)
                // setCount({...count, clientPro: response.data})
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });

    } catch (error) {
        console.error(error);
    }
  }

  function getNombreOffre() {
    try {
        axios.get(api + `/api/getTotalOffrePrest/${id}`)
            .then(response => {
              setTotalOffre(response.data)
                // setCount({...count, clientPro: response.data})
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            })

    } catch (error) {
        console.error(error);
    }
  }

  function getRdvPrest() {
    try {
        axios.get(api + `/api/get5RdvPrest/${id}`)
            .then(response => {
              setListeRdv(response.data)
              console.error('fetching data appointment:', response.data);
                // setCount({...count, clientPro: response.data})
            })
            .catch(error => {
                console.error('Error fetching data appointment in dashboard:', error);
            });

    } catch (error) {
        console.error(error);
    }
  }

  // const  data = {
  //   labels: ['Janv', 'Fév', 'Mar', 'Avr', 'Mai', 'Jui', 'Jui', 'Aoû', 'Sept','Oct', 'Nov', 'Déc'],
  //   datasets:[{
  //     data:[8, 9, 4, 8, 4, 8, 4],
  //     backgroundcolor: 'transparent',
  //     borderColor: '#f26c6d',
  //     pointBorderColor: 'transparent',
  //     pointBorderWidth: 4,
  //     tension: 2
  //   }]
  // }
  // const options ={
  //   plugin: {
  //     legend: false,
  //   },
  //   scales: {
  //     x: {
  //      grid: {
  //       display: false,}
  //     },
  //     y:{
  //       min: 2,
  //       max: 10,
  //       ticks:{
  //         stepSize: 2,
  //         callBack: (value)=> value+"K"
  //       },
  //       grid: {
  //         borderDash: [10],   
  //       }
        
  //     }
  //   }
  // }
  
  const [resumeRDV, setResumeRDV] = useState([])
  const [resumeClient, setResumeClient] = useState([])

  useEffect(() => {
  //   axios.get(api+"/api/getRdvCountPerMonth/"+localStorage.getItem("soro-id"))
  //  .then(response => {
  //   setResumeRDV(response.data)
  //   console.log("Nombre rdv chart",response.data)
  //   })
  //   .catch((err)=>{
  //     console.error(err)
  //   })

  //   axios.get(api+"/api/getUniqueClientsPerMonth/"+localStorage.getItem("soro-id"))
  //   .then(response => {
  //     setResumeClient(response.data)
  //     console.log("Nombre client chart",response.data)
  //    })
  //    .catch((err)=>{
  //      console.error(err)
  //    })
    

  })

  useEffect(() => {

    getNombreClient()
    getNombreRdv()
    getNombreOffre()
    getRdvPrest()

    axios.get(api+"/api/getRdvCountPerMonth/"+localStorage.getItem("soro-id"))
    .then(response => {
     setResumeRDV(response.data)
     console.log("Nombre rdv chart",response.data)
     })
     .catch((err)=>{
       console.error(err)
     })
 
     axios.get(api+"/api/getUniqueClientsPerMonth/"+localStorage.getItem("soro-id"))
     .then(response => {
       setResumeClient(response.data)
       console.log("Nombre client chart",response.data)
      })
      .catch((err)=>{
        console.error(err)
      })
    setTitle('Tableau de bord')

}, [setTitle])

const getStatusColor = (status) => {
  switch (status) {
    case 'En attente':
      return 'bg-yellow-50'; // Yellow background for pending appointments
    case 'Annulé':
      return 'bg-red-50'; // Red background for canceled appointments
    default:
      return ''; // Neutral background for confirmed appointments
  }
};

const getStatusBorderColor = (status) => {
  switch (status) {
    case 'En attente':
      return 'bg-yellow-400'; // Yellow border for pending appointments
    case 'Annulé':
      return 'bg-red-400'; // Red border for canceled appointments
    default:
      return 'bg-slate-100'; // Neutral border for confirmed appointments
  }
};

  return (
    <div>
    <div className="relative  bg-blueGray-100">
      {/* <Navbar /> */}
      {/* <Header></Header> */}
      <div className="relative pb-32 " >
        <div className="px-4 grid grid-cols-10 gap-4 mb-4">
          <div className='col-span-6'>
            {/* Card stats */}

           
            <div className="grid grid-cols-3 gap-4 mb-4">
               {/* card1 */}
              <div className="bg-white rounded-md border-line shadow">
                <div className="flex justify-between mb-1">
                  <h3 className='title'>Total client</h3>
                  <FaEllipsisV></FaEllipsisV>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="bg-primary rounded-xl flex items-center justify-center w-12 h-12">
                      <img src={bag} alt="" className=' w-5 h-5' />
                    </div>
                    <span className=' font-bold text-2xl'>{totalClient}</span>
                  </div>
                  {/* statictic */}
                  {/* <div className="flex gap-1 items-center justify-end">
                  <BiArrowToTop></BiArrowToTop>
                  50%
                  </div> */}
                </div>
                {/* <div className="flex justify-end">
                  Comparé au mois de Janvier
                </div> */}
              </div>

               {/* card1 */}
               <div className="bg-white rounded-md border-line shadow">
                <div className="flex justify-between mb-1">
                  <h3 className='title'>Total rendez-vous</h3>
                  <FaEllipsisV></FaEllipsisV>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="bg-primary rounded-xl flex items-center justify-center w-12 h-12">
                      <img src={bag} alt="" className=' w-5 h-5' />
                    </div>
                    <span className=' font-bold text-2xl'>{totalRdv}</span>
                  </div>
                  {/* statictic */}
                  {/* <div className="flex gap-1 items-center justify-end">
                  <BiArrowToTop></BiArrowToTop>
                  50%
                  </div> */}
                </div>
                {/* <div className="flex justify-end">
                  Comparé au mois de Janvier
                </div> */}
              </div>

               {/* card1 */}
               <div className="bg-white rounded-md border-line shadow">
                <div className="flex justify-between mb-1">
                  <h3 className='title'>Total offre</h3>
                  <FaEllipsisV></FaEllipsisV>
                </div>
                <div className="flex justify-between">
                  <div className="flex items-center gap-2">
                    <div className="bg-primary rounded-xl flex items-center justify-center w-12 h-12">
                      <img src={bag} alt="" className=' w-5 h-5' />
                    </div>
                    <span className=' font-bold text-2xl'>{totalOffre}</span>
                  </div>
                  {/* statictic */}
                  {/* <div className="flex gap-1 items-center justify-end">
                  <BiArrowToTop></BiArrowToTop>
                  50%
                  </div> */}
                </div>
                {/* <div className="flex justify-end">
                  Comparé au mois de Janvier
                </div> */}
              </div>
            </div>

                <div className="border-line ">
                  <div className="flex justify-between items-center mb-2">
                    <p>Statistique</p>
                    {/* <div className="flex items-center justify-end gap-2">
                      <div className="flex items-center"><input type="radio" name="periode" id="mois" value="mois"/><label htmlFor="mois">Mois</label></div>
                      <div className="flex items-center"><input type="radio" name="periode" id="annee" value="annee"/><label htmlFor="annee">Année</label></div>
                      <div className="flex items-center"><input type="radio" name="" id=""><label htmlFor=""></label></input></div>
                    </div> */}
                  </div>
                  <div className=" h-40 w-full">
                  {/* <Line data={data} options={options} width=''></Line> */}
                  <AreaChartComponent rdvData={resumeRDV} clientData={resumeClient}/>
                  </div>
                </div>

          </div>
          <div className='col-span-4 border-line'>
            {/* Card stats */}
            <div className="flex justify-between mb-4">
              <h5 className=' text-lg font-semibold'>Rendez-vous</h5>
              <Link className='text-primary font-semibold' to={'appointments'}>Voir tout</Link>
            </div>
            <div className="p-2 bg-gray-50 rounded-md justify-between flex items-center mb-2">
              <p>Septembre 2024</p>
              <BiArrowFromLeft/>
            </div>

            {listeRdv.map((rdv, index) => (
          <div key={index} className={`p-2 cursor-pointer rounded-md justify-between flex items-center mb-2 ${getStatusColor(rdv.etat)} hover:${(rdv.etat === "Confirmé"? "bg-gray-50" : "")}`}>
            <div className="flex gap-2">
              <div className={`rounded-md flex items-center ${getStatusBorderColor(rdv.etat)}`}>
                <div className="flex-col flex items-center justify-center px-4 py-1">
                  <div>{new Date(rdv.date).getDay()}</div>
                  <div className='font-bold'>
                    {new Date(rdv.date).toLocaleString('fr-FR', { month: 'long' }).slice(0, 4).charAt(0).toUpperCase() + new Date(rdv.date).toLocaleString('fr-FR', { month: 'long' }).slice(1, 4)}
                  </div>

                </div> 
              </div>
              <div className="flex-col flex justify-between">
                <div>{rdv.user.nom}</div>
                <div>{TimeConverter(rdv.heure, rdv.minute)}</div>
              </div>
            </div>
            <BiArrowFromLeft />
          </div>
        ))}
          {/* <div>

           
            <div className="p-2 bg-yellow-50 cursor-pointer rounded-md justify-between flex items-center mb-2">
              <div className="flex gap-2">
                <div className="bg-yellow-400 rounded-md flex items-center">
                  <div className="flex-col flex items-center justify-center px-4 py-1">
                    <div>12</div>
                    <div className='font-bold'>Juin</div>
                  </div> 
                </div>

              <div className="flex-col flex justify-between">
                  <div>Dr. Ashton Cleve</div>
                  <div>10:00 - 10:30</div>
                </div>
              </div>
            
              <BiArrowFromLeft/>
            </div>


          <div className="p-2 hover:bg-gray-50 cursor-pointer rounded-md justify-between flex items-center mb-2">
            <div className="flex gap-2">
          <div className="bg-slate-100 rounded-md flex items-center">
              <div className="flex-col flex items-center justify-center px-4 py-1">
                <div>12</div>
                <div className='font-bold'>Juin</div>
              </div> 
            </div>

            <div className="flex-col flex justify-between">
                <div>Dr. Ashton Cleve</div>
                <div>10:00 - 10:30</div>
              </div>
            </div>
            
            <BiArrowFromLeft/>
          </div>
          <div className="p-2 hover:bg-gray-50 cursor-pointer rounded-md justify-between flex items-center mb-2">
            <div className="flex gap-2">
          <div className="bg-slate-100 rounded-md flex items-center">
              <div className="flex-col flex items-center justify-center px-4 py-1">
                <div>12</div>
                <div className='font-bold'>Juin</div>
              </div> 
            </div>

            <div className="flex-col flex justify-between">
                <div>Dr. Ashton Cleve</div>
                <div>10:00 - 10:30</div>
              </div>
            </div>
            
            <BiArrowFromLeft/>
          </div>
          </div> */}

          </div>
        </div>


      </div>

    </div></div>
  )
}

export default Index