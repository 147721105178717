// src/components/TruncateText/TruncateText.tsx

import React from 'react';

interface TruncateTextProps {
  children: React.ReactNode;
  maxChars: number;
}

const TruncateText: React.FC<TruncateTextProps> = ({ children, maxChars, showButton = false, opacityLevel=70, className }) => {
  const truncatedText = children.slice(0, maxChars);
  const showMore = children.length > maxChars;

  return (
    <div>
      {showMore ? (
        <>
        <p className={className+ ' opacity-'+opacityLevel}>{truncatedText+"... "} </p>
          {showButton && showMore  ? <button onClick={() => alert(`Voir plus : ${children}`)} className='text-sm text-[#000000] font-semibold'> Voir plus</button>: " " }
        </>
     ) 
      : (
        <span className={className+ ' opacity-'+opacityLevel}>{children}</span>
      )
      } 
    </div>
  );
};

export default TruncateText;
