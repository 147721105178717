import React, { useEffect, useState } from 'react'
// import Back from '../entrepreneur/components/btn/back'
// import Input from '../entrepreneur/components/form/Input'
import VerificationEmail from './VerificationEmail'
import VerificationCode from './VerificationCode'
import ChangePassword from './ChangePassword'
import { BsBack } from 'react-icons/bs'
import { BiArrowBack } from 'react-icons/bi'
import logo from "../../website/assets/logo/logo.png"
import { useNavigate } from 'react-router-dom'
// Input
export default function ForgotPassword() {
    const navigate = useNavigate()
    const [data, setData] = useState({
        email: '',
        code: '',
        codeGenerated: '',
        password: '',
        confirmPassword: '',
    })
    const [page, setPage] = useState(1)
    useEffect(() => {
        console.log(data)
    }, [data])

    return (
        <div className="w-screen max-w-screen h-screen flex flex-row justify-center items-center  bg-gray-50/40">
            <div className="flex-col justify-center flex gap-y-10 max-lg:w-11/12 lg:w-5/12">
            <div className="flex justify-center"> <img src={logo} alt="logo"  className='w-36 h-20'/></div>
               
            <div className=' p-6 bg-white shadow-md  border rounded-md'>
                {/* <form > */}
                <div className='grid grid-cols-12'>
                    <div className="col-span-1">
                        <button onClick={()=>{
                            if(page===1){
                                navigate("/login")
                            }else{
                              setPage(page-1)  
                            }
                            }}><BiArrowBack/> </button>
                    </div>
                    
                </div>

                <div className="col-span-11 flex justify-center">
                        <h4 className='text-lg font-semibold mb-4'>Mot de passe oublié</h4>
                    </div>
                <p>
                </p>
                {
                    page == 1 && (
                        <VerificationEmail setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page == 2 && (
                        <VerificationCode setPage={setPage} data={data} setData={setData} />
                    )
                }
                {
                    page == 3 && (
                        <ChangePassword setPage={setPage} data={data} setData={setData} />
                    )
                }
            </div> 
            </div>
           
        </div>
    )
}
