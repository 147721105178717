import React, { useState, useEffect } from 'react';
import ButtonOutline from '../../../components/Button/ButtonOutline';
import Status from '../../../components/Status/Status';
import ModalAbonnement from '../../../components/Modal/ModalAbonnement';
import { api } from '../../../../api';
import axios from 'axios';

const History = () => {
    const [showModal, setShowModal] = useState(false);
    const [abo, setAbo] = useState([]);
    const [selectedSubscription, setSelectedSubscription] = useState(null);
    const id = 5 //Soloina localStorage, id du prestataire connecté

    const formatDate = (dateStr) => {
        const date = new Date(dateStr);
        return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
    };

    useEffect(() => {
        // Remplacez l'URL par l'URL de votre API
        axios.get(`${api}/api/getAboUser/${id}`)
          .then(response => {
            setAbo(response.data);
          })
          .catch(error => {
            console.error("There was an error fetching the customer data!", error);
          })
    }, []);

    const handleOpenModal = (abonnement) => {
        setSelectedSubscription(abonnement);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedSubscription(null);
    };

    return (
        <div>
            <div className="">
                <h5 className='title1 mb-1'>Historique des abonnements</h5>
                <hr />
                <div className="overflow border rounded-md">
                 <table className='w-full table2'>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Plan</th>
                            <th>Date</th>
                            <th>État</th>
                            <th>Prix (Ar)</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {abo.map((abonnement) => (
                            <tr key={abonnement.id}>
                                <td>{abonnement.id}</td>
                                <td>{abonnement.type}</td>
                                <td>{formatDate(abonnement.dateDebut)} à {formatDate(abonnement.dateFin)}</td>
                                <td><Status text={abonnement.etat} /></td>
                                <td>{abonnement.prix} Ariary</td>
                                <td>
                                    <ButtonOutline onClick={() => handleOpenModal(abonnement)}>Détails</ButtonOutline>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>    
                </div>
               
            </div>
            {selectedSubscription && (
                <ModalAbonnement 
                    show={showModal} 
                    onClose={handleCloseModal} 
                    subscription={selectedSubscription} 
                />
            )}
        </div>
    );
};

export default History;
