import React, { useState, useRef , useEffect}  from 'react'
import  "./Register.css"
import axios from 'axios'
import { api } from '../../api'

const VerifyCode = ({isEmailValidated, email, codeGenerated1 }) => {
    // const VerifyCode = ({ isEmailValidated, email }) => {
        const [codeSent, setCodeSent] = useState(false);
        const inp1 = useRef(null);
        const inp2 = useRef(null);
        const inp3 = useRef(null);
        const inp4 = useRef(null);
        const [notMatch, setNotMatch] = useState(false);
        const [code, setCode] = useState([undefined, undefined, undefined, undefined]);
        // let codeGenerated = generatingCode();
        // console.log(codeGenerated1)
        const getCodeGenerated = () => {
        //   if (codeSent) return; // Empêcher l'envoi multiple du code
      
        //   codeGenerated = generatingCode()
        //   console.log(codeGenerated)
        // console.log(codeGenerated1)
          const form = new FormData()
          form.append("email", email)
          form.append("code", codeGenerated1)
      
          axios.post(api + '/api/sendEmail', form)
            .then((res) => {
              console.log('Code envoyé avec succès');
              setCodeSent(true); // Marquer comme envoyé
            })
            .catch((err) => {
              console.log("Une erreur est survenue.");
            });
        }
      
        // function generatingCode() {
        //   return Math.ceil(Math.random() * 9001) + 999;
        // }
       
       

    const handleChange = (index, prevRef, currentRef, nextRef) => {

        code[index] = currentRef.current.value
        console.log(code[index])
        if (currentRef.current.value !== '') {
            if (nextRef !== undefined) {
                nextRef.current.focus()
            } else {
                const codeConfirmation = code[0] + code[1] + code[2] + code[3]
                console.log("code généré"+codeGenerated1+"code confirmé"+codeConfirmation)
                if (codeGenerated1.toString() == codeConfirmation) {
                    isEmailValidated(true)
                    setNotMatch(false)
                } else {
                    isEmailValidated(false)
                    setNotMatch(true)
                    // getCodeGenerated()
                    code[0] = undefined
                    code[1] = undefined
                    code[2] = undefined
                    code[3] = undefined
                }
            }
        } else if (currentRef.current.value === '') {
            if (prevRef !== undefined) {
                prevRef.current.focus()
            }
        }

    }


    return (
        <>
            <form className=' w-full text-center'>
                <p className="text-slate-600">Nous avons envoyer de code de verification à votre adresse email <span className="text-primary">{email}</span>.</p>
                <div className="flex flex-col w-full items-center py-4">

                    <div className="flex w-full gap-x-3 justify-center items-center">
                        
                        <button className="btn-tertiary" onClick={(e) => { e.preventDefault(); getCodeGenerated() }}>Réenvoyer</button>
                    </div>
                </div>

                <div className="grid grid-cols-4 justify-center items-center gap-x-4 py-5">

                    <input type="text" ref={inp1} value={code[0]} onChange={(e) => { handleChange(0, undefined, inp1, inp2) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                    <input type="text" ref={inp2} value={code[1]} onChange={(e) => { handleChange(1, inp1, inp2, inp3) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                    <input type="text" ref={inp3} value={code[2]} onChange={(e) => { handleChange(2, inp2, inp3, inp4) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                    <input type="text" ref={inp4} value={code[3]} onChange={(e) => { handleChange(3, inp3, inp4, undefined) }} maxLength={1} className='border text-3xl text-center font-bold rounded-md h-20' />
                </div>
                {
                    notMatch && (
                        <div className="w-full">
                            <span className="flex flex-col justify-center items-center w-full rounded-sm gap-x-4 bg-orange-100 text-orange-400 pt-2 pb-4 px-2">
                                <span className="w-full flex justify-end items-center">
                                </span>
                                <span className="flex justify-center items-center gap-x-3">
                                    <p>
                                        Réessayer. nous avons envoyer une autre code de verification a votre adresse email.
                                    </p>
                                </span>
                            </span>
                        </div>
                    )
                }


            </form>
        </>
    )
}
export default VerifyCode
