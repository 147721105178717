import React, { useEffect, useState } from 'react'
import ButtonBack from '../../components/Button/ButtonBack'
import Button from "../../components/Button"
import Input from "../../../component/Forms/Input"
import Textarea from "../../../component/Forms/Textarea"
import Select from "../../../component/Forms/Select"
import Status from '../../components/Status/Status'
import axios from 'axios'
import { api } from '../../../api'
import Feedback from "../../../admin/components/feedback"
import ConfirmModal from "../../components/Modal/ConfirmModal"

const AddService = () => {
  const id = 5 //Misolo localStorage, adapter si nécessaire
  const [formData, setFormData] = useState({
    nom: '',
    description: '',
    photo: null, // Changer à null pour mieux gérer les fichiers
    etat: 'Publié',
    adresse: '',
    user_id: id,
    secteur_id: '',
  });
  const [secteurs, setSecteurs] = useState([])
  const [showAcceptModal, setShowAcceptModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [imagePreview, setImagePreview] = useState(''); // Nouvel état pour l'aperçu de l'image

  const handleChange = (e) => {
    const { name, value, files } = e.target;
    if (name === "photo" && files && files.length > 0) {
      const file = files[0];
      setFormData({
        ...formData,
        [name]: file, // Stocker le fichier directement
      });
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result); // Mettre à jour l'aperçu de l'image
      };
      reader.readAsDataURL(file);
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  }

  const getAllSecteurs = async () => {
    try {
      const res = await axios.get(api + '/api/allSecteurs');
      setSecteurs(res.data);
    } catch (error) {
      console.error('Erreur lors de la récupération des secteurs:', error);
    }
  }

  const handleSubmit = async () => {
    const formDataToSend = new FormData();
    Object.keys(formData).forEach(key => {
      if (formData[key] !== null && formData[key] !== '') {
        formDataToSend.append(key, formData[key]);
      }
    });

    formDataToSend.forEach((el)=>{
      console.log("FormData : "+ el)
    })

    console.log("Object: "+formData)

    try {
      const response = await axios.post(api + '/api/ajoutService', formDataToSend, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      console.log('Service enregistré avec succès:', response.data);
      setShowAcceptModal(false)
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Erreur lors de l\'enregistrement du service:', error.response?.data ?? error.message);
      setShowAcceptModal(false)
      setShowErrorModal(true);
    }
  };

  const isValid = () => {
    return formData.nom && formData.description && formData.adresse && formData.secteur_id && formData.photo;
  }
  

  useEffect(() => {
    getAllSecteurs();
  }, []);

  return (
    <div className='px-6 mx-6'>
      <div className="flex justify-between items-center">
        <ButtonBack path={'/pro/services'} text={"Créer un nouveau service"} />
        <Button onClick={() => setShowAcceptModal(true)} disabled={!isValid()}>Enregistrer</Button>
      </div>
      <div className="grid grid-cols-6 gap-3 my-5">
        <div className="col-span-4 space-y-4">
          <div className="border-line">
            <Input
              label={"Nom"}
              name={"nom"}
              className='w-full'
              placeholder='Ajouter un nom...'
              onChange={handleChange}
            />
            <Textarea
              label={"Description"}
              name="description"
              id="description"
              placeholder='Écrire une description...'
              onChange={handleChange}
            />
            <Input
              label={"Adresse"}
              name={"adresse"}
              className='w-full'
              placeholder='Ajouter une adresse...'
              onChange={handleChange}
            />
          </div>
          <div className="border-line">
              <label htmlFor="photo" className='text-sm font-medium text-gray-700 mb-1 text-start flex justify-start'>Photo</label>
              <div className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
                <input
                  name="photo"
                  type="file"
                  className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                  onChange={handleChange}
                />
                <div className="flex flex-col items-center justify-center py-10 text-center">
                  {imagePreview ? (
                    <img
                      src={imagePreview}
                      alt="Selected"
                      className="w-32 h-32 object-cover rounded-full"
                    />
                  ) : (
                    <div className='bg-orange-400/10 flex items-center justify-center p-2 text-primary rounded-full'>
                      <svg
                        className="w-6 h-6 text-current-50"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                        />
                      </svg>
                    </div>
                  )}
                  <p className="m-0">Glisser l’image ici ou cliquer sur Ajouter une image.</p>
                </div>
              </div>
            </div>
        </div>
        <div className="col-span-2">
          <div className="border-line">
            <div className="mb-4">
              <label
                htmlFor="secteur"
                className="block text-sm font-medium text-gray-700 mb-1 text-start"
              >
                Secteur
              </label>
              <select
                name="secteur_id"
                onChange={handleChange}
                required
                className={`border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full`}
              >
                <option value="">Séléctionner</option>
                {secteurs.map((item) => (
                  <option key={item.id} value={item.id}>
                    {item.nom}
                  </option>
                ))}
              </select>
            </div>
            <Select
              label={"État"}
              name="etat"
              placeholder='Sélectionner un état...'
              options={["Publié", "Non publié"]}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <ConfirmModal 
        show={showAcceptModal} 
        onClose={() => setShowAcceptModal(false)}
        title="Confirmation"
        text="Êtes-vous sûr de vouloir enregistrer ce service"
        buttonText="Enregistrer"
        action={handleSubmit}
      />
      <Feedback isOpen={showSuccessModal} onClose={() => setShowSuccessModal(false)} message='Enregistrement réussie !' />
      <Feedback isOpen={showErrorModal} onClose={() => setShowErrorModal(false)} type='error' message='Une erreur est survenue !' />
    </div>
  );
}

export default AddService;

