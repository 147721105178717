import React from "react";
import { api } from "../../../../../api";

const CategoryCard = ({ item, selected, setSelected }) => {
  return (
    <div
      className={` shadow-md rounded-lg p-6 cursor-pointer ${
        selected ? 'bg-orange-500 border-2' : ' bg-white'
      }`}
      onClick={() => setSelected(item.id)}
    >
      <div className="flex justify-center items-center">
        <img
          src={api + '/storage/' + item.logo}
          alt={item.nom}
          className="w-20 h-20 mx-auto mb-4 bg-slate-100 rounded-full shadow-sm"
        />
      </div>
      <h4 className="text-lg font-semibold mb-2 text-center">{item.nom}</h4>
    </div>
  );
};

export default CategoryCard;
