import React, { useState } from 'react'


const ModalDelete = ({show, onClose}) => {
    const [timeSelected, setTimeSelected] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)

if (!show) return null

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-950/20">
      <div className="relative w-[900px] px-4 h-full md:h-auto ">
        <div className="bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-gray-900 text-lg lg:text-lg font-semibold ">
              Ajouter un nouveau RDV
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="grid grid-cols-6 p-6  h-[500px] overflow-y-scroll">
            <div className="col-span-2 border-r pr-4">
                <div className="flex-col">
                    contenu
                </div>
            </div>

          
          </div>
          <div className="flex justify-end space-x-2 items-center px-6  border-t border-gray-200 rounded-b dark:border-gray-600 py-3 bg-gray-200">
         
            <button
              onClick={onClose}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            >
              Non, annuler
            </button> 
            <button className='bg-black-900 text-white  rounded-md py-2 px-4' onClick={()=>{setShowConfirmModal(false)
                        }}>Oui, supprimer</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ModalDelete