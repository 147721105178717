import React from 'react'

const Modal = ({children,width,setShowModal}) => {
    
  return (
    <div className='w-screen h-full fixed top-0 left-0 z-[100] bg-black-900/20 shadow-md backdrop-blur-sm' onClick={()=>setShowModal(false)}>
        <div className={"w-["+width+"] bg-white absolute left-1/2 top-1/2 z-[110]  rounded transform -translate-x-1/2 -translate-y-1/2 p-5"} >
         {children}
        </div>
    </div>
  )
}

export default Modal