import Button from '@mui/material/Button';
import Logo from '../../assets/image/404.png'
import Card from '../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoCreate, IoPersonCircleOutline, IoSearch, IoShare, IoTrash } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'
import Input from '../../components/form/input'
import Path from '../../components/path';
import axios from 'axios';
import { api } from '../../../api';
import Modals from '../../components/modal';
import Feedback from '../../components/feedback';
import Load from '../../components/load';
import { exportToExcel } from '../../components/ExcelExport';
export default function Categorie() {
    const navigate = useNavigate()
    const [data, setData] = useState([

    ])
    const [search, setSearch] = useState('')
    const [id, setId] = useState(null)
    const [isOpen, setIsOpen] = useState({
        delete: false,
        success: false,
        fails: false
    })
    const [options, setOptions] = useState({
        loading: false
    })
    const handleClose = (type) => {
        setIsOpen({ ...isOpen, [type]: false })
    }

    const handleOpen = (type) => {
        setIsOpen({ ...isOpen, [type]: true })
    }

    function fetchData() {
        try {
            axios.get(api + '/api/allSecteurs')
                .then(response => setData(response.data))
                .catch(error => console.error(error));
        } catch (error) {
            console.error(error);
        }
    }

    function deleteCategorie() {
        setOptions({ ...options, loading: true })
        try {
            axios.post(api + '/api/deleteSecteur/' + id)
                .then(response => {
                    handleOpen('success')
                    handleClose('delete')
                    fetchData()
                    console.log(response.data)
                })
                .catch(error => {
                    handleOpen('fails')
                    console.error(error)
                    handleClose('delete')

                })
        } catch (error) {
            console.error(error)
            handleOpen('fails')
            handleClose('delete')
        }
    }

    useEffect(() => {
        fetchData()
    }, [])

    return (
        <div className='w-full flex flex-col gap-y-3'>
            <div className="w-full flex flex-row justify-between items-center">
                <div className='flex flex-row gap-x-3 justify-start items-center'>
                    <Button className="flex flex-row gap-x-3" variant="contained" onClick={() => { navigate('/admin/nouveauSecteur') }}>
                        <IoAddCircle />
                        <span>Nouveau</span>
                    </Button>
                    <Button className="flex flex-row gap-x-3" variant="outlined"
                        onClick={() => {
                            exportToExcel(data, "Secteur")
                        }}
                    >
                        <IoShare />
                        <span>Exporter</span>
                    </Button>
                </div>
                <div className='flex flex-row justify-end items-center'>
                    <div className="flex flex-row justify-end items-center gap-x-1">
                        <Input value={search} onChange={(e) => { setSearch(e.target.value) }} />
                        <Button className="flex flex-row gap-x-3" variant="contained">
                            <IoSearch />
                            <span>Rechercher</span>
                        </Button>
                    </div>
                </div>
            </div>
            <Card>
                <div className='flex flex-row justify-between items-center'>
                    <h4 className="mb-2">Secteur</h4>
                    <Path currentName='Secteur' />
                </div>
                <table className='w-full max-w-full '>
                    <thead>
                        <tr>
                            <th>NUMERO</th>
                            <th>NOM</th>
                            <th>LOGO</th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.filter((item) => (search ? item.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.nom}</td>
                                    <td>
                                        {
                                            item.logo != null ? (
                                                <img src={api + '/storage/' + item.logo} alt={item.nom} className='w-8 h-8 rounded-full' />
                                            ) : (
                                                <IoPersonCircleOutline className='w-8 h-8' />
                                            )
                                        }

                                    </td>

                                    {/* <status>{item.status}</status> */}
                                    <td className='flex flex-row justify-end items-center text-xl gap-x-3'>

                                        {/* <IoEye className='text-lg' onClick={() => { navigate(`/admin/detailsClient/${item.id}`) }} /> */}
                                        <IoCreate className='text-lg' onClick={() => { navigate(`/admin/modificationSecteur/${item.id}`) }} />
                                        <IoTrash className='text-lg' onClick={() => { handleOpen("delete"); setId(item.id) }} />
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </Card>
            <Modals isOpen={isOpen.delete} onClose={() => { handleClose("delete") }}>
                <div className='w-full flex flex-col gap-y-2'>
                    <h4>Suppression de secteur</h4>
                    <p>
                        Etes-vous sûr vouloir supprimer ce secteur? Cette action est irréversible.
                    </p>
                    <div className="flex flex-row justify-between items-center gap-x-2">
                        <Button fullWidth variant='outlined' onClick={() => { handleClose("delete") }}>
                            Annuler
                        </Button>
                        <Button color='error' fullWidth variant='contained' onClick={() => {
                            deleteCategorie()
                        }}>
                            <Load loading={options.loading} title='Oui,supprimer' />
                        </Button>

                    </div>
                </div>
            </Modals>
            <Feedback isOpen={isOpen.success} type='success' />
            <Feedback isOpen={isOpen.fails} type='error' />


        </div>
    )
}
