import React, { useEffect, useState } from 'react'
import Card from '../../../components/Card'
import profile from '../../../../assets/image/profile.png'
import GoBack from '../../../components/btn/goBack'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../api'
export default function DetailsClient() {
    const { id } = useParams()

    const [data, setData] = useState({
        id: id,
        nom: 'ARIANANA Maherison Jules',
        role: 'Client',
        age: 19,
        adresse: '123 rue des Martyrs, Paris',
        telephone: '0601020304',
        email: 'arianana.maherison@gmail.com',
        image: profile,
        province: 'Paris',
        region: 'Paris',
        district: 'Paris',
        rendezVousConfirme: 12,
        rendezVousTotal: 17,
        rendezVousAnnule: 5
    })
    const [rdvConf, setRdvConf] = useState([])
    const [rdvAnn, setRdvAnn] = useState([])
    const [rdvTot, setRdvTot] = useState([])

    function fetchData() {
        try {
            axios.get(api + '/api/getInfoClient/' + id)
                .then(response => setData(response.data))
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        } catch (error) {
            console.error(error);
        }    
    }

    function getClientRdvConf() {
        try {
            axios.get(api + '/api/getClientRdvConf/' + id)
                .then(response => setRdvConf(response.data))
                .catch(error => {
                    console.error('Error :', error);
                });
        } catch (error) {
            console.error(error);
        }    
    }

    function getClientRdvAnn() {
        try {
            axios.get(api + '/api/getClientRdvAnn/' + id)
                .then(response => setRdvAnn(response.data))
                .catch(error => {
                    console.error('Error :', error);
                });
        } catch (error) {
            console.error(error);
        }    
    }

    function getClientRdvTot() {
        try {
            axios.get(api + '/api/getClientRdvTot/' + id)
                .then(response => setRdvTot(response.data))
                .catch(error => {
                    console.error('Error :', error);
                });
        } catch (error) {
            console.error(error);
        }    
    }

    useEffect(() => {
        fetchData()
        getClientRdvConf()
        getClientRdvAnn()
        getClientRdvTot()
    }, [])
    return (
        <div className='w-full'>
            <Card>
                <div className="flex flex-col justify-start items-center gap-y-4 p-2">
                    <div className="w-full flex flex-row gap-x-3 justify-start items-center ">
                        <GoBack />
                        <span className="font-bold text-slate-400">Détails client</span>
                    </div>
                    <img src={api + '/storage/' + data.photo} className='w-[150px]' />
                    <div className='grid grid-cols-3 gap-8'>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Nom</span>
                            <span className="text-[#434966]  font-medium">{data.nom}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Rôle</span>
                            <span className="text-[#434966]  font-medium">{data.role}</span>
                        </div>
                        {/* <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Age</span>
                            <span className="text-[#434966]  font-medium">{data.age}</span>
                        </div> */}
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Numero de téléphone</span>
                            <span className="text-[#434966]  font-medium">{data.contact}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">E-mail</span>
                            <span className="text-[#434966]  font-medium">{data.email}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Adresse</span>
                            <span className="text-[#434966]  font-medium">{data.adresse}</span>
                        </div>
                        {/* <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Province</span>
                            <span className="text-[#434966]  font-medium">{data.province}</span>
                        </div> */}
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Région</span>
                            <span className="text-[#434966]  font-medium">{data.region}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">District</span>
                            <span className="text-[#434966]  font-medium">{data.district}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                           
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Rendez-vous confirmée</span>
                            <span className="text-[#434966]  font-medium">{rdvConf}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Rendez-vous annulée</span>
                            <span className="text-[#434966]  font-medium">{rdvAnn}</span>
                        </div>
                        <div className='flex flex-col items-start gap-y-1'>
                            <span className="text-xs text-slate-400">Rendez-vous total</span>
                            <span className="text-[#434966]  font-medium">{rdvTot}</span>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    )
}
