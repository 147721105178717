import React from 'react';

const CustomButton = ({ children, onClick, type = 'button', className = '', disabled=false }) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={!disabled?"bg-orange-500 hover:bg-orange-600 text-white px-6 py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-orange-300 text-sm ":"bg-gray-300 text-white px-6 py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-orange-300 text-sm "+` text-white px-6 py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-orange-300 text-sm ${className}`}
    >
      {children}
    </button>
  );
};

export default CustomButton;
