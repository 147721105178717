import React, { useEffect, useState } from 'react'
import ButtonBack from '../../components/Button/ButtonBack'
import Button from "../../components/Button"
import Input from "../../../component/Forms/Input"
import Textarea from "../../../component/Forms/Textarea"
import Select from "../../../component/Forms/Select"
import Status from '../../components/Status/Status'
import axios from 'axios'
import { useParams } from 'react-router-dom'
import { api } from '../../../api'
import ImagePicker from '../../components/ImagePicker'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import Feedback from '../../../admin/components/feedback'

const EditService = () => {
    const {id} = useParams()
    const [services, setServices] = useState({
        nom: '',
        description: '',
        photo: '',
        etat: '',
        adresse:'',
        secteur_id: 0,
    });
    const [isModified, setIsModified] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    });

    const [secteurs, setSecteurs] = useState([])

    function getAllSecteurs() {
        try {
            axios.get(api + '/api/allSecteurs')
                .then(res => {
                    setSecteurs(res.data);
                    // console.log(res.data)
                })
                .catch(err => {
                    console.error(err);
                })
        } catch (error) {
            console.error(error);
        }
    }

    // const api = 'http://your-api-url'; // Remplacez par l'URL de votre API

    // Récupérer les données du service existant
    useEffect(() => {
        axios.get(api + `/api/getDetailService/${id}`) // Remplacez `1` par l'ID du service que vous modifiez
            .then(res => {
                setServices(res.data);
            })
            .catch(err => {
                console.error(err);
            });

            getAllSecteurs()
    }, []);

    const handleChange = (e) => {
        setIsModified(true)
        const { name, value, files } = e.target;
        if (name === "photo" && files && files.length > 0) {
            setServices({
                ...services,
                [name]: files[0], // Stocker le fichier directement
            });
        } else {
            setServices({
                ...services,
                [name]: value,
            });
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
    
        const formData = new FormData();
        formData.append('nom', services.nom);
        formData.append('description', services.description);
        formData.append('secteur_id', services.secteur_id);
        formData.append('etat', services.etat);
        formData.append('adresse', services.adresse);
    
        if (services.photo) {
            formData.append('photo', services.photo);
        }
        // console.log(services)
        try {
            axios.post(api +`/api/modService/${id}`, formData)
                .then(res => {
                    setShowConfirmModal(false)
                    setIsOpen({ ...isOpen, success: true });
                }).catch((err)=>{
                    setShowConfirmModal(false)
                    setIsOpen({ ...isOpen, fails: true });
                })
        } catch (error) {
            setShowConfirmModal(false)
            setIsOpen({ ...isOpen, fails: true });
        }
    };

    return (
        <div className='px-6 mx-6'>
            <div className="flex justify-between items-center">
                <ButtonBack path={'/pro/services'} text={"Modifier un service"} />
                <Button onClick={() => setShowConfirmModal(true)} disabled={!isModified}>Enregistrer</Button>
            </div>
            <div className="grid grid-cols-6 gap-3 my-5">
                <div className="col-span-4 space-y-4">
                    <div className="border-line">
                        <Input
                            label={"Nom"}
                            name={"nom"}
                            className='w-full'
                            placeholder='Ajouter un nom...'
                            value={services.nom}
                            onChange={handleChange}
                        />
                        <Textarea
                            label={"Description"}
                            name="description"
                            id="description"
                            placeholder='Ecrire une description...'
                            value={services.description}
                            onChange={handleChange}
                        />
                        <Input
                            label={"Adresse"}
                            name={"adresse"}
                            className='w-full'
                            placeholder='Ajouter une adresse'
                            value={services.adresse}
                            onChange={handleChange}
                        />
                    </div>
                    {/* <div className="border-line">
                        <label htmlFor="image" className='text-sm font-medium text-gray-700 mb-1 text-start flex justify-start'>Photo</label>
                        <div className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
                            <input
                                // accept="*"
                                name={"photo"}
                                // onChange={(e) => setServices({ ...services, photo: e.target.files[0]})}
                                onChange={handleChange}
                                type="file"
                                // multiple
                                className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                                // Ajouter le traitement du fichier ici
                            />
                            <div className="flex flex-col items-center justify-center py-10 text-center">
                                <div className='bg-orange-400/10 flex items-center justify-center p-2 text-primary rounded-full'>
                                    <svg
                                        className="w-6 h-6  text-current-50"
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            strokeWidth="2"
                                            d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                        />
                                    </svg>
                                </div>
                                <p className="m-0">Glisser l’image ici ou cliquer sur Ajouter une image.</p>
                            </div>
                        </div>
                    </div> */}
                    
                    <ImagePicker 
    initialImage={services.photo ? api + "/storage/" + services.photo : null} 
    onImageChange={handleChange} 
/>
                </div>

                <div className="col-span-2 space-y-2">
                    <div className="border-line">
                        <h5 className='text-start mb-2 font-semibold text-md'>Secteur</h5>
                        <select
                            name="secteur_id"
                            // value={value}
                            onChange={handleChange}
                            required
                            className={`border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full`}
                        
                        >
                            {/* <option value="0">Séléctionner</option> */}
                            {secteurs.map((item, index) => (
                            <option key={index} value={item.id} selected={(item.id === services.secteur_id)} >
                                {item.nom}
                            </option>
                            ))}
                        </select>
                    </div>
                    <div className="border-line">
                        <div className="flex justify-between">
                            <h5 className='text-start mb-2 font-semibold text-md'>Etat</h5>
                            <div className={services.etat === "Publié" ? "status-success1" : "status-error1"} >{services.etat}</div>
                        </div>
                        {/* <Select
                        name={"etat"}
                            label={"Etat actuel"}
                            placeholder='Sélectionner un état...'
                            options={["Publié", "Non publié"]}
                            value={services.etat}
                            onChange={(value) => setServices({ ...services, etat: value })}
                        /> */}
                         <select 
                                name="etat"
                                value={services.etat}
                                onChange={handleChange}
                                className="mt-2 border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full"
                            >
                                {/* <option value="" disabled>Sélectionner un état...</option> */}
                                <option value="Publié">Publié</option>
                                <option value="Non publié">Non publié</option>
                            </select>
                    </div>
                </div>
            </div>

            <ConfirmModal 
                show={showConfirmModal} 
                onClose={() => setShowConfirmModal(false)}
                title="Confirmation"
                text="Êtes-vous sûr de vouloir enregistrer cette offre modifiée ?"
                buttonText="Enregistrer"
                action={handleSubmit}
            />

            {/* Feedback success or error */}
            <Feedback isOpen={isOpen.success} onClose={() => setIsOpen({ ...isOpen, success: false })} message='Modification réussie !' />
            <Feedback isOpen={isOpen.fails} onClose={() => setIsOpen({ ...isOpen, fails: false })} type='error' message='Une erreur est survenue !' />
        </div>
    );
};

export default EditService;