import React, { useEffect, useState } from "react"
import Heading from "../../../../components/Heading"
import CategoryCard from "./CategoryCard"
import { categories } from "../../../../Datas/Datas"
import { api } from "../../../../../api"
import axios from "axios"

const Categories = () => {

  const [secteur, setSecteur] = useState([])

  useEffect(() => {
    axios.get(api + `/api/allSecteurs`)
      .then(response => {
        setSecteur(response.data);
        console.log(response.data)
      })
      .catch(error => {
        console.error("Erreur lors de la récupération des secteurs:", error);
      })
      
  }, []);

  return (
    <section className='bg-gray-100 py-16'>
      <div className='container mx-auto px-4'>
      <Heading 
          title='Secteurs' 
          subtitle="Découvrez nos secteurs d'activités'"
        />

        <div className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6'>
        {secteur.map((item, index) => (
        <CategoryCard item={item} key={index}/>
        ))}
        </div>
      </div>
    </section>
  )
}

export default Categories
