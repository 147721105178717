import React, { useEffect, useState } from 'react';
import logo from '../../../assets/image/logo.png';
import { IoChevronForward, IoHome, IoNotifications, IoPerson } from 'react-icons/io5';
import { MdSubscriptions, MdBusiness } from 'react-icons/md'
import { FaIdCard, FaTableList } from "react-icons/fa6";
import { FaUser } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import Grow from '@mui/material/Grow';
import { BiLogOut } from 'react-icons/bi';

export default function Sidebar() {
    const [list, setList] = useState([
        {
            logo: <IoHome />,
            title: "Tableau de bord",
            path: '/admin'
        }, {
            logo: <FaUser />,
            title: "Utilisateur",

            show_child: false,
            child: [
                {
                    logo: <IoChevronForward />,
                    title: "Client",
                    path: '/admin/client'
                },
                {
                    logo: <IoChevronForward />,
                    title: "Prestataire",
                    path: '/admin/prestataire'
                },
                {
                    logo: <IoChevronForward />,
                    title: "Administrateur",
                    path: '/admin/administrateur'
                },
            ]
        },
        {
            logo: <FaTableList />,
            title: "Service",
            path: '/admin/service/0'
        },
        {
            logo: <MdSubscriptions />,
            title: "Abonnement",
            path: '/admin/abonnement/0'
        },
        {
            logo: <MdBusiness />,
            title: "Secteur",
            path: '/admin/secteur'
        },
        {
            logo: <FaIdCard />,
            title: "Profil",
            path: '/admin/profile'
        },

        // {
        //     logo: <IoNotifications />,
        //     title: "Notification",
        //     path: '/admin/notification'
        // },

    ])
    const location = useLocation();
    const navigate = useNavigate();
    function logout() {
        localStorage.removeItem('soro-id');
        localStorage.removeItem('soro-photo');
        localStorage.removeItem('soro-email');
        localStorage.removeItem('soro-role');
        navigate('/login');
    }
    const toggleChild = (index) => {
        const updatedList = [...list];
        updatedList[index].show_child = !updatedList[index].show_child;
        setList(updatedList);
    };

    useEffect(() => {
        console.log(location.pathname);
    }, []);

    return (
        <div className='w-full p-6   h-full overflow-y-hidden'>
            <img src={logo} className="w-[138px] mb-4" />
            <div className='flex flex-col justify-between w-full h-full'>
                <ul className='flex flex-col w-full '>
                    {list.map((item, index) => (
                        <li key={index}>
                            <div className='flex flex-row justify-between items-center w-full' onClick={() => {
                                if (item?.child) {
                                    toggleChild(index);
                                } else {
                                    navigate(item.path);
                                }
                            }}>
                                <div
                                    className={`${location.pathname === item.path
                                        ? 'border-r-4 bg-primary/10 py-1 border-primary text-primary'
                                        : ''
                                        } my-1 px-2 gap-x-2 hover:cursor-pointer flex flex-row w-full justify-start items-center py-2`}
                                >
                                    <span>{item.logo}</span>
                                    <span>{item.title}</span>
                                </div>
                                {
                                    item?.child && (
                                        <IoChevronForward className={`ml-auto  hover:text-primary ${item.show_child ? '-rotate-90' : 'rotate-90'}`} />
                                    )
                                }
                            </div>
                            {item.show_child && (
                                <ul className=' bg-primary/10'>
                                    {item.child.map((child, childIndex) => (
                                        <li onClick={() => navigate(child.path)} key={childIndex} className={`${location.pathname === child.path
                                            ? 'border-r-4 bg-primary/10 border-primary text-primary'
                                            : ''
                                            }  px-2 gap-x-2 hover:cursor-pointer flex flex-row w-full justify-start items-center py-1`}
                                        >

                                            <div
                                                className={`my-1 px-2 gap-x-2 hover:cursor-pointer flex flex-row justify-start items-center py-1`}

                                            >
                                                <span>{child.logo}</span>
                                                <span>{child.title}</span>
                                            </div>

                                        </li>
                                    ))}
                                </ul>
                            )}
                        </li>
                    ))}

                    <li onClick={() => { logout() }} className='flex flex-row justify-start hover:cursor-pointer pl-2 items-center w-full gap-x-1'>
                        <BiLogOut />
                        <span>Se déconnecter</span>
                    </li>
                </ul>

            </div>
        </div>
    );
}