import Img1 from "../assets/images/serv1.png"
import Img2 from "../assets/images/serv1.png"
import Img3 from "../assets/images/serv1.png"
import Img4 from "../assets/images/serv1.png"
import Img5 from "../assets/images/serv1.png"
import Img6 from "../assets/images/serv1.png"

// import image1 from "../assets/images/h1.png"
import prestataire1 from "../assets/images/prestataire1.png"
// import image2 from "../assets/images/h2.png"
// import image3 from "../assets/images/h3.png"
// import image4 from "../assets/images/h4.png"

import categ1 from "../assets/icons/categ1.png"
import categ2 from "../assets/icons/categ2.png"

import testi from "../assets/images/testimonialimage.png"


export const offreData = {
  "title": "Make-up",
  "rating": 4.5,
  "isOpen": false,
  "nextOpenTime": "19:00",
  "reservations": 12,
  "location": {
    "address": "Lot 13 IBM Antarandolo Fianarantsoa",
    "directionsLink": "https://www.google.com/maps?q=Lot+13+IBM+Antarandolo+Fianarantsoa"
  },
  "openingHours": [
    {
      "days": "Lundi - Vendredi",
      "time": "09:00 -- 17:00"
    },
    {
      "days": "Samedi - Dimanche",
      "time": "Fermé"
    }
  ],
  "description": "Transformez votre look avec notre service de maquillage professionnel. Que ce soit pour une soirée spéciale, un événement formel ou simplement pour vous sentir au mieux, notre équipe de maquilleurs experts utilise des produits de haute qualité pour créer des looks personnalisés qui mettent en valeur votre beauté naturelle. Profitez de notre consultation préalable pour discuter de vos préférences, suivez les conseils professionnels sur le choix des produits adaptés à votre type de peau, et bénéficiez d’une application de maquillage qui dure toute la journée. Nous offrons également des forfaits pour les mariages et les séances photo."
}

export const serviceDetail =  {
  "id": 1,
  "titre": "Croisière de Luxe aux Caraïbes",
  "dateCreation": "2023-08-10T10:30:00Z",
  "description": "Une croisière inoubliable à travers les îles des Caraïbes avec un service tout compris.",
  "lieu": "Caraïbes",
  "idPrestataire": "Prestataire Maritime International",
  "contactEmail": "contact@maritime-international.com",
  "contactTel": "+1234567890",
  "slogan": "Service de qualité pour une expérience inoubliable."
}

export const offerByIdService = [
{
  "id": 101,
  "datePublication": "2023-08-15T09:00:00Z",
  "titre": "Offre spéciale: Croisière 7 jours",
  "prix": 2999,
  "lieu": "Caraïbes",
  "responsable": "John Doe"
},
{
  "id": 102,
  "datePublication": "2023-08-18T11:00:00Z",
  "titre": "Croisière de Luxe 14 jours",
  "prix": 5999,
  "lieu": "Caraïbes",
  "responsable": "Jane Smith"
},
{
  "id": 103,
  "datePublication": "2023-08-20T08:00:00Z",
  "titre": "Escapade Week-end en Voilier",
  "prix": 1499,
  "lieu": "Caraïbes",
  "responsable": "Alan Walker"
}
]


export const prestataires =
[
  {
    "id": 1,
    "image": prestataire1,
    "name": "Dr. Shantanu Jambhekar",
    "profession": "Dentist",
    "experience": "16",
    "location": "Cardiology Center, USA",
    "description": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Similique quas rerum est nisi quo laboriosam earum iste consectetur illo eaquee",
    "rating": "5.0",
    "votes": "1,872"
  },
  {
    "id": 2,
    "image": prestataire1,
    "name": "Dr. Jane Doe",
    "profession": "Cardiologist",
    "experience": "10",
    "location": "Heart Clinic, UK",
    "description": "Expert in cardiology with over a decade of experience.",
    "rating": "4.8",
    "votes": "1,245"
  },
  {
    "id": 3,
    "image": prestataire1,
    "name": "Dr. Jane Doe",
    "profession": "Cardiologist",
    "experience": "10",
    "location": "Heart Clinic, UK",
    "description": "Expert in cardiology with over a decade of experience.",
    "rating": "4.8",
    "votes": "1,245"
  },
  {
    "id": 4,
    "image": prestataire1,
    "name": "Dr. Jane Doe",
    "profession": "Cardiologist",
    "experience": "10",
    "location": "Heart Clinic, UK",
    "description": "Expert in cardiology with over a decade of experience.",
    "rating": "4.8",
    "votes": "1,245"
  }
]


export const testimonials = [
  {
    id: 1,
    name: 'John Doe',
    jobTitle: 'Ingénieur logiciel',
    message: 'C\'est un produit incroyable ! Il m\'a énormément aidé dans mon travail.',
    image: testi,
  },
  {
    id: 2,
    name: 'Jane Smith',
    jobTitle: 'Chef de produit',
    message: 'Service et support excellents. Hautement recommandé !',
    image: testi,
  },
  {
    id: 3,
    name: 'Robert Brown',
    jobTitle: 'Développeur web',
    message: 'Une expérience utilisateur fantastique. Très satisfait !',
    image: testi,
  },
  {
    id: 4,
    name: 'Robert Brown',
    jobTitle: 'Développeur web',
    message: 'Une expérience utilisateur fantastique. Très satisfait !',
    image: testi,
  },
  {
    id: 5,
    name: 'Robert Brown',
    jobTitle: 'Développeur web',
    message: 'Une expérience utilisateur fantastique. Très satisfait !',
    image: testi,
  },
  // Ajoutez d'autres témoignages selon vos besoins
];


// export const categories = [
//   {
//     cover: image1,
//     name: "Family House",
//     total: "122 Property",
//   },
//   {
//     cover: image2,
//     name: "House & Villa",
//     total: "155 Property",
//   },
//   {
//     cover: image3,
//     name: "Apartment",
//     total: "300 Property",
//   },
//   {
//     cover: image4,
//     name: "Office & Studio",
//     total: "80 Property",
//   },
//   {
//     cover: image4,
//     name: "Villa & Condo",
//     total: "80 Property",
//   },
// ]
export const categories = [
  {
    "cover": categ1,
    "name": "Médecine et Santé"
  },
  {
    "cover": categ2,
    "name": "Beauté et Bien-Être"
  },
  {
    "cover": categ2,
    "name": "Services Professionnels"
  },
  {
    "cover": categ1,
    "name": "Éducation et Formation"
  },
  {
    "cover": categ2,
    "name": "Services Locaux"
  }
]


export const ServiceData = [
  {
    img: Img1,
    title: "Boat",
    location: "USA",
    description: "lorem ipsum dolor sit amet consectetur adipisicing elit.",
    price: 6700,
    type: "Cultural Relax",
  },
  {
    img: Img2,
    title: "Taj Mahal",
    location: "India",
    description:
      "The Taj Mahal is an ivory-white marble mausoleum on the south bank of the river Yamuna in the Indian city of Agra.",
    price: 6700,
    type: "Cultural Relax",
  },
  {
    img: Img3,
    title: "Underwater",
    location: "US",
    description:
      "The Taj Mahal is an ivory-white marble mausoleum on the south bank of the river Yamuna in the Indian city of Agra.",
    price: 6200,
    type: "Cultural Relax",
  },
  {
    img: Img4,
    title: "Sydney",
    location: "USA",
    description: "lorem ipsum dolor sit amet consectetur adipisicing elit.",
    price: 6700,
    type: "Cultural Relax",
  },
  {
    img: Img5,
    title: "Los Angeles",
    location: "United states",
    description:
      "The Taj Mahal is an ivory-white marble mausoleum on the south bank of the river Yamuna in the Indian city of Agra.",
    price: 6700,
    type: "Cultural Relax",
  },
  {
    img: Img6,
    title: "Los Vegas",
    location: "California",
    description:
      "The Taj Mahal is an ivory-white marble mausoleum on the south bank of the river Yamuna in the Indian city of Agra.",
    price: 6200,
    type: "Cultural Relax",
  },
];
export const price = [
    {
      plan: "Basic",
      price: "29",
      ptext: "per user, per month",
      list: [
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "99.5% Uptime Guarantee",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "120GB CDN Bandwidth",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "5GB Cloud Storage",
        },
        { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Personal Help Support" },
        { change: "color", icon: <i class='fa-solid fa-x'></i>, text: "Enterprise SLA" },
      ],
    },
    {
      best: "Best Value",
      plan: "Standard",
      price: "49",
      ptext: "per user, per month",
      list: [
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "99.5% Uptime Guarantee",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "150GB CDN Bandwidth",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "10GB Cloud Storage",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "Personal Help Support",
        },
        {
          change: "color",
          icon: <i class='fa-solid fa-x'></i>,
          text: "Enterprise SLA",
        },
      ],
    },
    {
      plan: "Platinum",
      price: "79",
      ptext: "2 user, per month",
      list: [
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "100% Uptime Guarantee",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "200GB CDN Bandwidth",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "20GB Cloud Storage",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "Personal Help Support",
        },
        {
          icon: <i class='fa-solid fa-check'></i>,
          text: "Enterprise SLA",
        },
      ],
    },
  ]