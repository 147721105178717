
import React, {useState, useEffect} from 'react'
import Calendar1 from "../Calendar/Calendar1"
import Slots from "../Slot/Slots"
import Button from "../Button"
import axios from 'axios'
import { api } from '../../../api'
import Modal from './Modal'
import { useParams } from 'react-router-dom'

const AppointmentModal = ({ title, show, onClose, onSave, children, okOption=false, idOffer, idService }) => {
    const [offer, setOffer] = useState([])
    const [timeSelected, setTimeSelected] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [hourSelected, setHourSelected] = useState(null);
    const [minuteSelected, setMinuteSelected] = useState(null)
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number);
        // Obtention de la date courante
        const today = new Date();
        const day = addLeadingZero(today.getDate());
        const month = addLeadingZero(today.getMonth() + 1); // Les mois commencent à 0 en JavaScript, donc on ajoute 1
        const year = today.getFullYear();
    
        // Initialisation de selectedDate avec la date courante au format "DD-MM-YYYY
      
        const [dateSelected, setDateSelected] = useState(`${day}-${month}-${year}`)
        const {id}= useParams()

        const formatDate = (dateString) => {
          const [day, month, year] = dateString.split('-');
          return `${year}-${month}-${day}`;
      };
    
    

    useEffect(() => {
      // axios.get(api+"/api/getJourOuvrable/"+idService)
      // .then((res)=>setOffer(res.data))
      // .catch((error)=>console.log(error))
      // axios.get(api+"/api/getJourOuvrable/"+idService)
      // .then((res)=>setOffer(res.data))
      // .catch((error)=>console.log(error))

      axios.get(api + `/api/getOffreJourHeureOuvrable/${idService}`)
      .then(response => {
        setOffer(response.data)
      })
      .catch(error => console.error('Erreur lors de la récupération des offres:', error))
    })

    if (!show) {
      return null
    }

    const modRdv = () => {

      const formattedDate = formatDate(dateSelected);
      const userId = parseInt(localStorage.getItem("soro-id"));


      const dataForm = new FormData()
      dataForm.append('date', formattedDate)
      dataForm.append('heure', hourSelected)
      dataForm.append('minute', minuteSelected)

      // api pour prendre un rdv ; id: idOffre
      axios.post(api + `/api/modRdv/${id}`, dataForm)
            .then(response => {
              // Traiter la réponse si tout se passe bien
              console.log('Rendez-vous modifié avec succès')
            })
            .catch(error => console.error('Erreur lors de la récupération des offres:', error))
    }

    

  return (

    
    <div className={"fixed inset-0 z-[500] flex items-center justify-center overflow-auto bg-gray-900/20 bg-opacity-50"}>

      <div className="bg-white max-w-lg mx-auto shadow-lg absolute right-0 top-0 bottom-0 w-full md:w-[450px] md:rounded-l-md">
        {/* Modal Header */}
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h3 className="text-lg font-semibold text-gray-900">Modifier un rendez-vous</h3>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 focus:outline-none"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        {/* Modal Body */}
        <div className="">
          <div className=" xl:col-span-5 lg:col-span-5 text-start col-span-12 bg-white px-4 py-4">
            <Calendar1 setDateSelected={setDateSelected} openingDays={{
                "Dim": offer?.service?.jour_ouvrable?.dimanche,
                "Lun": offer?.service?.jour_ouvrable?.lundi,
                "Mar": offer?.service?.jour_ouvrable?.mardi,
                "Mer": offer?.service?.jour_ouvrable?.mercredi,
                "Jeu": offer?.service?.jour_ouvrable?.jeudi,
                "Ven": offer?.service?.jour_ouvrable?.vendredi,
                "Sam": offer?.service?.jour_ouvrable?.samedi
            }}/>
            <p className='pt-10 p-5'>Seléctionner une heure</p>
            <Slots
              dateSelected={dateSelected}
              openingHour={offer?.service?.heure_ouvrable?.heure_ouv}
              openingMinute={offer?.service?.heure_ouvrable?.min_ouv}
              breakStartHour={offer?.service?.heure_ouvrable?.heure_debut_pause}
              breakStartMinute={offer?.service?.heure_ouvrable?.min_debut_pause}
              breakEndHour={offer?.service?.heure_ouvrable?.heure_fin_pause}
              breakEndMinute={offer?.service?.heure_ouvrable?.min_fin_pause}
              closingHour={offer?.service?.heure_ouvrable?.heure_ferm}
              closingMinute={offer?.service?.heure_ouvrable?.min_ferm}
              intervalHour={offer.duree_heure}
              intervalMinute={offer.duree_minute}
              setTimeSelected={setTimeSelected}
              setHourSelected={setHourSelected}
              setMinuteSelected={setMinuteSelected}
              textSize="text-lg"          // Taille du texte des boutons de créneaux
            />
            {/* {hourSelected +" "+minuteSelected+" "+timeSelected} */}
            <button className='bg-black-900 text-white w-full rounded-md py-3' onClick={()=>{setShowConfirmModal((dateSelected !== null && timeSelected !==null)?true:false)
            }}>Réserver maintenant</button>
        </div>
        </div>

        {showConfirmModal && 
      <Modal width="500px" setShowModal={setShowConfirmModal}>
            <div className="sm:flex sm:items-start">
                <div
                    className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                    <svg className="h-6 w-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none"
                        viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 className="text-lg leading-6 font-medium" id="modal-headline">
                        Confirmation de RDV
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">
                            Voulez-vous sauvegarder les modifications ? <br /> Date seléctionnée: <span className='b font-bold'>{dateSelected} à {timeSelected}</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                onClick={()=>{
                  modRdv()
                  setShowConfirmModal(false)
                }
                }
                
                type="button" data-behavior="commit" className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                    Enregister
                </button>
                <button
                onClick={()=>setShowConfirmModal(false)}
                type="button" data-behavior="cancel" className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm">
                    Annuler
                </button>
            </div>
      </Modal>}
      </div>
    </div>
  );
};

export default AppointmentModal
