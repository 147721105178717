import React, { useState } from "react";
import Logo from "../assets/logo/logo.png";
import { NavLink, Link, useLocation } from "react-router-dom"
import ResponsiveMenu from "./ResponsiveMenu"
import bars from "../assets/icons/bars-svgrepo-com.svg"
import AccountDropdown from "./Dropdown/AccountDropdown";

export const NavbarLinks = [
  {
    name: "Accueil",
    link: "/",
    level:"public"
  },
  {
    name: "Service",
    link: "/service",
    level:"public"
  },
  {
    name: "Agence",
    link: "/prestataire",
    level:"public"
  },
  {
    name: "Mes réservations",
    link: "/reservation",
    level:"private"
  },
];


const Navbar = ({ handleOrderPopup,isAuthenticated }) => {
  const [showMenu, setShowMenu] = useState(false);
  const location = useLocation()
  // const [isAuthenticated, setisAuthenticated] = useState(localStorage.getItem("natura_germe_id"))

// useLayoutEffect(() => {
//   first

// }, [isAuthenticated])

  const isActive = (path) => {
    if (path === location.pathname) {
      return true;
    }
    return false;
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu);
  }
  return (
    <>
      <nav className="fixed top-0 right-0 w-full z-50 text-black text-black-900 bg-white shadow-md selection:bg-orange-300/80 selection:text-white">

        <div className="bg-gradient-to-r from-primary to-secondary text-white ">
        </div>
        <div className="w-full py-4 md:py-0  px-10 sm:px-12 md:px-28 bg-white ">
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-4  font-bold text-2xl">
                {/* Mobile Hamburger icon */}
                <div className="md:hidden block">
                {showMenu ? (
                  <span
                    onClick={toggleMenu}
                    className=" cursor-pointer transition-all"
                    size={30}
                    >
                      <img src={bars} alt="" className="w-10 h-10"/>
                    </span>
                ) : (
                  <span
                    onClick={toggleMenu}
                    className="cursor-pointer transition-all"
                    size={30}
                  >
                    <img src={bars} alt="" className="w-10 h-10"/>
                  </span>
                )}
              </div>
              <Link to={"/"} onClick={() => window.scrollTo(0, 0)}>
                <img src={Logo} alt="" className="h-10 w-20" />
              </Link>
            </div>
            <div className="hidden md:block">
              <ul className="flex items-center gap-6 font-semibold">
{/* className={'py-2 sm:py-6 px-4 border-b-[3px]  ${ isActive(item.link) ? "text-primary" : "text-black-900" }  border-b-primary'} */}
              {NavbarLinks.map((item, key) =>{
                return (item.level === "private") ? (isAuthenticated &&<li 
                        className={`py-2 sm:py-6 px-4 border-b-[3px] ${
                        isActive(item.link) ? "border-b-primary" : "border-b-white"}`} 
                        key={key}>
                          <NavLink 
                          to={item.link}
                          activeClassName="active" 
                          className={`link-item ${
                          isActive(item.link)
                            ? "text-primary"
                            : "text-black-900"
                          }`}>
                          {item.name}
                          </NavLink>
                        </li> ): <li 
                        className={`py-2 sm:py-6 px-4 border-b-[3px] ${
                        isActive(item.link) ? "border-b-primary" : "border-b-white"}`} 
                        key={key}>
                          <NavLink 
                          to={item.link}
                          activeClassName="active" 
                          className={`link-item ${
                          isActive(item.link)
                            ? "text-primary"
                            : "text-black-900"
                          }`}>
                          {item.name}
                          </NavLink>
                        </li> 
              })}
              </ul>
            </div>
            <div className="flex items-center justify-center gap-4 pl-0 sm:pl-10">
            {isAuthenticated ? 
            <AccountDropdown/>
            :
            <Link
                className="bg-primary hover:bg-bg-gradient-to-r hover:from-secondary hover:bg-primary transition-all duration-600 text-white px-4 py-2 rounded-full"
                to='/login'
              >
                Se connecter
              </Link>
          }
            
              
            
            </div>
          </div>
        </div>
        <ResponsiveMenu setShowMenu={setShowMenu} showMenu={showMenu} />
      </nav>
    </>
  );
};

export default Navbar;
