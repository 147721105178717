import React, { useEffect, useState } from 'react';
import ButtonBack from '../../components/Button/ButtonBack';
import Button from "../../components/Button";
import Input from "../../../component/Forms/Input";
import Textarea from "../../../component/Forms/Textarea";
import Select from "../../../component/Forms/Select";
import Status from '../../components/Status/Status';
import ConfirmModal from '../../components/Modal/ConfirmModal'; // Assurez-vous d'importer votre modal de confirmation
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { api } from '../../../api'
import Feedback from '../../../admin/components/feedback'
import ImagePicker from '../../components/ImagePicker';

const EditOffer = () => {
    const { id, id1 } = useParams();
    const [offer, setOffer] = useState({
        nom: '',
        description: '',
        prix: '',
        duree_heure: '',
        duree_minute: '',
        photo: '',
        status: 'Publié'
    });
    const [isModified, setIsModified] = useState(false);
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false
    });

    useEffect(() => {
        const fetchOffer = async () => {
            try {
                const response = await axios.get(api + `/api/getOffre/${id1}`);
                setOffer(response.data);
            } catch (err) {
                console.error(err);
            }
        };

        fetchOffer();
    }, [id1]);

    const handleSubmit = async () => {
        const formData = new FormData();
        formData.append('nom', offer.nom);
        formData.append('prix', offer.prix);
        formData.append('description', offer.description);
        formData.append('duree_heure', offer.duree_heure);
        formData.append('duree_minute', offer.duree_minute);
        formData.append('status', offer.status);

        if (offer.photo) {
            formData.append('photo', offer.photo);
        }
        console.log(offer)

        try {
            const response = await axios.post(api + `/api/modOffre/${id1}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            console.log('Offre mise à jour avec succès', response.data)
            setShowConfirmModal(false)
            setIsOpen({ ...isOpen, success: true });
        } catch (error) {
            console.error('Erreur lors de la mise à jour de l\'offre:', error)
            setShowConfirmModal(false)
            setIsOpen({ ...isOpen, fails: true });
        }
    }

    const handleChange = (e) => {
        setIsModified(true)
        const { name, value, files } = e.target;
        // setIsModified(true);

        if (name === "photo" && files && files.length > 0) {
            setOffer({
                ...offer,
                [name]: files[0], // Stocker le fichier directement
            });
        } else {
            setOffer({
                ...offer,
                [name]: value,
            });
        }
    };

    return (
        <div className='px-6 mx-6'>
            <div className="flex justify-between items-center">
                <ButtonBack path={`/pro/service/${id}/offers`} text={"Modifier une offre"} />
                <Button onClick={() => setShowConfirmModal(true)} disabled={!isModified}>Enregistrer</Button>
            </div>
            <div className="grid grid-cols-6 gap-3 my-5">
                <div className="col-span-4 space-y-4">
                    <div className="border-line">
                        <Input 
                            label={"Nom"} 
                            name={"nom"} 
                            value={offer.nom} 
                            onChange={handleChange} 
                            className='w-full' 
                            placeholder='Ajouter un nom...' 
                        />
                        <Textarea 
                            label={"Description"} 
                            name="description" 
                            value={offer.description} 
                            onChange={handleChange} 
                            id="description" 
                            placeholder='Ecrire une description...' 
                        />
                    </div> 
                    {/* <div className="border-line">
                        <label htmlFor="image" className='text-sm font-medium text-gray-700 mb-1 text-start flex justify-start'>Photo</label>
                        <div className="relative flex flex-col text-gray-400 border border-gray-200 border-dashed rounded cursor-pointer">
                            <input
                                name="photo"
                                onChange={handleChange}
                                type="file"
                                className="absolute inset-0 z-50 w-full h-full p-0 m-0 outline-none opacity-0 cursor-pointer"
                            />
                            {offer.photo ? (
                                <div className="w-full h-full flex-col flex items-center justify-center py-4 gap-y-2">
                                <img src={api+"/storage/"+offer.photo} alt="Offer" className="object-cover w-52 h-52 " />
                                <p className="m-0">Glisser l’image ici ou cliquer pour modifier.</p>
                                </div>
                            ) : (
                                <div className="flex flex-col items-center justify-center py-10 text-center">
                                    <div className='bg-orange-400/10 flex items-center justify-center p-2 text-primary rounded-full'>
                                        <svg
                                            className="w-6 h-6 text-current-50"
                                            xmlns="http://www.w3.org/2000/svg"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                            stroke="currentColor"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M4 16l4.586-4.586a2 2 0 012.828 0L16 16m-2-2l1.586-1.586a2 2 0 012.828 0L20 14m-6-6h.01M6 20h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
                                            />
                                        </svg>
                                    </div>
                                    <p className="m-0">Glisser l’image ici ou cliquer sur Ajouter une image.</p>
                                </div>
                            )}
                        </div>
                    </div> */}
                    <ImagePicker 
    initialImage={offer.photo ? api + "/storage/" + offer.photo : null} 
    onImageChange={handleChange} 
/>
                    <div className="border-line">
                        <Input 
                            label={"Prix (Ar)"} 
                            name={"prix"} 
                            value={offer.prix} 
                            onChange={handleChange} 
                            className='w-full' type='number'
                        />
                        <Input 
                            label={"Durée en heure"} 
                            name={"duree_heure"} 
                            value={offer.duree_heure} 
                            onChange={handleChange} 
                            className='w-full' type='number'
                        />
                        <Input 
                            label={"Durée en minute"} 
                            name={"duree_minute"} 
                            value={offer.duree_minute} 
                            onChange={handleChange} 
                            className='w-full' 
                            type='number'
                        />
                    </div>
                </div>   

                <div className="col-span-2 space-y-2">
                    <div className="border-line">
                        <div className="flex justify-between">
                            <h5 className=' text-start mb-2 font-semibold text-md'>Etat</h5>
                            {/* <Status text={offer.status === "Publié" ? "Publié" : "Non publié"} variant={offer.status === "Publié" ? "success" : "warning"} /> */}
                            <div className={offer.status === "Publié" ? "status-success1" : "status-error1"} >{offer.status}</div>
                        </div>
                        <div className="mb-4">
                            <label
                                htmlFor="status"
                                className="block text-sm font-medium text-gray-700 mb-1 text-start"
                            >
                                État actuel
                            </label>
                            <select
                                name="status"
                                value={offer.status}
                                onChange={handleChange}
                                className="border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full"
                            >
                                {/* <option value="" disabled>Sélectionner un état...</option> */}
                                <option value="Publié">Publié</option>
                                <option value="Non publié">Non publié</option>
                            </select>
                        </div>
                    </div>    
                </div>  
            </div>

            {/* Modal de confirmation */}
            <ConfirmModal 
                show={showConfirmModal} 
                onClose={() => setShowConfirmModal(false)}
                title="Confirmation"
                text="Êtes-vous sûr de vouloir enregistrer cette offre modifiée ?"
                buttonText="Enregistrer"
                action={handleSubmit}
            />

            {/* Feedback success or error */}
            <Feedback isOpen={isOpen.success} onClose={() => setIsOpen({ ...isOpen, success: false })} message='Modification réussie !' />
            <Feedback isOpen={isOpen.fails} onClose={() => setIsOpen({ ...isOpen, fails: false })} type='error' message='Une erreur est survenue !' />
        </div>
    );
}

export default EditOffer;
