import React from 'react';

const ButtonOutline = ({ 
  children, 
  onClick, 
  type = 'button', 
  className = '', 
  icon: Icon, 
  iconPosition = 'left' // 'left' or 'right'
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={`flex items-center justify-center px-6 py-2 border border-black-900 text-black-900 rounded-md hover:bg-black-900 hover:text-white transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-black-900 text-sm ${className}`}
    >
      {iconPosition === 'left' && Icon && (
        <Icon className="mr-2" />
      )}
      {children}
      {iconPosition === 'right' && Icon && (
        <Icon className="ml-2" />
      )}
    </button>
  );
};

export default ButtonOutline
