// import React from 'react'
import Slots from '../../components/Slot/Slots'

import React, { useState } from 'react'
// import SlotSelection from './SlotSelection';

const ParentComponent = () => {
  const [timeSelected, setTimeSelected] = useState(null)
  const [hourSelected, setHourSelected] = useState(null)
  const [minuteSelected, setMinuteSelected] = useState(null)

  return (
    <div>
      <Slots
        openingHour={9}
        openingMinute={0}
        breakStartHour={12}
        breakStartMinute={0}
        breakEndHour={13}
        breakEndMinute={0}
        closingHour={17}
        closingMinute={0}
        // interval={30}
        setTimeSelected={setTimeSelected}
        setHourSelected={setHourSelected}
        setMinuteSelected={setMinuteSelected}
        intervalHour={0}
        intervalMinute={40}
      />

      {/* Affichage de l'heure et de la minute sélectionnées */}
      <div>
        <p>Heure sélectionnée: {timeSelected}</p>
        <p>Heure choisie: {hourSelected}</p>
        <p>Minute choisie: {minuteSelected}</p>
      </div>
    </div>
  );
};

export default ParentComponent;
