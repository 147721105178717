import React, { useState } from 'react'
import Modal from '../Modal/Modal'
import { api } from '../../../api'

const ModalServiceDetail  = ({show, onClose, item}) => {
    const [timeSelected, setTimeSelected] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [showViewModal, setShowViewModal] = useState(false)

if (!show) return null

  return (
    <div>
       <Modal onClose={()=>onClose(false)} show={show} title={"Information sur l'abonnement"} okOption={true}>
      <div class=" border-gray-200 px-4 py-5 sm:p-0">
        <img src={api+"/storage/"+item.photo} alt="" className='rounded-full w-32 h-32'/>
        <dl class="sm:dvide-y sm:divide-gray-200">
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Nom
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {item.nom}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Description
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.description}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Adresse
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.adresse}
                </dd>
            </div>
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Etat
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.etat}
                </dd>
            </div>
           
            <div class="py-3 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt class="text-sm font-medium text-gray-500">
                    Date de création
                </dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {item.created_at}
                </dd>
            </div>
          
        </dl>
      </div>
    </Modal>
       </div>
  )
}

export default ModalServiceDetail 