import React from "react";
import user from "../../../website/assets/icons/user.png"
import heart from "../../../website/assets/icons/heart.png"
import localisation from "../../../website/assets/icons/localisation.png"
import starfull from "../../../website/assets/icons/fullstar.png"
import staroutline from "../../../website/assets/icons/staroutline.png"
import imageError from "../../../website/assets/images/pexels-creationsbyfaik-1025469.jpg"
import DropdownMenu from "../Dropdown/DropdownMenu"
import { api } from "../../../api"
import TruncateText from "../../../website/components/TruncateText";

const ServiceCard = ({
  id,
  photo,
  nom,
  description,
  etat,
  secteur,
  handleOrderPopup, serviceData
}) => {
  const isPlanValid = localStorage.getItem("soro-abonnement-valid")

  return (
    <div
      className="relative group shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer rounded-t-md text-black-900"
      onClick={handleOrderPopup}
    >
      <div className=" overflow- rounded-t-md">
        <div className="absolute top-4 right-4  transition-opacity duration-300 bg-white rounded-md border">
        {isPlanValid && <DropdownMenu idSelected={id} serviceSelected={serviceData}/>}
        </div>

        {/* Image */}
        <img
          src={api + '/storage/' + photo}
          alt="No image"
          onError={(e) => { e.target.onerror = null; e.target.src = imageError; }}
          className="mx-auto h-[220px] w-full object-cover rounded-t-md"
        />
      </div>

      <div className="space-y-2 p-3 rounded-b-md">
        <h1 className="line-clamp-1 font-bold text-lg text-start">{nom}</h1>
        <div className="flex items-center gap-2 opacity-70">
          {/* <img src={localisation} alt="place" className="w-5 h-5"/> */}
          <TruncateText className='text-sm' opacityLevel={100}  maxChars={50} >{description}</TruncateText>
        </div>
        {/* <div className=" flex items-center gap-2"><p>5.0</p>
        <p className='flex gap-2'>
          <img src={starfull} alt="s" className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={staroutline} alt="s" className=" w-4 h-4" /></p>
        <p>(58 Votes)</p>                      
                              </div> */}
        <div className="flex items-center justify-between border-t-2 py-3 !mt-3">
        <div className="opacity-70">
            <p className="flex gap-2 items-center text-sm">
              <img src={api+"/storage/"+secteur?.logo} alt="User" className="w-5 h-5" />
              {/* Nom du secteur */}
              {secteur?.nom}              
            </p>
          </div>
          <div>
            <div className={etat === "Publié" ? "status-success1" : "status-error1"} >{etat}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard