import React, { useEffect, useState } from 'react'
import Calendar from '../../../website/components/Calendar'
import SlotSelection from '../../../website/components/Slot/SlotSelection'
import Select from '../../../component/Forms/Select'
import ConfirmModal from './ConfirmModal'
import axios from 'axios'
import { api } from '../../../api'

const AddAppointementModal = ({ show, onClose }) => {
    const [timeSelected, setTimeSelected] = useState(null)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [users, setUsers] = useState([])
    const [offers, setOffers] = useState([])
    const [hourSelected, setHourSelected] = useState(0)
    const [minuteSelected, setMinuteSelected] = useState(0)
    const [customerId, setCustomerId] = useState(0)
    const [offerId, setOfferId] = useState(0)
    // Fonction pour ajouter un zéro devant les jours et mois inférieurs à 10
    const addLeadingZero = (number) => (number < 10 ? `0${number}` : number)

    // Obtention de la date courante
    const today = new Date()
    const day = addLeadingZero(today.getDate());
    const month = addLeadingZero(today.getMonth() + 1); // Les mois commencent à 0 en JavaScript, donc on ajoute 1
    const year = today.getFullYear();

    // Initialisation de selectedDate avec la date courante au format "DD-MM-YYYY
    const [dateSelected, setDateSelected] = useState(`${day}-${month}-${year}`)

    const handleSubmit = () => {
      // Enregistrer le nouveau rendez-vous
      const data = new FormData()
      data.append('offre_id', offerId )
      data.append('cuser_id', customerId )
      data.append('hourSelected', hourSelected )
      data.append('minuteSelected', minuteSelected )
      data.append('dateSelected', dateSelected )
      // console.log(hourSelected+" "+minuteSelected+" "+ dateSelected)
      // axios.post(api+"/api/addAppointement/", data)
      // .then((res)=>{
      //   console.log(res)
      //   setShowConfirmModal(false)
      // })
      // .catch((err)=>console.log(err))
    }

    const joursOuvrables = {
      lundi: false,
      mardi: true,
      mercredi: false,
      jeudi: true,
      vendredi: false,
      samedi: true,
      dimanche: false
    };
    
    const joursOuvrablesArray = Object.entries(joursOuvrables).map(([day, isOpen]) => ({
      day,
      isOpen
    }));
    
    // console.log(joursOuvrablesArray);
    
function handleHour(e){
  console.log(e)
}
if (!show) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-x-hidden overflow-y-auto bg-gray-950/20">
      <div className="relative w-[900px] px-4 h-full md:h-auto ">
        <div className="bg-white rounded-lg shadow ">
          <div className="flex items-start justify-between p-4 border-b rounded-t ">
            <h3 className="text-gray-900 text-lg lg:text-lg font-semibold ">
              Ajouter un nouveau RDV
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              onClick={onClose}
            >
              <svg
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
          <div className="grid grid-cols-6 p-6  h-[500px] overflow-y-scroll">
            <div className="col-span-2 border-r pr-4">
                <div className="flex-col">
                    {/* <Select label={'Client'} options={users} name={'customerId'} onChange={(e)=>setCustomerId(e.target.value)}></Select> */}
                    <div className="mb-4">

        <label
          htmlFor="customerId"
          className="block text-sm font-medium text-gray-700 mb-1 text-start"
        >
          Client
        </label>
      
        <select
          name="customerId"
          onChange={(e)=>setCustomerId(e.target.value)}
          required
          className={`border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full`}
        >
          {offers.map((option, index) => (
            <option key={index} value={option.id} >
              {option.nom}
            </option>
          ))}
        </select>
      </div>
                    {/* <Select label={'Service'} options={offers} name={'serviceId'} onChange={(e)=>setOfferId(e.target.value)}></Select> */}
                    <div className="mb-4">

<label
  htmlFor="offerId"
  className="block text-sm font-medium text-gray-700 mb-1 text-start"
>
  Offre
</label>

<select
  name="offerId"
  onChange={(e)=>setOfferId(e.target.value)}
  className={`border text-gray-600 border-gray-300 text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-[#FF7200] focus:border-transparent w-full`}
>
  {offers.map((option, index) => (
    <option key={index} value={option.id} >
      {option.nom}
    </option>
  ))}
</select>
</div>
                </div>
            </div>
            <div className="col-span-4">
            <div className=" xl:col-span-5 lg:col-span-5 text-start col-span-12 bg-white px-16">
                      {/* Selectionner une date  */}
                        <Calendar setDateSelected={setDateSelected} textSize="text-xs"/>
                        {/* <p className='pt-10 p-5'>Seléctionner une heure</p> */}
                        {/* <span>Vous avez selectionnée {timeSelected}</span> */}
                        {/* nos abonnées, clients, professionneld */}
                      
                        <SlotSelection
                         textSize="text-sm"
                          setTimeSelected={setTimeSelected}
                          setHourSelected={(e)=>{handleHour(e)}}
                          setMinuteSelected={(e)=>{console.log(e)}}
                          openingHour={9}//heure d'ouverture
                          openingMinute={0}//minute ouverture
                          breakStartHour={12}//Heure début pause 
                          breakStartMinute={0}//Minute début pause 
                          breakEndHour={13}//Heure fin pause 
                          breakEndMinute={0}//Minute fin pause 
                          closingHour={18}//Heure de fermeture
                          closingMinute={0}//Minute de fermeture
                          interval={30} //Intervale 
                        ></SlotSelection>
                    </div>
            </div>
          
          </div>
          <div className="flex justify-end space-x-2 items-center px-6  border-t border-gray-200 rounded-b dark:border-gray-600 py-3 bg-gray-200">
         
            <button
              onClick={onClose}
              className="text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:ring-gray-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600"
            >
              Annuler
            </button> 
            <button className='bg-black-900 text-white  rounded-md py-2 px-4' onClick={()=>{setShowConfirmModal((dateSelected !== null && timeSelected !==null)?true:false)
                        }}>Réserver maintenant</button>
          </div>
        </div>
      </div>
      <ConfirmModal 
        show={showConfirmModal} 
        onClose={()=>setShowConfirmModal(false)}
        // variant='danger'
        title="Confirmation"
        text="Êtes-vous sûr de vouloir enregistrer ce rendez-vous"
        buttonText="Enregistrer"
        action={handleSubmit}
      />
    </div>
  )
};

export default AddAppointementModal