import React from 'react'
import PricingCard from './PriceCard';
import Heading from '../../../../components/Heading';



const Pricing = () => {
  return (
    <section className="bg-white dark:bg-gray-900 mb-32">
      <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6">
        <Heading 
          title='Faites vos choix' 
          subtitle='Sélectionner les meilleurs offres pour vous.' 
        />
        <div className="space-y-8 lg:grid lg:grid-cols-4 sm:gap-4 xl:gap-4 lg:space-y-0 items-center">
          {/* Plan Basique */}
          <PricingCard
            background="white"
            title="Plan Basique"
            description="Option idéale pour un usage personnel ou pour de petits projets."
            price="20,000 Ariary"
            duration="/mois"
            features={[
              "Jusqu'à 10 rendez-vous par mois",
              "Notifications par email pour les nouveaux rendez-vous",
              "Page de profil professionnelle basique",
              "Accès à l'interface de gestion des rendez-vous"
            ]}
            buttonText="Choisir ce plan"
          />
        
          {/* Plan Standard */}
          <PricingCard
            background="white"
            title="Plan Standard"
            description="Convient pour plusieurs utilisateurs avec un support étendu."
            price="50,000 Ariary"
            duration="/mois"
            features={[
              "Jusqu'à 30 rendez-vous par mois",
              "Notifications par email  pour les nouveaux rendez-vous et rappels",
              "Page de profil professionnelle standard avec photo et description détaillée",
              "Accès à l'interface de gestion des rendez-vous",
              "Statistiques de base sur les rendez-vous (nombre de rendez-vous, taux d'annulation, etc.)"
            ]}
            buttonText="Choisir ce plan"
           
          />
            {/* Plan Professionnel */}
            <PricingCard
            background="dark"
            title="Plan Professionnel"
            description="Idéal pour une utilisation à grande échelle avec des droits de redistribution étendus."
            price="100,000 Ariary"
            duration="/mois"
            features={[
              "Rendez-vous illimités",
              "Notifications par email et SMS pour les nouveaux rendez-vous et rappels",
              "Page de profil professionnelle avancée avec photo, description détaillée, et avis des clients",
              "Accès à l'interface de gestion des rendez-vous",
              "Statistiques avancées sur les rendez-vous (nombre de rendez-vous, taux d'annulation, taux de satisfaction, etc.)",
              "Possibilité de proposer des offres spéciales et des promotions"
            ]}
            buttonText="Choisir ce plan"
            isHighlighted={true} // Carte du milieu mise en avant
          />
            <PricingCard
            background="white"

            title="Plan Premium"
            description="Convient pour plusieurs utilisateurs avec un support étendu."
            price="200,000 Ariary"
            duration="/mois"
            features={[
              "Rendez-vous illimités",
              "Notifications par email  pour les nouveaux rendez-vous et rappels",
              "Page de profil professionnelle premium avec photo, description détaillée, avis des clients, et vidéos de présentation",
              "Accès à l'interface de gestion des rendez-vous",
              "Statistiques avancées sur les rendez-vous (nombre de rendez-vous, taux d'annulation, taux de satisfaction, etc.)",
              "Possibilité de proposer des offres spéciales et des promotions",
              "Support client prioritaire",
              "Mise en avant des profils premium sur la plateforme (plus de visibilité)"

            ]}
            buttonText="Choisir ce plan"
           
          />
          
        </div>
      </div>
    </section>
  );
}

export default Pricing;