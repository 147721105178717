import React from 'react'

export default function Status({ type, label = "Status" }) {
    //1=expiré,2=en attente,3=confirmé;4=refusé;
    function getStatus() {
        switch (type) {
            case 1:
                return 'bg-red-50 text-red-600';
            case 2:
                return 'bg-blue-50 text-blue-600';
            case 3:
                return 'bg-green-50 text-green-600';
            case 4:
                return 'bg-yellow-50 text-yellow-600';
            default:
                return 'bg-blue-50 text-blue-600';
        }
    }
    return (
        <div className={`px-4 py-2 rounded-md text-center font-semibold ${getStatus()}`} >{label}</div>
    )
}
