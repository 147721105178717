import React from "react";
import user from "../../../../assets/icons/user.png"
import heart from "../../../../assets/icons/heart.png"
import localisation from "../../../../assets/icons/localisation.png"
import starfull from "../../../../assets/icons/fullstar.png"
import staroutline from "../../../../assets/icons/staroutline.png"
import { api } from "../../../../../api";
import { useNavigate } from "react-router-dom"
import TruncateText from "../../../../components/TruncateText";

const ServiceCard = ({
  // img,
  // title,
  // location,
  // description,
  // price,
  created_at,id,
  handleOrderPopup,
  photo, nom, description, secteur
}) => {

  const navigate = useNavigate()
  return (
    <div
      className="relative group shadow-lg transition-all duration-500 hover:shadow-xl dark:bg-slate-950 dark:text-white cursor-pointer rounded-t-md"
      onClick={()=>navigate('/service/'+id)}
    >
      <div className="relative overflow-hidden rounded-t-md">
        {/* Heart Icon - Displayed only on hover */}
        <div className="absolute top-4 right-4 z-10 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-white p-2 rounded-full">
          <img src={heart} alt="Favorite" className="h-4 w-4" />
        </div>

        {/* Image */}
        <img
          src={api + '/storage/' + photo}
          alt="No image"
          className="mx-auto h-[220px] w-full object-cover transition duration-700 hover:skew-x-1 hover:scale-105 hover:z-10"
        />
      </div>

      <div className="space-y-2 p-3 rounded-b-md">
        <h1 className="line-clamp-1 font-bold text-xl text-start">{nom}</h1>
        {/* <div className="flex items-center gap-2 opacity-70">
          <img src={localisation} alt="place" className="w-5 h-5"/>
          {/* <span>{location}</span> 
          <span>Inde</span>
        </div> */}

        <div className=" items-center gap-2 ">
 
        <TruncateText showButton={true} maxChars={150} children={description} ></TruncateText>
          {/* <p>{description}</p> */}
        </div>
        {/* <div className=" flex items-center gap-2"><p>5.0</p>
        <p className='flex gap-2'>
          <img src={starfull} alt="s" className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={starfull} alt="s"  className=" w-4 h-4"/>
          <img src={staroutline} alt="s" className=" w-4 h-4" /></p>
        <p>(58 Votes)</p>                      

                              </div> */}
        {/* <p className="line-clamp-2">{description}</p> */}

        <div className="flex items-center justify-between border-t-2 py-3 !mt-3">
          <div className="opacity-70">
            <p className="flex gap-2 items-center">
              <img src={api+"/storage/"+secteur?.logo} alt="User" className="w-5 h-5" />
              {/* Nom du secteur */}

              {secteur?.nom}

              
            </p>
          </div>
          <div className="flex items-center gap-1">
             Créé le <p className="text-sm font-thin">{new Date(created_at).toLocaleDateString()}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard
