import React , {useState}from 'react'
import Offer from '../Offer/Offer'
// import OfferDetail from '../Offer/OfferDetail'
import Hero from '../../components/Hero/Hero'
import homeimage from "../../assets/images/homeimage.jpg";
import Pricing from './partials/Price/Price'
import Price from '../price/Price'
import Service from './partials/Services/Service'
import Categories from './partials/Categories/Categories'
import Enterprise from './partials/Enterprise/Enterprise'
import Testimonials from './partials/Testimonials/Testimonials'
import Logo01 from "../../assets/logo/asa-removebg-preview.png"
import Logo02 from "../../assets/logo/naturamada-removebg-preview.png"
import Logo03 from "../../assets/logo/tsenavita-removebg-preview.png"
import Logo04 from "../../assets/logo/WhatsApp Image 2024-09-09 at 12.24.06.jpeg"

const Home = () => {
  const [orderPopup, setOrderPopup] = useState(false);

  const handleOrderPopup = () => {
    setOrderPopup(!orderPopup)
  }

  return (
    <>
    <div>
      <div className="h-[700px] relative">
          
        <Hero />

      </div>
      <div class="items-center mx-auto grid max-w-[1040px]  gap-8 py-20 sm:grid-cols-3 sm:gap-12 ">
      <div class="mx-auto">
        <img src={Logo01} alt="" class="inline-block h-32" />
      </div>
      <div class="mx-auto">
        <img src={Logo02} alt="" class="inline-block h-32" />
      </div>
      <div class="mx-auto">
        <img src={Logo03} alt="" class="inline-block h-36" />
      </div>
    </div>
      <Categories/>
      <Service/>
      <Pricing/>
      {/* <Testimonials/> */}
    </div>
  </>
  )
}

export default Home