import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import { useNavigate, useParams } from 'react-router-dom';
import ButtonBack from '../../components/Button/ButtonBack';
import ButtonOutline from '../../components/Button/ButtonOutline';
import Status from '../../components/Status/Status';
import img from '../../assets/images/back1.jpg';
import axios from 'axios'
import {api} from '../../../api'
import AppointmentStatus from '../../components/AppointmentStatus';

const CustomerDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const [customerData, setCustomerData] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const idPrest = 5; //Soloina localStorage

  useEffect(() => {
    // Fetch customer details
    axios.get(`${api}/api/getInfoClient/${id}`)
      .then(response => {
        setCustomerData(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the customer data!", error);
      })

    // Fetch customer appointments
    axios.get(`${api}/api/getRdvClientVersPrest/${id}/${idPrest}`)
      .then(response => {
        setAppointments(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the appointments!", error);
      })
  }, [id])

  return (
    <div className='border-line min-h-screen mx-6'>
      <div className="flex justify-between items-center mb-6">
        <ButtonBack path="/pro/customers/" text="Détail du client"></ButtonBack>
        {/* <Button onClick={() => navigate('/pro/service/add')}>Nouveau rdv</Button> */}
      </div>
      <div className="grid grid-cols-5 gap-4">
        <div className="col-span-2">
          <div className="bg-purple-300 rounded-md h-40 pt-28 pl-6">
            <img src={api + '/storage/' + customerData?.photo} alt="" className='w-24 h-24 rounded-full bg-purple-100 p-1' />
          </div>

          <div className="px-6 mt-16 pb-6">
            <h5 className='text-xl font-semibold mb-4'>{customerData?.nom}</h5>
            <p className='text-sm text-gray-1 mb-1'>Email: {customerData?.email}</p>
            <p className='text-sm text-gray-1 mb-1'>Contact: {customerData?.contact}</p>
            <hr className='my-6'/>
            <p className='text-sm font-semibold mb-2'>Note</p>
            <p>Pas de note</p>
            <hr className='my-6'/>
            <p className='text-sm font-semibold mb-2'>Adresse</p>
            <p>{customerData?.adresse}</p>
          </div>
        </div>
        <div className="col-span-3">
          {/* <div className="grid grid-cols-10 gap-1 mb-4">
            <input type="text" className='input2 col-span-6 w-full' placeholder="Nom d'offre"/>
            <select name="etat" id="etat" className='input2 col-span-4 w-full'>
              <option value="Tous">Tous</option>
              <option value="Confirmé">Confirmé</option>
              <option value="En attente">En attente</option>
              <option value="Annulé">Annulé</option>
            </select>
          </div> */}
          <div className="grid grid-cols-3 gap-4 mb-4">
            <div className="bg-neutral-200 rounded-md h-20 p-4 flex-col shadow-md">
              <h5 className='text-black-900 font-bold text-xl'>{appointments.length}</h5>
              <p className='font-medium'>Total RDV</p>
            </div>
            <div className="bg-green-400/10 rounded-md shadow h-20 p-4">
              <h5 className='text-green-600 font-bold text-xl'>{appointments.filter(appointment => (appointment.etat === "Confirmé")).length}</h5>
              <p className='font-medium'>Total RDV confirmé</p>
            </div>
            <div className="bg-yellow-400/20 rounded-md shadow h-20 p-4">
              <h5 className='text-orange-400 font-bold text-xl'>{appointments.filter(appointment => (appointment.etat === "En attente")).length}</h5>
              <p className='font-medium'>Total RDV en attente</p>
            </div>
          </div>
          <div className="border rounded-md">
            <table className='w-full table2'>
              <thead>
                <tr>
                  <th>Commande</th>
                  <th>Date</th>
                  <th>Etat</th>
                  <th>Prix (Ar)</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {appointments.map(appointment => (
                  <tr key={appointment.id}>
                    <td>
                      <div className="flex gap-1">
                        <img src={api + '/storage/' + appointment.offre?.photo} alt="" className='w-10 h-10 rounded-md '/>
                        <div className="flex-col flex justify-between">
                          <p>#{appointment.id}</p>
                          <p>{appointment.offre?.nom}</p>
                        </div>
                      </div>
                    </td>
                    <td>{new Date(appointment.created_at).toLocaleDateString()}</td>
                    <td>
                      {/* <Status text={appointment.etat}></Status> */}
                      <div className="flex">
                    <AppointmentStatus text={appointment.etat}/>
                      </div>
                    </td>
                    <td>{appointment.offre?.prix}</td>
                    {/* <td>
                      <ButtonOutline>Détails</ButtonOutline>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CustomerDetail;
