import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Card from '../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Input from '../../components/form/input'
import Path from '../../components/path';
import axios from 'axios';
import { api } from '../../../api';
import Modals from '../../components/modal';
import Load from '../../components/load';
import Feedback from '../../components/feedback';
import { exportToExcel } from '../../components/ExcelExport';
export default function ClientPro() {
  const navigate = useNavigate()
  const [data, setData] = useState([])
  const [deleteId, setDeleteId] = useState()
  const [userProId, setUserProId] = useState()
  const [search,setSearch]=useState('')
  const [isOpen, setIsOpen] = useState({
    delete: false,
    success: false,
    fails: false
  })
  const handleOpenModal = (type) => {
    setIsOpen({ ...isOpen, [type]: true })
  }
  const handleCloseModal = (type) => {
    setIsOpen({ ...isOpen, [type]: false })
  }
  const [options, setOptions] = useState({
    disabled: false,
    loading: false
  })


  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleDelete = () => {
    setOptions({ ...options, loading: true })
    try {
      axios.delete(api + '/api/route/' + deleteId)
        .then(response => {
          setIsOpen({ ...isOpen, success: true })
          setOptions({ ...options, loading: false })
          fetchData()
        })
        .catch(error => {
          setIsOpen({ ...isOpen, fails: true })
          setOptions({ ...options, loading: false })
          console.error(error)
        })
    } catch (error) {
      console.error(error)
      setOptions({ ...options, loading: false })
    }
  }

  function fetchData() {
    try {
      axios.get(api + '/api/allPrestataires')
        .then(response => setData(response.data))
        .catch(error => console.error(error))
    } catch (error) {
      console.error(error)
    }
  }


  useEffect(() => {
    fetchData()
  }, [])

  return (
    <div className='w-full flex flex-col gap-y-3'>
      <div className="w-full flex flex-row justify-between items-center">
        <div className='flex flex-row gap-x-3 justify-start items-center'>
          <Button className="flex flex-row gap-x-3" variant="contained" onClick={() => { navigate('/admin/nouveauPrestataire') }}>
            <IoAddCircle />
            <span>Nouveau</span>
          </Button>
          <Button className="flex flex-row gap-x-3" variant="outlined"
          onClick={()=>{
            exportToExcel(data,"Client-Pro")
          }}
          >
            <IoShare />
            <span>Exporter</span>
          </Button>
        </div>
        <div className='flex flex-row justify-end items-center'>
          <div className="flex flex-row justify-end items-center gap-x-1">
            <Input value={search} onChange={(e)=>{setSearch(e.target.value)}}/>
            <Button className="flex flex-row gap-x-3" variant="contained">
              <IoSearch />
              <span>Rechercher</span>
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <div className='flex flex-row justify-between items-center'>
          <h4 className="mb-2">Client professionnel</h4>
          <Path currentName='Client pro' />
        </div>
        <table className='w-full max-w-full'>
          <thead>
            <tr>
              <th>NUMERO</th>
              <th>PHOTO</th>
              <th>NOM</th>
              <th>EMAIL</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {
              data.filter((item) => (search ? item.nom.toLowerCase().includes(search.toLocaleLowerCase()) : item)).map((item, index) => (
                <tr key={index}>
                  <td>{item.id}</td>
                  <td><img src={api + '/storage/' + item.photo} alt={item.nom} className='w-8 h-8 rounded-full' /></td>
                  <td>{item.nom}</td>
                  <td>{item.email}</td>
                  <td className='flex flex-row justify-end items-center text-xl gap-x-3'>
                    <Button id="basic-button"
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={(e)=>{ handleClick(e); setUserProId(item.id)}} >
                      <IoEllipsisHorizontal className='text-lg' />
                    </Button>
                    <IoEye className='text-lg cursor-pointer' onClick={() => { navigate(`/admin/detailsPrestataire/${item.id}`) }} />
                    <IoCreate className='text-lg cursor-pointer' onClick={() => { navigate(`/admin/modificationPrestataire/${item.id}`) }} />
                    <IoTrash className='text-lg hover:cursor-pointer'
                      onClick={() => {
                        setDeleteId(item.id); handleOpenModal("delete")
                      }}
                    />
                  </td>
                </tr>
              ))
            }

          </tbody>
        </table>
      </Card>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { navigate(`/admin/abonnement/${userProId}`) }}>Abonnement</MenuItem>
        <MenuItem onClick={() => { navigate(`/admin/service/${userProId}`) }}>Service</MenuItem>
        <MenuItem sx={{
          color: 'red', '&:hover': {
            backgroundColor: 'red',
            color: 'white'
          }
        }}>Desactiver</MenuItem>
      </Menu>
      <Modals
        isOpen={isOpen.delete}
        onClose={() => { handleCloseModal("delete") }}
      >
        <div className='w-full flex flex-col gap-y-3'>
          <h1>Confirmation</h1>
          <p>êtes-vous sûr de vouloir supprimer ce prestataire?</p>
          <div className='flex flex-row gap-x-3'>
            <Button fullWidth variant="outlined" onClick={() => { handleCloseModal("delete"); }}>Annuler</Button>
            <Button fullWidth variant="contained" color="error" onClick={() => { handleDelete() }}>
              <Load title='Supprimer' loading={options.loading} />
            </Button>
          </div>
        </div>
      </Modals>
      <Feedback isOpen={isOpen.success} type='success'
        message='Supprimé avec succès'
        onClose={() => {
          setIsOpen({ ...isOpen, success: false })
        }}
      />
      <Feedback isOpen={isOpen.fails} type='error'
        message="Une erreur s'est produite"
        onClose={() => {
          setIsOpen({ ...isOpen, fails: false })
        }}
      />
    </div>
  )
}
