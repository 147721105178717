import React from 'react'
import logotransparent from '../../assets/logo/logotransparent.png'
import angleRight from '../../assets/icons/angle-right.svg'
import { Link, useNavigate } from 'react-router-dom'

const Index = () => {
  const navigate = useNavigate()
  const footer = [
    {
      title: "MENU",
      text: [
        { list: "Page d'accueil", url: "/" },
        { list: "Service", url: "/service" },
        { list: "Professionnel", url: "/prestataire" },
        { list: "Reservation", url: "/reservation" },
        // { list: "Contact", url: "/contact" },
        // { list: "Blog", url: "/blog" },
      ],
    },
    // {
    //   title: "SECTIONS",
    //   text: [
    //     { list: "En-têtes", url: "/headers" },
    //     { list: "Fonctionnalités", url: "/features" },
    //     { list: "Attrayant", url: "/attractive" },
    //     { list: "Témoignages", url: "/testimonials" },

    {
      title: "ENTREPRISE",
      text: [
        { list: "Nous contacter", url: "/contact" },
        { list: "Terme et condition", url: "/termecondition" },
      ],
    },
  ];
  

  return (
    <>
      <section className='bg-primary py-10 text-white  px-6 selection:bg-orange-300/80 selection:text-white'>
        <div className='container mx-auto'>
          <div className='flex sm:flex-row flex-col items-center justify-between'>
            <div>
              <h1 className='text-4xl'>Avez-vous des questions ?</h1>
              <p className='mt-4'>Nous vous aiderons à gérer vos rendez-vous et à optimiser votre temps.</p>
            </div>
            <button className='bg-white text-primary px-6 py-2 rounded mt-3 sm:mt-0' onClick={()=>navigate('/contact')}>Contactez-nous aujourd'hui</button>
          </div>
        </div>
      </section>

      <footer className='bg-gray-900 py-12 text-white px-6 '>
        <div className='container mx-auto grid grid-cols-2 md:grid-cols-4 gap-6 selection:bg-orange-300/80 selection:text-white'>
          <div className='flex flex-col items-start'>
            <img src={logotransparent} alt='Logo' className='w-36' />
            <h2 className='font-medium mt-4 text-start'>Besoin d'aide avec quelque chose ?</h2>
            <p className='text-gray-400 mt-4 text-start'>Recevez des mises à jour, des offres spéciales, des tutoriels et des réductions directement dans votre boîte de réception chaque mois.</p>

            <div className='flex mt-6'>
              <input type='text' placeholder='Adresse e-mail' className='bg-white text-gray-900 px-4 py-3 rounded-l w-full' />
              <button className='bg-blue-600 text-white px-4 py-3 rounded-r'>S'abonner</button>
            </div>
          </div>
          <div></div>

          {footer.map((val) => (
            <div key={val.title} className='flex flex-col text-start selection:bg-orange-300/80 selection:text-white'>
              <h3 className='font-medium mb-8'>{val.title}</h3>
              <ul>
                {val.text.map((items, index) => (
                  <Link to={items.url} key={index} className='hover:text-white items-center text-gray-400 mb-5 cursor-pointer flex gap-1'><img src={angleRight} className='w-6 h-6' alt="" />{items.list}</Link>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </footer>
      <div className='text-center py-5 bg-gray-900 text-gray-500 border-t border-gray-700'>
        <span>© 2024 SORO Rendez-vous. Design by HAISOA</span>
      </div>
    </>
  )
}

export default Index