import { Button } from '@mui/material'
import React, { useState } from 'react'
import GeneralSection from './Generale'
import HistorySection from './Historique'
import Card from '../../components/Card'

export default function Profile() {
    const [index, setIndex] = useState(1)
    
    return (
        <Card>
            <div className="w-full flex gap-x-2 flex-row justify-between items-start">
                <div className="max-lg:hidden lg:w-3/12  flex flex-col items-start justify-start gap-y-1">
                    <Button style={index === 1 ? { backgroundColor: '#FF7200', width: '100%' } : { width: '100%' }} variant={index === 1 ? 'contained' : 'clear'} onClick={() => { setIndex(1) }}>
                        Général
                    </Button>
                    {/* <Button style={index === 2 ? { backgroundColor: '#FF7200', width: '100%' } : { width: '100%' }} variant={index === 2 ? 'contained' : 'clear'} onClick={() => { setIndex(2) }}>
                        Historique
                    </Button> */}
                </div>
                <div className="max-lg:w-full lg:w-9/12 ">
                    {index === 1 ? <GeneralSection /> : <HistorySection />}
                </div>
            </div>
        </Card>
    )
}