import React from 'react'
import { IoArrowBack } from 'react-icons/io5'
import { useNavigate } from 'react-router-dom'

export default function GoBack() {
    const navigate=useNavigate()
    function go(){
        navigate(-1)
    }
  return (
    <button onClick={()=>{go()}} type='button'>
        <IoArrowBack className='text-slate-500 text-[15px]'/>
    </button>
  )
}
