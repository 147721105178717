import React, { useEffect, useState } from 'react'
import profile from '../../../../assets/image/profile.png'
import { Button } from '@mui/material'
import { IoPencil } from 'react-icons/io5'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../api'
export default function GeneralSection() {
    const {myData,setMyData}=useOutletContext()
    const navigate = useNavigate()
    const [id, setId] = useState(1)
    const [data, setData] = useState([])
   

    function fetchData() {
        try {
            axios.get(api + '/api/getInfoClient/' + id)
                .then(res => {
                    setData(res.data);
                })
                .catch(err => {
                    console.error(err);
                })
        } catch (error) {
            console.error(error);
        }
    }
    useEffect(() => {
        fetchData()
    }, [])
    return (
        <div className='flex flex-col justify-start items-start '>
            <div className='flex flex-row justify-start w-full mb-4 items-center gap-x-3 p-2 border border-slate-200 rounded-sm '>
                <img src={api + '/storage/' + data.photo} className='w-[50px]' />
                <div>
                    <h4 >{data.nom}</h4>
                    <p >Administrateur</p>
                </div>
            </div>
            <div className='w-full flex flwx-row justify-end items-center'>
                <Button variant='text' size='small' onClick={() => { navigate('/admin/modificationProfile') }}>
                    <div className="flex flex-row justify-center items-center gap-x-3 ">
                        <IoPencil />
                        <span className="text-[10px]">Modifier</span>
                    </div>
                </Button>
            </div>
            <div className='w-full border mb-2 border-slate-300 rounded-sm p-2'>
                <div className='w-full grid max-lg:grid-cols-2 gap-3 lg:grid-cols-3'>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Nom
                        </span>
                        <span className="font-medium ">
                            {data.nom}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Rôle
                        </span>
                        <span className="font-medium ">
                            Administrateur
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Adresse
                        </span>
                        <span className="font-medium ">
                            {data.adresse}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Région
                        </span>
                        <span className="font-medium ">
                            {data.region}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            District
                        </span>
                        <span className="font-medium ">
                            {data.district}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Numero de téléphone
                        </span>
                        <span className="font-medium ">
                            {data.contact}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            E_mail
                        </span>
                        <span className="font-medium ">
                            {data.email}
                        </span>
                    </div>
                    <div className='flex flex-col justify-start items-start gap-y-1'>
                        <span className="text-[10px] text-slate-400">
                            Description
                        </span>
                        <span className="font-medium ">
                            {data.description}
                        </span>
                    </div>
                </div>
            </div>
            <div className='w-full border border-slate-300 rounded-sm p-2'>
                <div className='w-full grid grid-cols-2'>
                    <div className='flex flex-row justify-between items-center w-full border-2-r pr-2'>
                        <span>Changer le mot de passe</span>
                        <Button variant="outlined" size='small' onClick={() => { navigate('/admin/changePassword') }}>
                            Changer
                        </Button>
                    </div>
                    <div className='flex flex-row justify-between items-center w-full border-2-r pr-2'>
                        <span>Changer l'e-mail</span>
                        <Button variant="outlined" size='small' onClick={() => { navigate('/admin/changeEmail') }}>
                            Changer
                        </Button>
                    </div>
                </div>
            </div>

        </div>
    )
}
