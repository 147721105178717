import * as React from 'react';

import { PieChart } from '@mui/x-charts';
import { useSeries } from '@mui/x-charts/internals';
export default function Pie({data=[]}) {
  return (
    <PieChart
      series={[
        {
          data: data,
        },
      ]}
      width={400}
      height={200}
    />
  );
}