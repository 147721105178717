import React from 'react'

const Input = ({
  label, 
  type = 'text', 
  name, 
  value, 
  placeholder, 
  onChange, 
  required = false, 
  className = '',
  ...props
}) => {
  return (
    <div className="mb-4">
      {label && (
        <label 
          htmlFor={name} 
          className="block text-sm font-medium text-gray-700 mb-1 text-start"
        >
          {label}
        </label>
      )}
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        className={`border border-gray-300 rounded-md px-3 py-2 text-sm focus:border-primary  focus:outline focus:outline-2 focus:outline-primary  ${className}`}
        {...props}
      />
    </div>
  )
}

export default Input;
