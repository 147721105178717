import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import Input from '../../../../components/form/input'
import { Button } from '@mui/material'
import GoBack from '../../../../components/btn/goBack'
import Modal from '../../../../components/modal'
import Feedback from '../../../../components/feedback'
import Load from '../../../../components/load'
import axios from 'axios'
import { api } from '../../../../../api'
import { useNavigate } from 'react-router-dom'
export default function ChangePassword() {
    const navigate = useNavigate()
    const [data, setData] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    })
    const [id, setId] = useState(1)

    const [options, setOptions] = useState({
        loading: false,
        disabled: true
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
        confirm: false
    })
    function handleOpen(type) {
        setIsOpen({ ...isOpen, [type]: true })
    }
    function handleClose(type) {
        setIsOpen({ ...isOpen, [type]: false })
    }
    function handleConfirmation(e) {
        e.preventDefault()
        setIsOpen({ ...isOpen, confirm: true })
    }
    function handleChange() {
        // e.preventDefault()
        setOptions({ ...options, loading: true })
        try {
            const form = new FormData()
            form.append('oldPassword', data.password)
            form.append('newPassword', data.newPassword)
            axios.post(api + `/api/changerMdp/${id}`, form)
                .then(() => {
                    setData({ ...data, password: '', newPassword: '', confirmPassword: '' })
                    handleOpen('success')
                    setOptions({ ...options, loading: false })
                    setTimeout(() =>{
                        
                    },500)
                })
                .catch((error) => {
                    console.error(error)
                    setOptions({ ...options, loading: false })
                    handleOpen('fails')
                })
        } catch (error) {

            console.error(error)
            setOptions({ ...options, loading: false })
            handleOpen('fails')
        }
    }

    useEffect(() => {
        if (
            data.password &&
            data.newPassword &&
            data.confirmPassword &&
            data.newPassword === data.confirmPassword

        ) {
            setOptions({ ...options, disabled: false })
        } else {
            setOptions({ ...options, disabled: true })
        }

    }, [data])




    return (
        <div className="max-md:w-full max-lg:w-8/12 lg:w-5/12" >
            <Card>
                <form onSubmit={(e) => { handleConfirmation(e) }} className='flex flex-col w-full gap-y-3'>
                    <div className="flex flex-row justify-start items-center gap-x-3">
                        <GoBack />
                        <h4 className='w-full'>Modification de mot de passe</h4>
                    </div>
                    <Input label='Ancien mot de passe' required value={data.password} type='password' onChange={(e) => { setData({ ...data, password: e.target.value }) }} />
                    <Input label='Nouveau mot de passe' required value={data.newPassword} type='password' onChange={(e) => { setData({ ...data, newPassword: e.target.value }) }} />
                    <Input label='Confirmation nouveau mot de passe' required wrong={data.newPassword !== '' && (data.newPassword !== data.confirmPassword)} value={data.confirmPassword} type='password' onChange={(e) => { setData({ ...data, confirmPassword: e.target.value }) }} />
                    <Button type="submit" variant="contained" disabled={options.disabled}>
                        Changer
                    </Button>
                </form>
            </Card>
            <Feedback message='Mot de passe modifié avec succès!' type='success' onClose={() => { handleClose('success') }} />
            <Feedback message="Une erreur s'est produit!" type='erreur' onClose={() => { handleClose('fails') }} />
            <Modal isOpen={isOpen.confirm} onClose={() => { handleClose('confirm') }}>
                <div className="w-full flex flex-col gap-y-3">
                <h4>Confirmation</h4>
                <p>êtes-vous sûr de vouloir modifier votre mot de passe?</p>
                <div className='flex justify-end gap-x-2'>
                    <Button onClick={() => { handleClose('confirm') }} fullWidth variant='outlined'>Non,abondonner</Button>
                    <Button onClick={() => { handleChange() }} fullWidth variant='contained'>
                        <Load title='Oui,modifer' loading={options.loading} />
                    </Button>
                </div>
                </div>
            </Modal>

        </div>
    )
}
