import React from "react";
import { Link, useLocation } from "react-router-dom";
// import { NavbarLinks } from "../../../website/Datas/Datas"
import prestataire1 from "../../../website/assets/images/prestataire1.png"
import Logo from "../../../website/assets/logo/logo.png"

export const NavbarLinks = [
  {
    name: "Accueil",
    link: "/",
    level:"public"
  },
  {
    name: "Service",
    link: "/service",
    level:"public"
  },
  {
    name: "Agence",
    link: "/prestataire",
    level:"public"
  },
  {
    name: "Mes réservations",
    link: "/reservation",
    level:"private"
  },
];

const ResponsiveMenu = ({ showMenu, setShowMenu }) => {
  console.log("showMenu", showMenu)
  const location = useLocation();

  const menuItems = [
    { name: 'Tableau de bord', icon: 'home', path: '/pro/' },
    { name: 'Rendez-vous', icon: 'calendar', path: '/pro/appointments' },
    // { name: 'Mes disponibilités', icon: 'availability', path: '/pro/availability' },
    { name: 'Clients', icon: 'users', path: '/pro/customers' },
    { name: 'Mes services', icon: 'services', path: '/pro/services' },
    // { name: 'Mes offres', icon: 'offers', path: '/pro/offers' },
    { name: 'Abonnement', icon: 'abonnement', path: '/pro/abonnement' },
    // { name: 'Profil', icon: 'offers', path: '/pro/profile' },
    { name: 'Paramètre', icon: 'parameter', path: '/pro/parametre' },
    // { name: 'Se déconnecter', icon: 'parameter', path: '' },
  ]

  return (
    <div 
      onClick={() => setShowMenu(false)}
      className={`${showMenu ? "w-screen" : "w-0"} sm:hidden bg-gray-900/20 h-screen fixed z-20 top-0 backdrop-blur-md backdrop-filter`}
    >
      <div
        onClick={(e) => e.stopPropagation()} 
        className={`${
          showMenu ? "left-0 w-[300px] block" : "-left-[500px]"
        } fixed bottom-0 top-0 z-40 flex h-screen justify-between flex-col bg-white dark:bg-gray-900 dark:text-white px-8 pb-6 pt-10 text-black transition-all duration-300 md:hidden rounded-r-xl shadow-md`}
      >
        <div>
          <img src={Logo} alt="" className="h-10 w-20" />
        <hr className="mt-10"/>
        <div className="card">
          {/* <div className="flex items-center justify-start gap-3">
            <img src={prestataire1} alt="prestataire" className="outline outline-[4px] outline-primary/30 w-10 h-10 rounded-full"/>
            <div>
              <h1>Bonjour {localStorage.getItem("soro-nom")}</h1>
              <h1 className="text-sm text-slate-500">{localStorage.getItem("soro-email")}</h1>
            </div>
          </div> */}
          <nav className="">
            {/* <ul className="space-y-4 text-xl text-start">
              {NavbarLinks.map((data) => (
                <li key={data.name}>
                  <Link
                    to={data.link} // Adjust based on your routing setup
                    onClick={() => setShowMenu(false)}
                    className="mb-5 inline-block text-black-900 text-base"
                  >
                    {data.name}
                  </Link>
                </li>
              ))}
            </ul> */}
             <ul className="flex flex-col my-2 space-y-1 font-medium">
            {/* <li className="px-5">
              <div className="flex flex-row items-center h-8">
                <div className="text-sm font-light tracking-wide text-gray-500">Menu</div>
              </div>
            </li> */}
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link
  to={item.path}
  className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-primary hover:bg-opacity-5 text-gray-600 hover:text-primary hover:font-medium border-r-4 ${

    location.pathname === item.path ? ' border-r-[4px] border-r-[#fb923c] bg-primary bg-opacity-5 text-primary ' : ' border-transparent '

  } pr-6 border-r-4 border-r-transparent `}
>
                  <span className="inline-flex justify-center items-center ml-4">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {item.icon === 'home' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  />
)}
{item.icon === 'calendar' && (
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
)}
{item.icon === 'availability' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M5 8h14M12 4v4m0 8v4m-8-4h16"
  />
)}
{item.icon === 'users' && (
 <path 
 stroke-linecap="round" 
 stroke-linejoin="round" 
 stroke-width="2" 
 d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
)}
{item.icon === 'services' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M9.75 17L8.5 19m-3.5 2l4.5-2.5m0 0l4.5 2.5M9.75 17v2.5m0 0l4.5-2.5M12 13V4m0 0L7 8m5-4l5 4m0 8v5m0 0l3.5-3.5M19 19v-2.5m0 0l-4.5 2.5M19 17l-3.5 3.5"
  />
)}
{item.icon === 'offers' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M9 5v2a7 7 0 006 0V5M5 10h14m-5 5v6m0 0h-4m4 0l-4-6"
  />
)}
{item.icon === 'abonnement' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M7 20h10a1 1 0 001-1V9a1 1 0 00-1-1H7a1 1 0 00-1 1v10a1 1 0 001 1zM4 8V7a2 2 0 012-2h12a2 2 0 012 2v1M7 4h10a1 1 0 011 1v1M4 8h16"
  />
)}
{item.icon === 'parameter' && (
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
    // <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
)}
{item.icon === 'parameter' && (
    // <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
)}

                    </svg>
                  </span>
                  <span className="ml-2 text-sm tracking-wide truncate">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
          </nav>
        </div>
        </div>


        <div>
            <hr />
        <div className="footer mt-2">
          <button className="w-full text-sm px-4 font-medium gap-x-2 relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-500 hover:bg-opacity-5 text-gray-600 hover:text-gray-900 rounded-md ">
          <svg
                className="w-5 h-5"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>         
            </svg>
            Se déconnecter
          </button>
        </div>
        </div>
      
      </div>
    </div>
  );
};

export default ResponsiveMenu;