import React, { useEffect, useState } from 'react'
import boat from '../../assets/images/boat.jpg'
import bookmark from '../../assets/icons/bookmark.svg'
import { Link } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'

const Booking = () => {

    const user_id = 5;

    const [userInfo, setUserInfo] = useState([])
    const [userRdv, setUserRdv] = useState([])

    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
  };


    // useEffect(() => {
        
    //     axios.get(api + `/api/getInfoClient/${user_id}`)
    //       .then(response => {
    //         setUserInfo(response.data);
    //       })
    //       .catch(error => {
    //         console.error("Erreur lors de la récupération des info des users:", error);
    //       })

    //       axios.get(api + `/api/getRdvClient/${user_id}`)
    //       .then(response => {
    //         setUserRdv(response.data);
    //         console.log(userRdv)
    //       })
    //       .catch(error => {
    //         console.error("Erreur lors de la récupération des rdvs du client:", error);
    //       })


    //     }, [])


  return (
    <>
      <div>

<main className="profile-page">
  <section className="relative block h-[400-px]">
     {/* background image */}
    
    <div className="absolute top-0 w-full  bg-center bg-cover bg-slate-400 h-[400px]" id='back2'>
      <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
    </div>
    <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" >
      <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <section className="relative pt-[500px] bg-blueGray-200 ">
    <div className="container mx-auto px-4">
      <div className='grid grid-cols-3 relative min-w-0 break-words  w-full mb-6 rounded-lg -mt-64 gap-4'>

        {/* Section gauche */}
        <div className='col-span-1 '>
            <div className='bg-white rounded-md p-6 drop-shadow-md'>
                <h5 className='font-bold text-lg mb-6 text-start'>Vos informations</h5>
                <hr className='mb-3'/>
                <ul>
                    <li className='flex items-center gap-2 mb-3'>
                            <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                                <img src={bookmark} alt="..." className='w-6 h-6'/>
                            </div>
                            <p>{userInfo.nom}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.contact}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.email}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.adresse}</p>
                    </li>
                </ul>    
            </div>
            
        </div>

        {/* Section droite */}
        <div className='col-span-2 bg-white rounded-md p-6  drop-shadow-md min-h-[600px]'>
        <h5 className='font-bold text-lg mb-6 text-start'>Liste de mes rendez-vous</h5>
            <hr className=''/>
            <ul>
            {userRdv.map((rdv, index) => (
                    <li
                        key={index}
                        className='cursor-pointer border-b border-b-slate-500/10 py-3 hover:bg-slate-50 px-4'
                    >
                        <Link to={`/reservation/${rdv.id}`} className='flex items-center gap-3'>
                            <div className='bg-green-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                                <img src={bookmark} alt="..." className='w-6 h-6' />
                            </div>
                            <div className='flex-col flex items-start'>
                                <p className='font-semibold text-lg'>{formatDate(rdv.date)} à {rdv.heure}:{rdv.minute}</p>
                                <div className=''>
                                    <span className='font-semibold text-green-500'>{rdv.etat}</span>
                                    <span className='text-black-300'> | {rdv.offre?.nom}</span>
                                    <span className='text-black-300'> | {rdv.offre.service?.nom}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}

               
            </ul>
        </div>
        </div>
    </div>
  </section>
</main>
    </div>
    {/* début booking */}
      
    </>
 
  )
}

export default Booking