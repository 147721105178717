import React, { useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import axios from 'axios'; // Import axios
import Button from '../../components/Button';
import ServiceCard from '../../components/Cards/ServiceCard';
import ButtonBack from '../../components/Button/ButtonBack';
import ButtonOutline from '../../components/Button/ButtonOutline';
import Status from '../../components/Status/Status';
import img from '../../assets/images/back1.jpg';
import { IoEye, IoTrash } from 'react-icons/io5';
import { BiPen } from 'react-icons/bi';
import { api } from '../../../api'
import ConfirmModal from '../../components/Modal/ConfirmModal'
import Feedback from '../../../admin/components/feedback';

const Index = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    Offer: '',
    agent: '',
    localisation: '',
    secteur: ''
  })
  const { setTitle } = useOutletContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [serviceData, setServiceData] = useState([]); // State for fetched data
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [idSelected, setIdSelected] = useState(0)
  const itemsPerPage = 6;
  const navigate = useNavigate()
  const [isOpen, setIsOpen] = useState({
    success: false,
    fails: false
})

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const [offres, setOffres] = useState([])

  const cancel = () => {
    // supprimer une offre
    axios.post(api+"/api/deleteOffre/"+idSelected)
    .then((res)=>{
      console.log(res)
      setOffres((prevOffres) => prevOffres.filter(offer => offer.id !== idSelected));
      setShowDeleteModal(false)
      setIsOpen({ ...isOpen, success: true })
    })
    .catch((err)=>{
      console.log(err)
      setShowDeleteModal(false)
      setIsOpen({ ...isOpen, fails: true })
    })
  }

  // Fetch offers when the component mounts
  useEffect(() => {
    const fetchOffers = async () => {
      try {
        const response = await axios.get(api + `/api/allOffresService/${id}`); // Replace with your API endpoint
        setOffres(response.data);
        console.log(response.data)
      } catch (error) {
        console.error('Error fetching offers:', error);
      }
    }

    fetchOffers();
    setTitle('Offre');
  }, [id, setTitle]);

  // Calculate the items to display on the current page
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = serviceData.slice(indexOfFirstItem, indexOfLastItem);

  // Calculate the total number of pages
  const totalPages = Math.ceil(serviceData.length / itemsPerPage);

  return (
    <div className='border-line min-h-screen mx-4'>
      <div className="flex justify-between items-center mb-6">
        <ButtonBack path={`/pro/services`} text="Liste d'offres" />
        <Button onClick={() => navigate(`/pro/service/${id}/offer/add`)}>Ajouter offre</Button>
      </div>
      <div className="border rounded-md">
        <table className='w-full table2'>
          <thead>
            <tr>
              <th>Numéro</th>
              <th>Nom</th>
              <th>Description</th>
              <th>Prix (Ar)</th>
              <th>Durée</th>
              <th>Photo</th>
              <th>Etat</th>
              <th>Rdv</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {offres.map((offer) => (
              <tr key={offer.id}>
                <td>{offer.id}</td>
                <td>{offer.nom}</td>
                <td>{offer.description}</td>
                <td>{offer.prix}</td>
                <td>{offer.duree_heure}h{offer.duree_minute}</td>
                <td>
                  <div className="flex gap-3">
                    <img src={api + '/storage/' + offer.photo} alt="" className='w-10 h-10 rounded-md '/>
                  </div>
                </td>
                <td><Status text={offer.status} /></td>
                <td>{offer.rdvs_count}</td>
                <td>
                  <div className="flex gap-2 items-center">
                    <BiPen className='text-xl cursor-pointer hover:text-blue-600' onClick={() => navigate(`/pro/service/${id}/offer/edit/${offer.id}`)} />
                    <IoTrash className='text-xl cursor-pointer hover:text-red-600' onClick={()=>{
                      setIdSelected(offer.id)
                      setShowDeleteModal(true)}}
                    // Add delete functionality here
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <ConfirmModal 
        show={showDeleteModal} 
        onClose={()=>setShowDeleteModal(false)}
        variant='danger'
        title="Supprimer"
        text="Êtes-vous sûr de vouloir supprimer cet offre"
        buttonText="Supprimer"
        action={cancel}
      />

<Feedback isOpen={isOpen.success} onClose={() => setIsOpen({ ...isOpen, success: false })} message='Suppression réussie !' />
<Feedback isOpen={isOpen.fails} onClose={() => setIsOpen({ ...isOpen, fails: false })} type='error' message='Une erreur est survenue !' />
    </div>
  );
};

export default Index;
