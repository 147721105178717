import React, { useEffect, useState } from 'react'
import boat from '../../assets/images/boat.jpg'
import bookmark from '../../assets/icons/bookmark.svg'
import { Link } from 'react-router-dom'
import { api } from '../../../api'
import axios from 'axios'
import { FaChevronDown } from 'react-icons/fa6'
import { BiFilter } from 'react-icons/bi'
import { FiBookmark, FiPhone, FiMail, FiMapPin, FiUser } from 'react-icons/fi';
import { TimeConverter } from '../../../websitePro/components/DateTimeConverter/TimeConverter'
import { FiCheckCircle, FiClock, FiXCircle } from 'react-icons/fi'; // Import des icônes


const Bookings = () => {

    const user_id = localStorage.getItem("soro-id");

    const [userInfo, setUserInfo] = useState([])
    const [userRdv, setUserRdv] = useState([])
    const [formData, setFormData] = useState({
      keyword: '',
      status: 'Tous'
    });
    const formatDate = (dateStr) => {
      const date = new Date(dateStr);
      return date.toLocaleDateString('fr-FR', { day: 'numeric', month: 'long', year: 'numeric' });
  };


    useEffect(() => {
        
        axios.get(api + `/api/getInfoClient/${user_id}`)
          .then(response => {
            setUserInfo(response.data);
          })
          .catch(error => {
            console.error("Erreur lors de la récupération des info des users:", error);
          })

          axios.get(api + `/api/getRdvClient/${user_id}`)
          .then(response => {
            setUserRdv(response.data);
            console.log("rdv"+userRdv.length,userRdv)
          })
          .catch(error => {
            console.error("Erreur lors de la récupération des rdvs du client:", error);
          })


        }, [])

        const filteredRdv = userRdv.filter((rdv) => {
          const matchesKeyword = formData.keyword === '' || rdv.offre?.nom.toLowerCase().includes(formData.keyword.toLowerCase());
          const matchesStatus = formData.status === 'Tous' || rdv.etat === formData.status;
          return matchesKeyword && matchesStatus;
        })

  return (
    <>
      <div>

<main className="profile-page ">
  <section className=" block h-[400px] ">    
    <div className="absolute top-0 w-full  bg-center bg-cover bg-slate-400 h-[400px]" id='back2'>
      <span id="blackOverlay" className="w-full h-full absolute opacity-50 bg-black"></span>
    </div>
    <div className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px" >
      <svg className="absolute bottom-0 overflow-hidden" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none" version="1.1" viewBox="0 0 2560 100" x="0" y="0">
        <polygon className="text-blueGray-200 fill-current" points="2560 0 2560 100 0 100"></polygon>
      </svg>
    </div>
  </section>
  <div className=" w-full bg-white h-16 shadow">
  <div className='grid grid-cols-3 min-w-0 break-words mx-4 md:mx-10  mb-6 rounded-lg gap-4'>
  <div className='col-span-1 '></div>
  <div className='col-span-2 flex items-center justify-between h-16 mx-4'><h5 className='font-bold text-lg  text-start'>Liste de mes rendez-vous</h5>
  <div className="flex gap-2 items-center">
     <p className='text-sm font-semibold'>Total: {filteredRdv.length}</p> 
     {/* <button className='py-2 px-6 bg-black-900 text-white rounded-md'>Filtrer</button> */}
     <div className="flex items-center gap-2">
        {/* search bar */}
    <div className="relative flex items-center w-full h-10 rounded-lg   overflow-hidden border bg-[#F6F8FA]">
        <div className="grid place-items-center h-full w-12 text-gray-300">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
        </div>

        <input
        className="peer h-full w-full outline-none text-sm text-gray-700 pr-2 bg-[#F6F8FA]"
        type="text"
        id="search"
        placeholder="Mot clé..." 
        onChange={(e)=>setFormData({...formData, keyword: e.target.value})}/> 
    </div>
        {/* <button
        //   onClick={() => setShowModal(true)}
          className="px-4 py-2  bg-[#F6F8FA] text-sm font-semibold border text-[#24292F] rounded-md hover:bg-neutral-200 focus:outline-none text-sm flex gap-1 items-center justify-between"

        ><BiFilter className='w-5 h-5'/>

          Filtrer
        </button> */}
        <select name="status" id="status" onChange={(e)=>setFormData({...formData, status: e.target.value})}>
          <option value="Tous">Tous</option>
          <option value="Confirmé">Confirmé</option>
          <option value="En attente">En attente</option>
          <option value="Annulé">Annulé</option>
        </select>
        
        
        {/* <button
        //   onClick={toggleDropdown}

          className={" bg-[#F6F8FA] px-3 py-[10px] text-gray-500 rounded-r-md focus:outline-none flex items-center border border-l-transparent border-l-gray-300 "}

        >
          <FaChevronDown 
        //   className={`transition-transform ${isOpen ? 'rotate-180' : ''}`} 
          />
        </button> */}
      </div>
  </div>
</div>
    <div/>
    </div> 
  </div>
  <div className='grid grid-cols-3 min-w-0 break-words mx-4 md:mx-10  mb-6 rounded-lg gap-4'>

<div className='col-span-1'>
  <div className='bg-white rounded-md p-6 drop-shadow-md mt-[-150px] relative'>
    <h5 className='font-bold text-lg mb-6 text-start'>Vos informations</h5>
    <hr className='mb-3'/>
    <ul>
      <li className='flex items-center gap-2 mb-3'>
        <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
          <FiUser className='w-6 h-6'/>
        </div>
        <p>{userInfo.nom}</p>
      </li>
      <li className='flex items-center gap-2 mb-3'>
        <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
          <FiPhone className='w-6 h-6'/>
        </div>
        <p>{userInfo.contact}</p>
      </li>
      <li className='flex items-center gap-2 mb-3'>
        <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
          <FiMail className='w-6 h-6'/>
        </div>
        <p>{userInfo.email}</p>
      </li>
      <li className='flex items-center gap-2 mb-3'>
        <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
          <FiMapPin className='w-6 h-6'/>
        </div>
        <p>{userInfo.adresse}</p>
      </li>
    </ul>    
  </div>
</div>


   
        <div className='col-span-2 py-4  min-h-[600px]'>

<ul>
  {filteredRdv.map((rdv, index) => {
    // Définition des classes et icônes pour chaque statut
    let statusClass = '';
    let iconBgClass = '';
    let IconComponent;

    if (rdv.etat === 'Confirmé') {
      statusClass = 'text-green-500';
      iconBgClass = 'bg-green-300/20';
      IconComponent = FiCheckCircle; // Icône de validation
    } else if (rdv.etat === 'En attente') {
      statusClass = 'text-yellow-500';
      iconBgClass = 'bg-yellow-300/20';
      IconComponent = FiClock; // Icône d'attente
    } else if (rdv.etat === 'Annulé') {
      statusClass = 'text-red-500';
      iconBgClass = 'bg-red-300/20';
      IconComponent = FiXCircle; // Icône d'annulation
    }

    return (
      <li
        key={index}
        className='cursor-pointer border-b border-b-slate-500/10 py-3 hover:bg-gray-50 px-4'
      >
        <Link to={`/reservation/${rdv.id}`} className='flex items-center gap-3'>
          <div className={`${iconBgClass} max-w-10 max-h-10 rounded-full p-3 flex items-center`}>
            <IconComponent className= {`w-6 h-6  ${statusClass}`} /> {/* Utilisation de l'icône en fonction du statut */}
          </div>
          <div className='flex-col flex items-start'>
            <p className='font-semibold text-lg'>
              {formatDate(rdv.date)} à {TimeConverter(rdv.heure, rdv.minute)}
            </p>
            <div>
              <span className={`font-semibold ${statusClass}`}>{rdv.etat}</span>
              <span className='text-black-300'> | {rdv.offre?.nom}</span>
              <span className='text-black-300'> | {rdv.offre.service?.nom}</span>
            </div>
          </div>
        </Link>
      </li>
    );
  })}
</ul>


        </div>
  </div>
  {/* <section className="relative pt-[700px] bg-blueGray-200 ">
    <div className="container mx-auto px-4">
      <div className='grid grid-cols-3 relative min-w-0 break-words  w-full mb-6 rounded-lg -mt-64 gap-4'>

      
        <div className='col-span-1 '>
            <div className='bg-white rounded-md p-6 drop-shadow-md'>
                <h5 className='font-bold text-lg mb-6 text-start'>Vos informations</h5>
                <hr className='mb-3'/>
                <ul>
                    <li className='flex items-center gap-2 mb-3'>
                            <div className='bg-black-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                                <img src={bookmark} alt="..." className='w-6 h-6'/>
                            </div>
                            <p>{userInfo.nom}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.contact}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.email}</p>
                    </li>
                    <li className='flex items-center gap-2 mb-3'>
                        <div className='bg-black-300/20  max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                            <img src={bookmark} alt="..." className='w-6 h-6'/>
                        </div>
                        <p>{userInfo.adresse}</p>
                    </li>
                </ul>    
            </div>
            
        </div>

   
        <div className='col-span-2 bg-white rounded-md p-6  drop-shadow-md min-h-[600px]'>
        <h5 className='font-bold text-lg mb-6 text-start'>Liste de mes rendez-vous</h5>
            <hr className=''/>
            <ul>
            {userRdv.map((rdv, index) => (
                    <li
                        key={index}
                        className='cursor-pointer border-b border-b-slate-500/10 py-3 hover:bg-slate-50 px-4'
                    >
                        <Link to={`/reservation/${rdv.id}`} className='flex items-center gap-3'>
                            <div className='bg-green-300/20 max-w-10 max-h-10 rounded-full p-3 flex items-center'>
                                <img src={bookmark} alt="..." className='w-6 h-6' />
                            </div>
                            <div className='flex-col flex items-start'>
                                <p className='font-semibold text-lg'>{formatDate(rdv.date)} à {rdv.heure}:{rdv.minute}</p>
                                <div className=''>
                                    <span className='font-semibold text-green-500'>{rdv.etat}</span>
                                    <span className='text-black-300'> | {rdv.offre?.nom}</span>
                                    <span className='text-black-300'> | {rdv.offre.service?.nom}</span>
                                </div>
                            </div>
                        </Link>
                    </li>
                ))}

               
            </ul>
        </div>
        </div>
    </div>
  </section> */}
</main>
    </div>
    {/* début booking */}
      
    </>
 
  )
}

export default Bookings