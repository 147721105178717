import React from 'react';
import { Link, useLocation } from 'react-router-dom'
import Logo from "../../../website/assets/logo/logo.png"

const Index = () => {
  const location = useLocation();

  const menuItems = [
    { name: 'Tableau de bord', icon: 'home', path: '/pro/' },
    { name: 'Rendez-vous', icon: 'calendar', path: '/pro/appointments' },
    // { name: 'Mes disponibilités', icon: 'availability', path: '/pro/availability' },
    { name: 'Clients', icon: 'users', path: '/pro/customers' },
    { name: 'Mes services', icon: 'services', path: '/pro/services' },
    // { name: 'Mes offres', icon: 'offers', path: '/pro/offers' },
    { name: 'Abonnement', icon: 'abonnement', path: '/pro/abonnement' },
    // { name: 'Profil', icon: 'offers', path: '/pro/profile' },
    { name: 'Paramètre', icon: 'parameter', path: '/pro/parametre' },
  ];

  return (
    <div className="min-h-screen flex-col w-64 flex-shrink-0 antialiased bg-gray-50 text-gray-800 hidden md:flex">
      <div className="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
        <div className="flex items-center justify-center h-16 border-b">
          <div><img src={Logo} alt="" className="h-10 w-20 my-2" /></div>
        </div>
        <div className="overflow-y-auto overflow-x-hidden flex-grow">
          <ul className="flex flex-col py-4 space-y-1 font-medium">
            <li className="px-5">
              <div className="flex flex-row items-center h-8">
                {/* <div className="text-sm font-light tracking-wide text-gray-500">Menu</div> */}
              </div>
            </li>
            {menuItems.map((item, index) => (
              <li key={index}>
                <Link
                to={item.path}
                className={`relative flex flex-row items-center h-11 focus:outline-none hover:bg-primary hover:bg-opacity-5 text-gray-600 hover:text-primary hover:font-medium border-r-4 ${
                location.pathname === item.path ? ' border-r-[4px] border-r-[#ff953e] bg-primary bg-opacity-5 text-primary ' : ' border-transparent '

  } pr-6 border-r-4 border-r-transparent `}
>
                  <span className="inline-flex justify-center items-center ml-4">
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      {item.icon === 'home' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
  />
)}
{item.icon === 'calendar' && (
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"></path>
)}
{item.icon === 'availability' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M5 8h14M12 4v4m0 8v4m-8-4h16"
  />
)}
{item.icon === 'users' && (
 <path 
 stroke-linecap="round" 
 stroke-linejoin="round" 
 stroke-width="2" 
 d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"></path>
)}
{item.icon === 'services' && (
  <path d="M18 4.25H6C5.27065 4.25 4.57118 4.53973 4.05546 5.05546C3.53973 5.57118 3.25 6.27065 3.25 7V17C3.25 17.7293 3.53973 18.4288 4.05546 18.9445C4.57118 19.4603 5.27065 19.75 6 19.75H18C18.7293 19.75 19.4288 19.4603 19.9445 18.9445C20.4603 18.4288 20.75 17.7293 20.75 17V7C20.75 6.27065 20.4603 5.57118 19.9445 5.05546C19.4288 4.53973 18.7293 4.25 18 4.25ZM19.25 7V11.25H12.75V5.75H18C18.3315 5.75 18.6495 5.8817 18.8839 6.11612C19.1183 6.35054 19.25 6.66848 19.25 7ZM6 5.75H11.25V11.25H4.75V7C4.75 6.66848 4.8817 6.35054 5.11612 6.11612C5.35054 5.8817 5.66848 5.75 6 5.75ZM4.75 17V12.75H11.25V18.25H6C5.66848 18.25 5.35054 18.1183 5.11612 17.8839C4.8817 17.6495 4.75 17.3315 4.75 17ZM18 18.25H12.75V12.75H19.25V17C19.25 17.3315 19.1183 17.6495 18.8839 17.8839C18.6495 18.1183 18.3315 18.25 18 18.25Z" fill="currentColor"/>
)}
{item.icon === 'offers' && (

  <path d="M18 4.25H6C5.27065 4.25 4.57118 4.53973 4.05546 5.05546C3.53973 5.57118 3.25 6.27065 3.25 7V17C3.25 17.7293 3.53973 18.4288 4.05546 18.9445C4.57118 19.4603 5.27065 19.75 6 19.75H18C18.7293 19.75 19.4288 19.4603 19.9445 18.9445C20.4603 18.4288 20.75 17.7293 20.75 17V7C20.75 6.27065 20.4603 5.57118 19.9445 5.05546C19.4288 4.53973 18.7293 4.25 18 4.25ZM19.25 7V11.25H12.75V5.75H18C18.3315 5.75 18.6495 5.8817 18.8839 6.11612C19.1183 6.35054 19.25 6.66848 19.25 7ZM6 5.75H11.25V11.25H4.75V7C4.75 6.66848 4.8817 6.35054 5.11612 6.11612C5.35054 5.8817 5.66848 5.75 6 5.75ZM4.75 17V12.75H11.25V18.25H6C5.66848 18.25 5.35054 18.1183 5.11612 17.8839C4.8817 17.6495 4.75 17.3315 4.75 17ZM18 18.25H12.75V12.75H19.25V17C19.25 17.3315 19.1183 17.6495 18.8839 17.8839C18.6495 18.1183 18.3315 18.25 18 18.25Z" fill="currentColor"/>
)}
{item.icon === 'abonnement' && (
  <path
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    d="M7 20h10a1 1 0 001-1V9a1 1 0 00-1-1H7a1 1 0 00-1 1v10a1 1 0 001 1zM4 8V7a2 2 0 012-2h12a2 2 0 012 2v1M7 4h10a1 1 0 011 1v1M4 8h16"
  />
)}
{item.icon === 'parameter' && (
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"></path>
)}
{item.icon === 'parameter' && (
    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
)}

                    </svg>
                  </span>
                  <span className="ml-2 text-sm tracking-wide truncate">{item.name}</span>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Index;

