import React from 'react';

const Textarea = ({ label, name, id, placeholder = '', value, onChange, className = '', rows = 4 }) => {
  return (
    <div className={`flex flex-col  ${className}`}>
      {label && (
        <label htmlFor={id} className="mb-1 text-sm font-medium text-gray-700 text-start">
          {label}
        </label>
      )}
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        rows={rows}
        className="p-2 border border-gray-300 text-sm rounded-md focus:border-primary  focus:outline focus:outline-2 focus:outline-primary"
      ></textarea>
    </div>
  );
};

export default Textarea;
