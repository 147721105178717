import React from 'react'
import img from "../resgistration/leftimage.png"

const Left = () => {
  return (
    <div className="hidden lg:block lg:fixed  top-0 left-0   lg:w-2/5  text-black ">
            <img src={img} alt="" className='w-full h-screen object-cover'/>
      </div>
  )
}

export default Left