import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import { useParams } from 'react-router-dom'
import photo from '../../../../assets/image/404.png'
import GoBack from '../../../../components/btn/goBack'
import axios from 'axios'
import { api } from '../../../../../api'

export default function DetailsService() {
  const { id } = useParams()
  const [data, setData] = useState([])
  function fetchData() {
    try {
      axios.get(api + '/api/getDetailService/' + id)
        .then(response => setData(response.data))
        .catch(error => console.error(error))
    } catch (error) {
      console.error(error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <div className='w-full flex flex-row justify-center h-screen'>
      <div className="w-full flex flex-row justify-center h-fit">
        <Card>
          <div className='flex flex-col gap-y-4 items-center'>
            <div className="flex flex-row justify-start items-center w-full gap-3">
              <GoBack />
              <h4>Details Service</h4>
            </div>
            <p>{data.description}</p>
            <img src={api + '/storage/' + data.photo} alt="Service" className='w-[200px]' />
            <div className="w-10/12 grid grid-cols-3 justify-center items-center gap-4">
              <div className='flex flex-col items-start gap-y-1'>
                <span className="text-xs text-slate-400">Nom</span>
                <span className="text-[#434966]  font-medium">{data.nom}</span>
              </div>
              <div className='flex flex-col items-start gap-y-1'>
                <span className="text-xs text-slate-400">Etat</span>
                <span className="text-[#434966]  font-medium">{data.etat}</span>
              </div>
              <div className='flex flex-col items-start gap-y-1'>
                <span className="text-xs text-slate-400">Adresse</span>
                <span className="text-[#434966]  font-medium">{data.adresse}</span>
              </div>
            </div>
          </div>

        </Card>
      </div>
    </div>
  )
}
