import React from 'react'
import { BiArrowToLeft, BiArrowBack } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'

const ButtonBack = ({path, text}) => {
    const navigate = useNavigate()
  return (
    <div className="flex items-center gap-2">
    <button className="border rounded-full p-2 w-[35px]" onClick={() => navigate(path)}>
      <BiArrowBack />
    </button>
    <h4 className='text-lg font-semibold'>{text}</h4>
  </div>
  )
}

export default ButtonBack