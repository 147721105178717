import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import ButtonOutline from '../../components/Button/ButtonOutline';
import axios from 'axios';
import Modal from '../../components/Modal/Modal';
import ToggleSwitch from '../../components/ToogleSwitch';
import { api } from '../../../api'
import { BiCamera } from 'react-icons/bi';
import ProfileImagePicker from '../../components/ProfileImagePicker'
import {district, region} from '../../../website/Datas/RegionDistrict'

const Index = () => {
  const { setTitle } = useOutletContext();
  const id = localStorage.getItem("soro-id")
  
  const [userInfo, setUserInfo] = useState({
    photo:'',
    nom: '',
    contact: '',
    email: '',
    adresse: '',
    region: '',
    district: '',
  })

  const [oldUserInfo, setOldUserInfo] = useState({
    photo:'',
    nom: '',
    contact: '',
    email: '',
    adresse: '',
    region: '',
    district: '',
  })

  const [oldImage, setoldImage] = useState()
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [notificationsEnabled, setNotificationsEnabled] = useState(false);
  const [visibilityEnabled, setVisibilityEnabled] = useState(false);
  const [data, setData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmPassword: ''
})

  useEffect(() => {
    setTitle('Profil');
    getInfoPrest()
  }, [setTitle])

  const handleInputChange = (e) => {
    const { name, value, files } = e.target
    if (name === "photo" && files && files.length > 0) {
      setUserInfo({
        ...userInfo,
        [name]: files[0], // Store the file directly
      });
    } else {
      setUserInfo({
        ...userInfo,
        [name]: value,
      });
    }
  }

  const getInfoPrest = async () => {
    axios.get(api + `/api/getInfoClient/${id}`)
    .then(response => {
      setUserInfo(response.data)
      setOldUserInfo(response.data)
      setoldImage(response.data.photo)
    })
    .catch(error => {
      console.error("There was an error fetching the customer data!", error);
    })
  };

  const handleSaveProfile = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('nom', userInfo.nom);
    formDataToSend.append('contact', userInfo.contact);
    formDataToSend.append('region', userInfo.region);
    formDataToSend.append('district', userInfo.district);
    formDataToSend.append('adresse', userInfo.adresse);

    if (userInfo.photo) {
      formDataToSend.append('photo', userInfo.photo);
  }

    try {
      const response = await axios.post(api + `/api/modInfoClient/${id}`, formDataToSend);
      // console.log('Profile updated:', response.data);
      setShowProfileModal(false);
      window.location.href= 'profile'
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  const handleSavePassword = async () => {
    const formDataToSend = new FormData();
    formDataToSend.append('oldPassword', data.oldPassword);
    formDataToSend.append('newPassword', data.newPassword);
  //  console.log(data.newPassword)
    axios.post(api + `/api/changerMdp/${id}`, formDataToSend)
    .then(response => {
      setShowPasswordModal(false);
      setData({ ...data, oldPassword: '', newPassword: '', confirmPassword: '' })
      // setUserInfo(response.data);
      console.log('Modification du mot de passe avec succès')
    })
    .catch(error => {
      console.error("Erreur lors du changement du mot de passe!", error);
    })
  };

  const handleToggleChange = async (toggleType, enabled) => {
    try {
      // const response = await axios.put(`${api}/api/user/settings/${toggleType}`, { enabled });
      // console.log(`${toggleType} updated:`, response.data);
      if (toggleType === 'notifications') {
        setNotificationsEnabled(enabled);
      } else if (toggleType === 'visibility') {
        setVisibilityEnabled(enabled);
      }
    } catch (error) {
      console.error(`Error updating ${toggleType}:`, error);
    }
  };

  return (
    <div className='mx-4'>
      <div className="col-span-2">
        {/* Information personnelle */}
        <div className='border-line p-4 mb-4 justify-between flex items-center' id='base-info'>
          <div className="flex gap-4">
            <div className="w-16 h-16 bg-gray-200 rounded-full">
              <img src={api + '/storage/' + oldImage} alt="" className='w-full h-full object-cover rounded-full' />
            </div>
            <div className="flex flex-col justify-between gap-2 items-start">
              <p className='text-xl font-medium text-black-1'>{userInfo.nom}</p>
              <p className='text-sm'>{userInfo.email}</p>
            </div>
          </div>
          {/* <ButtonOutline onClick={() => setShowProfileModal(true)}>Modifier</ButtonOutline>  */}
        </div>

        <div className="border-line mb-4">
          <div className="flex justify-between items-center mb-4">
            <h5 className='text-xl font-medium mb-4'>Information personnelle</h5>
            <ButtonOutline onClick={() => setShowProfileModal(true)}>Modifier</ButtonOutline>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-3 gap-4 info-col">
            <div>
              <p>Nom</p>
              <p>{oldUserInfo.nom}</p>
            </div>
            <div>
              <p>Numéro de téléphone</p>
              <p>{oldUserInfo.contact}</p>
            </div>
            <div>
              <p>Adresse e-mail</p>
              <p>{oldUserInfo.email}</p>
            </div>
            <div>
              <p>Région</p>
              <p>{oldUserInfo.region}</p>
            </div>
            <div>
              <p>District</p>
              <p>{oldUserInfo.district}</p>
            </div>
            <div>
              <p>Adresse</p>
              <p>{oldUserInfo.adresse}</p>
            </div>
          </div>
        </div>
        
        <div className="border-line mb-4">
          <div className="flex justify-between items-center">
            <p>Changer mot de passe</p>
            <ButtonOutline onClick={() => setShowPasswordModal(true)}>Changer</ButtonOutline>
          </div>
        </div>
      </div>

      {/* Modal pour la modification du profil */}
      <Modal
        title="Modifier le profil"
        show={showProfileModal}
        onClose={() => setShowProfileModal(false)}
        onSave={handleSaveProfile}
      >
      <div className="space-y-4">
      <ProfileImagePicker
        initialImage={userInfo.photo ? `${api}/storage/${userInfo.photo}` : null}
        onImageChange={handleInputChange}
      />
      
      <div>
        <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Nom</label>
        <input
          type="text"
          id="lastName"
          name="nom"
          value={userInfo.nom}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>

      <div>
        <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
        <input
          type="text"
          id="phoneNumber"
          name="contact"
          value={userInfo.contact}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>

{/* <div>
        <label htmlFor="region" className="block text-sm font-medium text-gray-700">Région</label>
        <input
          type="text"
          id="region"
          name="region"
          value={userInfo.region}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div> */}

<div className="relative mt-1">
  <select
    id="region"
    name="region"
    value={userInfo.region.toString()}
    onChange={handleInputChange}
    className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
    required
  >
    {/* <option value="">Choisissez une région</option> */}
    {region.map((item, index) => (
      <option key={index} value={item}>{item}</option>
    ))}
  </select>
</div>


      {/* <div>
        <label htmlFor="district" className="block text-sm font-medium text-gray-700">District</label>
        <input
          type="text"
          id="district"
          name="district"
          value={userInfo.district}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div> */}
                  <div className="relative mb-6">
                <label htmlFor="district" className="block text-sm font-medium text-gray-700 text-start">District</label>
                <div className="relative mt-1">
                    <select
                        id="district"
                        name="district"
                        value={userInfo.district}
                        onChange={handleInputChange}
                        className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                        required
                    >
                        <option value="">Choisissez un district</option>
                        {userInfo.region && district[userInfo.region].map((item, index) => (
                            <option key={index} value={item}>{item}</option>
                        ))}
                    </select>
                </div>
            </div>

      <div>
        <label htmlFor="address" className="block text-sm font-medium text-gray-700">Adresse</label>
        <input
          type="text"
          id="address"
          name="adresse"
          value={userInfo.region}
          onChange={handleInputChange}
          className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        />
      </div>
    </div>
</Modal>


      {/* Modal pour le changement de mot de passe */}
      <Modal
  title="Changer le mot de passe"
  show={showPasswordModal}
  onClose={() => setShowPasswordModal(false)}
  onSave={handleSavePassword}
>
  <div className="space-y-4">
    <div>
      <label htmlFor="currentPassword" className="block text-sm font-medium text-gray-700">Mot de passe actuel</label>
      <input
        type="password"
        id="oldPassword"
        name="oldPassword"
        className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        required value={data.oldPasswordpassword} onChange={(e) => { setData({ ...data, oldPassword: e.target.value }) }}
      />
    </div>
    <div>
      <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">Nouveau mot de passe</label>
      <input
        type="password"
        id="newPassword"
        name="newPassword"
        className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        required value={data.newPassword} onChange={(e) => { setData({ ...data, newPassword: e.target.value }) }}
      />
    </div>
    <div>
      <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">Confirmer le nouveau mot de passe</label>
      <input
        type="password"
        id="confirmPassword"
        name="confirmPassword"
        className="mt-1 p-2 w-full border rounded-md focus:border-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-300 transition-colors duration-300"
        required value={data.confirmPassword} onChange={(e) => { setData({ ...data, confirmPassword: e.target.value }) }}
      />
    </div>
  </div>
</Modal>

    </div>
  );
};

export default Index;
