
import React, { useEffect, useState } from 'react'
import Card from '../../../../components/Card'
import Input from '../../../../components/form/input'
import { Button } from '@mui/material'
import GoBack from '../../../../components/btn/goBack'
import { useNavigate, useOutletContext } from 'react-router-dom'
import axios from 'axios'
import { api } from '../../../../../api'
import Modal from '../../../../components/modal'
import Feedback from '../../../../components/feedback'
export default function UpdateProfile() {
    const navigate = useNavigate()
    const { myData, fetchData } = useOutletContext()
    const [id, setId] = useState(1)
    const [data, setData] = useState([])
    const [options, setOptions] = useState({
        loading: false,
        disabled: false
    })
    const [isOpen, setIsOpen] = useState({
        success: false,
        fails: false,
        // add: false,
        Verification: false
    })

    function getInfo() {
        try {
            axios.get(api + '/api/getInfoClient/' + id)
                .then(res => {
                    setData(res.data);
                })
                .catch(err => {
                    console.error(err);
                })
        } catch (error) {
            console.error(error);
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        // changeData()
        setIsOpen({ ...isOpen, Verification: true })
    }

    function changeData() {
        // e.preventData()
        setOptions({ ...options, loading: true })
        try {

            const form = new FormData()
            form.append('nom', data.nom)
            form.append('contact', data.contact)
            form.append('adresse', data.adresse)
            form.append('region', data.region)
            form.append('district', data.district)


            axios.post(api + `/api/modInfoAdmin/${id}`, form)
                .then(() => {
                    // setData(myData)
                    // fetchData()
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, success: true })

                    setTimeout(() => {
                        navigate('/admin/profile')
                    }, 500)
                })
                .catch((error) => {
                    console.error(error)
                    setOptions({ ...options, loading: false })
                    setIsOpen({ ...isOpen, fails: true })
                })
        } catch (error) {
            console.error(error)
            setOptions({ ...options, loading: false })
            setIsOpen({ ...isOpen, fails: true })
        }


    }
    useEffect(() => {
        getInfo()
    }, [])

    useEffect(() => {

        if (
            data.nom !== '' &&
            data.tel !== '' &&
            data.adresse !== '' &&
            data.region !== '' &&
            data.district !== ''
        ) {
            setOptions({ ...options, disabled: false })

        } else {
            setOptions({ ...options, disabled: true })
        }
    }, [data])
    return (
        <div className="max-lg:w-full lg:w-6/12" >
            <Card>
                <form onSubmit={(e) => {
                    handleSubmit(e);
                }} className='flex flex-col w-full gap-y-3'>
                    <div className="flex flex-row justify-start items-center gap-x-3">
                        <GoBack />
                        <h4 className='w-full'>Modification de profil</h4>
                    </div>
                    <Input label='Nom' value={data.nom} onChange={(e) => { setData({ ...data, nom: e.target.value }) }} />
                    <Input label='Numero de téléphone' value={data.contact} onChange={(e) => { setData({ ...data, contact: e.target.value }) }} />
                    <Input label='Adresse' value={data.adresse} onChange={(e) => { setData({ ...data, adresse: e.target.value }) }} />
                    <Input label='Région' value={data.region} onChange={(e) => { setData({ ...data, region: e.target.value }) }} />
                    <Input label='District' value={data.district} onChange={(e) => { setData({ ...data, district: e.target.value }) }} />
                    <Button type='submit' variant="contained" >
                        Changer
                    </Button>
                </form>
            </Card>
            <Modal isOpen={isOpen.Verification} onClose={() => { setIsOpen({ ...isOpen, Verification: false }) }}>
                <div className="w-full flex flex-col gap-y-4">
                    <h4>Verification</h4>
                    <p>Voulez-vous vraiment modifier votre profil?</p>
                    <div className="flex flex-row justify-between items-center gap-x-2">

                        <Button fullWidth variant='outlined' onClick={() => { setIsOpen({ ...isOpen, Verification: false }) }}>
                            Non,abandonner
                        </Button>
                        <Button fullWidth variant='contained' onClick={() => { changeData() }}>
                            Oui,modifier
                        </Button>
                    </div>
                </div>
            </Modal>
            <Feedback isOpen={isOpen.success} message='Modifié avec succès' type='success' onClose={()=>{setIsOpen({...isOpen,success:false})}}/>
            <Feedback isOpen={isOpen.fails} message="Une erreur s'est produit" type='error' onClose={()=>{setIsOpen({...isOpen,fails:false})}}/>

        </div>
    )
}

