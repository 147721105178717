import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import Card from '../../components/Card'
import React, { useEffect, useState } from 'react'
import { IoAddCircle, IoAddOutline, IoCreate, IoEllipsisHorizontal, IoEye, IoSearch, IoShare, IoShareOutline, IoTrash } from 'react-icons/io5'
import { useNavigate, useOutletContext } from 'react-router-dom'
import Input from '../../components/form/input'
import axios from 'axios';
import { api } from '../../../api';
export default function Notification() {
    const navigate = useNavigate()
    const [data, setData] = useState([
        {
            id: 1,
            objet: 'null',
            message: 'ARIMANANA Maherison Jules',
            // email: 'arimananajules'
        }
    ])
    function fetchData() {
        try {
            axios.get(api + '/api/route')
                .then(response => {
                    setData(response.data)
                })
                .catch(error => {
                    console.error(error)
                })
        } catch (error) {
            console.error(error)
        }
    }
    useEffect(() => {
        fetchData()
    }, [])


    return (
        <div className='w-full flex flex-col gap-y-3'>
            <div className="w-full flex flex-row justify-between items-center">
                <div className='flex flex-row gap-x-3 justify-start items-center'>


                </div>
                <div className='flex flex-row justify-end items-center'>
                    <div className="flex flex-row justify-end items-center gap-x-1">
                        <Input />
                        <Button className="flex flex-row gap-x-3" variant="contained">
                            <IoSearch />
                            <span>Rechercher</span>
                        </Button>
                    </div>
                </div>
            </div>
            <Card>
                <h4 className='mb-2'>Notification</h4>
                <table className='w-full max-w-full '>
                    <thead>
                        <tr>
                            <th>NUMERO</th>
                            <th>OBJET</th>
                            <th>MESSAGE</th>

                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            data.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.id}</td>
                                    <td>{item.objet}</td>
                                    <td>{item.message}</td>
                                    <td className='flex flex-row justify-end items-center text-xl gap-x-3'>
                                    </td>
                                </tr>
                            ))
                        }

                    </tbody>
                </table>
            </Card>


        </div>
    )
}
