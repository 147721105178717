import React, { useState } from 'react';

const ConfirmModal = ({ action, show, onClose,variant ="primary", title = null, text = null, buttonText, buttonCancelText=null }) => {
  const title1 = title === null ? 'Confirmation' : `${title} `;
  const message = text === null ? 'Êtes-vous sûr de vouloir enregistrer les modifications?' : `${text} ?`;
  const buttonColor = variant === 'danger' ? 'red' : 'blue';
  const buttonTextColor = 'white' ;
  const buttonHoverColor = variant === 'danger' ? 'red' : 'blue'
  const buttonHoverTextColor = variant === 'danger' ? 'white' : 'gray'
  // const buttonCancelText = buttonCancelText === null ? "Annuler": buttonCancelText

  if(show === false)return null;

  return (
    <div className={`relative z-50 bg-gray-500 bg-opacity-75`}>
      <div className="fixed inset-0 bg-gray-500 bg-opacity-10 transition-opacity" aria-hidden="true"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
  
          <div className={`relative transform overflow-hidden rounded-lg bg-white text-left shadow-md transition-all sm:my-8 sm:w-full sm:max-w-lg ${variant === 'deactivate' ? 'bg-red-100' : 'bg-blue-100'}`}>
            <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className={`mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-${variant === 'danger' ? 'red-100' : 'blue-100'} sm:mx-0 sm:h-10 sm:w-10`}>
                  <svg className={`h-6 w-6 text-${variant === 'danger' ? 'red-600' : 'blue-600'}`} fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v3.75m-9.303 3.376c-.866 1.5.217 3.374 1.948 3.374h14.71c1.73 0 2.813-1.874 1.948-3.374L13.949 3.378c-.866-1.5-3.032-1.5-3.898 0L2.697 16.126zM12 15.75h.007v.008H12v-.008z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{title1}</h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{message}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
              <button 
                onClick={action} 
                type="button" 
                className={`inline-flex w-full justify-center rounded-md border border-transparent shadow-sm px-3 py-2 text-sm font-semibold text-${buttonTextColor} ${variant === 'danger' ? 'bg-red-500 ' : 'bg-[#649af1]'} hover:bg-${buttonHoverColor}-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-${variant === 'deactivate' ? 'red-500' : 'blue-500'} sm:ml-3 sm:w-auto sm:text-sm`}
              >
                {buttonText}
              </button>
              <button 
                onClick={() => onClose(false)} 
                type="button" 
                className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-50 sm:mt-0 sm:w-auto sm:text-sm"
              >
                {buttonCancelText === null ? "Annuler": buttonCancelText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmModal;
